import React, { useState } from "react";
import { mainColumns, galenicColumns, galenicIngredientsColumns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PopUp from "../../../../Utilities/PopUp.js";
import { useEffect } from "react";
import IdikaMasterDataService from "../../../../../services/MediPlus/Idika/IdikaMasterDataService.js";
import { Spinner } from "../../../../Utilities/Utilities.js";

function Medicines({ prescription }) {
    const [openIngredientsPopUp, setOpenIngredientsPopUp] = useState(false);
    const [ingredients, setIngredients] = useState(null);
    const galenic = prescription.medicines.medicines.find(
        (element) => element.commercialName === "ΓΑΛΗΝΙΚΟ ΣΚΕΥΑΣΜΑ"
    )
        ? true
        : false;
    const columns = !galenic ? mainColumns : galenicColumns;

    const onIngredientsHandle = (medicine) => {
        setIngredients(medicine.request);
        setOpenIngredientsPopUp(true);
    };

    return (
        <div className="fullBox">
            <div className="boxTitle">
                <h6>{!galenic ? "Φάρμακα" : "Γαληνικά Σκευάσματα"}</h6>
            </div>
            <div className="boxContent">
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((column, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <b>{column.name}</b>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!galenic &&
                                prescription.medicines.medicines.map((medicine, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <TableRow>
                                                <TableCell>
                                                    {medicine.request.genericMedicine === true && (
                                                        <i className="fa fa-check"></i>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {medicine.request.treatmentProtocolDescription}
                                                </TableCell>
                                                <TableCell>
                                                    {medicine.request.activeSubstanceName}
                                                </TableCell>
                                                <TableCell>
                                                    {medicine.request.doseQuantity.unit}
                                                </TableCell>
                                                <TableCell>
                                                    {medicine.request.doseQuantity.value}
                                                </TableCell>
                                                <TableCell>
                                                    {medicine.request.effectiveTime.name}
                                                </TableCell>
                                                <TableCell>
                                                    {medicine.request.rateQuantity.value}
                                                </TableCell>
                                                <TableCell>{medicine.request.quantity}</TableCell>
                                                <TableCell>{medicine.request.remainder}</TableCell>
                                                <TableCell>
                                                    {medicine.request.percentageParticipation}%
                                                </TableCell>
                                                <TableCell>
                                                    {medicine.request.insuredParticipation}€
                                                </TableCell>
                                                <TableCell>
                                                    {medicine.request.insuredDifference}€
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={12}>
                                                    <div>
                                                        <div className="executionDates mb-3">
                                                            {medicine.executionDates.map(
                                                                (executionDate, index) => {
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className="d-flex flex-row justify-content-between"
                                                                        >
                                                                            <div className="form-inline">
                                                                                <div className="gold mr-2">
                                                                                    Ημ/νία Εκτέλεσης
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        executionDate.executionDate
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-inline">
                                                                                <div className="gold mr-2">
                                                                                    Ταινία
                                                                                    Γνησιότητας
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        executionDate.authenticity
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-inline">
                                                                                <div className="gold mr-2">
                                                                                    Τιμή Εκτέλεσης
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        executionDate.executionPrice
                                                                                    }
                                                                                    €
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-inline">
                                                                                <div className="gold mr-2">
                                                                                    Λιανική
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        executionDate.retailPrice
                                                                                    }{" "}
                                                                                    €
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-inline">
                                                                                <div className="gold mr-2">
                                                                                    Αναφοράς
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        executionDate.referencePrice
                                                                                    }{" "}
                                                                                    €
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                        {medicine.request.diagnoses.length > 0 && (
                                                            <div>
                                                                <div className="gold">
                                                                    Συσχετίσεις με διαγνώσεις ICD10:
                                                                </div>
                                                                <ul className="icd10s">
                                                                    {medicine.request.diagnoses.map(
                                                                        (diagnosis, index) => {
                                                                            return (
                                                                                <li key={index}>
                                                                                    {`${diagnosis.code} ${diagnosis.title}`}
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}

                            {galenic &&
                                prescription.medicines.medicines.map((medicine, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <TableRow>
                                                <TableCell>
                                                    {medicine.request.displayName}
                                                </TableCell>
                                                <TableCell>
                                                    {medicine.request.pharmaceuticalForm}
                                                </TableCell>
                                                <TableCell>
                                                    {medicine.request.galenicMeasurementUnit}
                                                </TableCell>
                                                <TableCell>
                                                    {medicine.request.galenicQuantity}
                                                </TableCell>
                                                <TableCell>
                                                    {medicine.request.doseQuantity.unit}
                                                </TableCell>
                                                <TableCell>
                                                    {medicine.request.doseQuantity.value}
                                                </TableCell>
                                                <TableCell>
                                                    {medicine.request.effectiveTime.name}
                                                </TableCell>
                                                <TableCell>
                                                    {medicine.request.rateQuantity.value}
                                                </TableCell>
                                                <TableCell>
                                                    {medicine.request.percentageParticipation}%
                                                </TableCell>
                                                <TableCell>
                                                    {medicine.request.insuredParticipation +
                                                        medicine.request.insuredDifference}
                                                    €
                                                </TableCell>
                                                <TableCell>
                                                    {medicine.request.insuredParticipation}€
                                                </TableCell>
                                                <TableCell>
                                                    {medicine.request.insuredDifference}€
                                                </TableCell>
                                                <TableCell>{medicine.request.comment}</TableCell>
                                                <TableCell>
                                                    <button
                                                        className="btn buttonPrimary"
                                                        onClick={() =>
                                                            onIngredientsHandle(medicine)
                                                        }
                                                    >
                                                        Συστατικά
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={12}>
                                                    <div>
                                                        <div className="executionDates mb-3">
                                                            {medicine.executionDates.map(
                                                                (executionDate, index) => {
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className="d-flex flex-row justify-content-between"
                                                                        >
                                                                            <div className="form-inline">
                                                                                <div className="gold mr-2">
                                                                                    Ημ/νία Εκτέλεσης
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        executionDate.executionDate
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-inline">
                                                                                <div className="gold mr-2">
                                                                                    Ταινία
                                                                                    Γνησιότητας
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        executionDate.authenticity
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-inline">
                                                                                <div className="gold mr-2">
                                                                                    Τιμή Εκτέλεσης
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        executionDate.executionPrice
                                                                                    }
                                                                                    €
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-inline">
                                                                                <div className="gold mr-2">
                                                                                    Λιανική
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        executionDate.retailPrice
                                                                                    }{" "}
                                                                                    €
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-inline">
                                                                                <div className="gold mr-2">
                                                                                    Αναφοράς
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        executionDate.referencePrice
                                                                                    }{" "}
                                                                                    €
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                        {medicine.request.diagnoses.length > 0 && (
                                                            <div>
                                                                <div className="gold">
                                                                    Συσχετίσεις με διαγνώσεις ICD10:
                                                                </div>
                                                                <ul className="icd10s">
                                                                    {medicine.request.diagnoses.map(
                                                                        (diagnosis, index) => {
                                                                            return (
                                                                                <li key={index}>
                                                                                    {`${diagnosis.code} ${diagnosis.title}`}
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <PopUp
                openPopUp={openIngredientsPopUp}
                setOpenPopUp={setOpenIngredientsPopUp}
                title={"Συστατικά"}
                maxWidth="lg"
                maxHeight="md"
            >
                <GalenicIngredients prescription={prescription} ingredients={ingredients} />
            </PopUp>
        </div>
    );
}

export default Medicines;

function GalenicIngredients({ prescription, ingredients }) {
    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {galenicIngredientsColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column.name}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ingredients.ramas?.map((rama, index) => {
                            return (
                                <TableRow key={`rama-${index}`}>
                                    <TableCell>Έκδοχο</TableCell>
                                    <TableCell>{rama.description}</TableCell>
                                    <TableCell>{rama.measurementUnit}</TableCell>
                                    <TableCell>{rama.doseQuantity}</TableCell>
                                    <TableCell>{rama.insuredParticipationPercentage}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>{rama.comments}</TableCell>
                                </TableRow>
                            );
                        })}
                        {ingredients.packs?.map((pack, index) => {
                            return (
                                <TableRow key={`pack-${index}`}>
                                    <TableCell>Υλικό Συσκευασίας</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>{pack.insuredParticipationPercentage}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>{pack.totalPrice}€</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        {pack.patientParticipationPercentage !== 0
                                            ? (pack.totalPrice *
                                                  pack.patientParticipationPercentage) /
                                              100
                                            : 0}
                                        €
                                    </TableCell>
                                    <TableCell>{pack.comments}</TableCell>
                                </TableRow>
                            );
                        })}
                        {ingredients.medicines?.map((med, index) => {
                            return (
                                <TableRow key={`med-${index}`}>
                                    <TableCell>Φάρμακο</TableCell>
                                    <TableCell>
                                        <div>{med.activeSubstanceName}</div>
                                        <div>{med.form}</div>
                                    </TableCell>
                                    <TableCell>{med.measurementUnit}</TableCell>
                                    <TableCell>{med.doseQuantity}</TableCell>
                                    <TableCell>{med.insuredParticipationPercentage}</TableCell>
                                    <TableCell>{med.medicinePrice}€</TableCell>
                                    <TableCell>{med.medicineReferencePrice}€</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>{med.comments}</TableCell>
                                </TableRow>
                            );
                        })}
                        {ingredients.acts?.map((act, index) => {
                            return (
                                <TableRow key={`act-${index}`}>
                                    <TableCell>Δραστική Ουσία</TableCell>
                                    <TableCell>{act.description}</TableCell>
                                    <TableCell>{act.measurementUnit}</TableCell>
                                    <TableCell>{act.doseQuantity}</TableCell>
                                    <TableCell>{act.insuredParticipationPercentage}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>{act.comments}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export { GalenicIngredients };
