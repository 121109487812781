import React, { useState, useEffect, createContext, useContext } from "react";
import { Tabs, Tab } from "@material-ui/core";
import "./Esyntagografisi.css";
import DoctorHome from "./DoctorHome/DoctorHome";
import DoctorVisit from "./DoctorVisit/DoctorVisit";
import AddPrescription from "./AddPrescription/AddPrescription";
import DoctorAddExamination from "./DoctorAddExamination/DoctorAddExamination";
import DoctorExecuteExam from "./DoctorExecuteExam/DoctorExecuteExam";
import PatientMedicineHistory from "./PatientMedicineHistory/PatientMedicineHistory";
import SearchPrescription from "./SearchPrescription/SearchPrescription";
import ViewPrescription from "./ViewPrescription/ViewPrescription";
import ViewReferral from "./ViewReferral/ViewReferral";
import DoctorInformantion from "./DoctorInformation/DoctorInformation";
import { mainTabs } from "./config.js";
import { UnitIdContext } from "../Doctor.js";
import { TabContext } from "../Doctor";
import IdikaPrescriptionService from "../../../services/MediPlus/Idika/IdikaPrescriptionService.js";
import PopUp from "../../Utilities/PopUp.js";
import IdikaPatientService from "../../../services/MediPlus/Idika/IdikaPatientService";
import { Button } from "@material-ui/core";
import IdikaDHPCService from "../../../services/MediPlus/Idika/IdikaDHPCService";

export const PatientContext = createContext(null);

const Esyntagografisi = ({ addTab, patientData = null }) => {
    const {
        selectedTab,
        setSelectedTab,
        findTabIndexByTabId,
        tabList,
        setTabList,
        setShowBanner,
        setBanner,
        openDiagnosePopUp,
        setOpenDiagnosePopUp,
    } = useContext(TabContext);
    const tabIndex = findTabIndexByTabId(selectedTab);
    const [tabItem, setTabItem] = useState(tabList[tabIndex]);
    const [subTab, setSubTab] = useState(tabList[tabIndex].subTab);
    const [tabAlertPopUp, setTabAlertPopUp] = useState(false);
    const [tabValue, setTabValue] = useState();
    const [dhpcMessages, setDhpcMessages] = useState([]);

    const [links, setLinks] = useState(null);
    const [selectedTabBttn, setSelectedTabBttn] = useState(tabList[tabIndex].subTab);
    const handleTabsBttns = (e, val) => {
        if (selectedTabBttn === 2 || selectedTabBttn === 3) {
            setTabAlertPopUp(true);
            setTabValue(val);
        } else {
            setSelectedTabBttn(val);
            setSubTab(val);
        }
    };

    const forceChangeTab = () => {
        setTabAlertPopUp(false);
        setSelectedTabBttn(tabValue);
        setSubTab(tabValue);
    };

    useEffect(() => {
        if (tabItem !== undefined) setTabItem({ ...tabItem, subTab: subTab });
        // eslint-disable-next-line
    }, [subTab]);

    useEffect(() => {
        if (tabItem !== undefined) {
            let newTabList = [...tabList];
            newTabList[tabIndex] = tabItem;
            setTabList(newTabList);
        }
        // eslint-disable-next-line
    }, [tabItem]);

    const { selectedUnit, setSelectedUnit } = useContext(UnitIdContext);
    useEffect(() => {
        if (sessionStorage.getItem("unitId") !== null)
            setSelectedUnit(sessionStorage.getItem("unitId"));
    }, [setSelectedUnit]);

    //to show error if treatment protocol can not be repeated
    const [err, setErr] = useState([]);
    const [openError, setOpenError] = useState(false);

    //no paper cases
    const [noPaperPrescriptionConsent, setNoPaperPrescriptionConsent] = useState(false);
    const [noPaperReferralConsent, setNoPaperReferralConsent] = useState(false);

    useEffect(() => {
        if (patientData !== null) {
            let request = {
                amkaOrEkaa: patientData.amka,
            };
            IdikaPatientService.getNoPaperPrescriptionConsent(request)
                .then(({ data }) => {
                    if (data.NoPaperPatientConsentMessage.Consent === 1) {
                        setNoPaperPrescriptionConsent(true);
                    }
                })
                .catch((error) => {
                    //console.log(error);
                });

            IdikaPatientService.getNoPaperReferralConsent(request)
                .then(({ data }) => {
                    if (data.NoPaperPatientConsentMessage.Consent === 1) {
                        setNoPaperReferralConsent(true);
                    }
                })
                .catch((error) => {
                    //console.log(error);
                });
        }
    }, [patientData]);

    useEffect(() => {
        IdikaDHPCService.getMessages()
            .then(({ data }) => {
                setDhpcMessages(data);
            })
            .catch((error) => {
                //setError(error);
            });
    }, []);

    const showContent = () => {
        if (selectedTabBttn === 0)
            return <DoctorHome links={links} setLinks={setLinks} dhpc={dhpcMessages} />;
        else if (selectedTabBttn === 1)
            return (
                <DoctorVisit
                    addTab={addTab}
                    tabIndex={tabIndex}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                    setSelectedTab={setSelectedTab}
                    setSelectedTabBttn={setSelectedTabBttn}
                    popPrescriptionOrReferralForRegistration={
                        popPrescriptionOrReferralForRegistration
                    }
                />
            );
        else if (selectedTabBttn === 2)
            return (
                <AddPrescription
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                    setSelectedTabBttn={setSelectedTabBttn}
                    error={err}
                    setError={setErr}
                    openError={openError}
                    setOpenError={setOpenError}
                    setNoPaperPrescriptionConsent={setNoPaperPrescriptionConsent}
                    setShowBanner={setShowBanner}
                    setBanner={setBanner}
                    openDiagnosePopUp={openDiagnosePopUp}
                    setOpenDiagnosePopUp={setOpenDiagnosePopUp}
                />
            );
        else if (selectedTabBttn === 3)
            return (
                <DoctorAddExamination
                    visitId={tabItem.visitDetails.id}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                    setSelectedTabBttn={setSelectedTabBttn}
                    setNoPaperReferralConsent={setNoPaperReferralConsent}
                />
            );
        else if (selectedTabBttn === 4) return <DoctorExecuteExam />;
        else if (selectedTabBttn === 5)
            return (
                <PatientMedicineHistory
                    addTab={addTab}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                    setSelectedTab={setSelectedTab}
                    setSelectedTabBttn={setSelectedTabBttn}
                />
            );
        else if (selectedTabBttn === 6)
            return (
                <SearchPrescription
                    addTab={addTab}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                    setSelectedTab={setSelectedTab}
                    setSelectedTabBttn={setSelectedTabBttn}
                />
            );
        else if (selectedTabBttn === 7)
            return (
                <ViewPrescription
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                    popPrescriptionOrReferralForRegistration={
                        popPrescriptionOrReferralForRegistration
                    }
                    noPaperPrescriptionConsent={noPaperPrescriptionConsent}
                />
            );
        else if (selectedTabBttn === 8)
            return (
                <ViewReferral
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                    popPrescriptionOrReferralForRegistration={
                        popPrescriptionOrReferralForRegistration
                    }
                    noPaperReferralConsent={noPaperReferralConsent}
                />
            );
        else if (selectedTabBttn === 9) return <DoctorInformantion />;
        else return null;
    };

    const [
        popPrescriptionWithTreatmentProtocolError,
        setPopPrescriptionWithTreatmentProtocolError,
    ] = useState(false);
    const popPrescriptionOrReferralForRegistration = () => {
        if (tabItem.visitDetails.referralsToBeRegistered.length > 0) {
            const [poppedReferral, ...rest] = tabItem.visitDetails.referralsToBeRegistered;
            let referrals = JSON.parse(poppedReferral.referrals);
            let diagnoses = JSON.parse(poppedReferral.diagnoses);
            let addReferral = {
                ...tabItem.addReferral,
                ...poppedReferral,
                referrals,
                diagnoses,
            };
            setTabItem({
                ...tabItem,
                subTab: 3,
                addReferral: addReferral,
                visitDetails: { ...tabItem.visitDetails, referralsToBeRegistered: rest },
            });
            setSelectedTabBttn(3);
        } else if (tabItem.visitDetails.prescriptionsToBeRegistered.length > 0) {
            const [poppedPrescription, ...rest] = tabItem.visitDetails.prescriptionsToBeRegistered;

            let treatmentProtocolStep = 0;
            //all the protocol steps that must be repeated
            const steps = [];
            //the diagnoses that we must have in the final prescription
            const icds = [];
            for (let medicine of poppedPrescription.medicines) {
                if (medicine.request.treatmentProtocolPatientStepId !== 0) {
                    treatmentProtocolStep = medicine.request.treatmentProtocolPatientStepId;
                    steps.push(treatmentProtocolStep);
                } else {
                    //add the diagnoses of non protocol medicines
                    const diagnoses = medicine.request.diagnoses;
                    diagnoses.forEach((d) => {
                        icds.push(d.code);
                    });
                }
            }

            if (treatmentProtocolStep !== 0) {
                setErr([]);
                //variable to check if loop is over
                const exit = {};
                exit.step = steps.length;
                //check if all the protocol steps san be repeated
                for (let i = 0; i < steps.length; i++) {
                    let medicines = poppedPrescription.medicines.map((medicine) => {
                        return { barcode: medicine.barcode, content: medicine.content };
                    });
                    let request = {
                        medicines: medicines,
                        visitId: tabItem.visitDetails.id,
                        step: steps[i],
                        amkaOrEkaa: patientData.amka.toString(),
                    };
                    IdikaPrescriptionService.repeatPrescriptionWithTreatmentProtocol(request)
                        .then(({ data }) => {
                            //if protocol cant be repeated delete medicines with this protocol
                            if (
                                data.response !==
                                "Prescription with treatment protocol can be repeated"
                            ) {
                                setErr([...err, data.message]);
                                setOpenError(true);
                                poppedPrescription.medicines = poppedPrescription.medicines.filter(
                                    (obj) => {
                                        return (
                                            obj.request.treatmentProtocolPatientStepId !== steps[i]
                                        );
                                    }
                                );
                            } else {
                                //keep diagnoses of medicine with protocol that can be repeated]
                                poppedPrescription.medicines.forEach((medicine) => {
                                    const diagnoses = medicine.request.diagnoses;
                                    if (
                                        medicine.request.treatmentProtocolPatientStepId === steps[i]
                                    ) {
                                        diagnoses.forEach((d) => {
                                            icds.push(d.code);
                                        });
                                    }
                                });
                            }
                            exit.step--;
                            if (exit.step === 0) {
                                //fill the icd10s of prescription
                                poppedPrescription.icd10s = poppedPrescription.icd10s.filter(
                                    (ob) => {
                                        return icds.includes(ob.code);
                                    }
                                );

                                let addPrescription = {
                                    ...tabItem.addPrescription,
                                    ...poppedPrescription,
                                };

                                //when prescription is repeated set default participation percentage
                                for (let medicine of addPrescription.medicines) {
                                    if (medicine.request.diseaseId !== -1) {
                                        const diseasesArray = medicine.diseases.diseases;
                                        for (let i = 0; i < diseasesArray.length; i++) {
                                            if (
                                                diseasesArray[i].disease.id ===
                                                medicine.request.diseaseId
                                            ) {
                                                medicine.request.insuredParticipationRate =
                                                    diseasesArray[i].participationPercent.name;
                                            }
                                        }
                                    } else {
                                        medicine.request.insuredParticipationRate =
                                            medicine.participationPercentage;
                                    }
                                }
                                setTabItem({
                                    ...tabItem,
                                    subTab: 2,
                                    addPrescription: addPrescription,
                                    visitDetails: {
                                        ...tabItem.visitDetails,
                                        prescriptionsToBeRegistered: rest,
                                    },
                                });
                                setSelectedTabBttn(2);
                            }
                        })
                        .catch((error) => {
                            setTabItem({
                                ...tabItem,
                                visitDetails: {
                                    ...tabItem.visitDetails,
                                    prescriptionsToBeRegistered: rest,
                                },
                            });
                            setPopPrescriptionWithTreatmentProtocolError(true);
                        });
                }
            } else {
                let addPrescription = {
                    ...tabItem.addPrescription,
                    ...poppedPrescription,
                };
                setTabItem({
                    ...tabItem,
                    subTab: 2,
                    addPrescription: addPrescription,
                    visitDetails: { ...tabItem.visitDetails, prescriptionsToBeRegistered: rest },
                });
                setSelectedTabBttn(2);
            }
        }
    };

    return (
        <div>
            <Tabs
                value={selectedTabBttn}
                onChange={handleTabsBttns}
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className="tabsBttns stickyTabs innerTabs"
            >
                {mainTabs.map((tab, index) => {
                    return (
                        <Tab
                            {...tab}
                            key={index}
                            // disabled={patientData === null ? tab.disabled : false}
                            disabled={
                                // tabItem.editReferralOrPrescription === "referral" && tab.label === "Καταχώρηση Παραπεμπτικού" ? ""
                                tabItem?.editReferralOrPrescription !== null
                                    ? tabItem.editReferralOrPrescription === "referral" &&
                                      tab.label === "Καταχώρηση Παραπεμπτικού"
                                        ? false
                                        : tabItem.editReferralOrPrescription === "prescription" &&
                                          tab.label === "Καταχώρηση Συνταγής Φαρμάκων"
                                        ? false
                                        : true
                                    : tab.label === "Επίσκεψη"
                                    ? false
                                    : (tabItem.visitDetails.id === -1 ||
                                          tabItem.visitDetails.status === "" ||
                                          tabItem.visitDetails.status === "Ολοκληρωμένη") &&
                                      (tab.label === "Καταχώρηση Συνταγής Φαρμάκων" ||
                                          tab.label === "Καταχώρηση Παραπεμπτικού")
                                    ? true
                                    : patientData !== null
                                    ? false
                                    : selectedUnit !== ""
                                    ? tab.disabled
                                    : true
                            }
                            style={{
                                display:
                                    tab.label === "Θεώρηση Συνταγής" ||
                                    tab.label === "Θεώρηση Παραπεμπτικού" ||
                                    tab.label === "Εκτέλεση Παραπεμπτικού"
                                        ? "none"
                                        : "block",
                            }}
                        />
                    );
                })}
            </Tabs>
            <PatientContext.Provider value={patientData}>{showContent()}</PatientContext.Provider>
            <PopUp
                openPopUp={popPrescriptionWithTreatmentProtocolError}
                setOpenPopUp={setPopPrescriptionWithTreatmentProtocolError}
                title={"Σφάλμα Επανάληψης Συνταγής"}
                maxWidth="sm"
            >
                <ul>
                    <li>
                        Η συνταγή δεν μπορεί να επαναληφθεί.
                        {(tabItem.visitDetails.prescriptionsToBeRegistered.length > 0 ||
                            tabItem.visitDetails.referralsToBeRegistered.length > 0) &&
                            ` Σας παρακαλώ συνεχίστε με τις υπόλοιπες Συνταγές/Παραπεμπτικά!`}
                    </li>
                </ul>
            </PopUp>

            <PopUp
                openPopUp={tabAlertPopUp}
                setOpenPopUp={setTabAlertPopUp}
                title={"Προειδοποίηση"}
                maxWidth="sm"
            >
                <div className="mb-3" style={{ textAlign: "justify" }}>
                    <p>
                        Αυτή η σελίδα περιέχει δεδομένα που δεν έχουν οριστικοποιηθεί. Θέλετε να
                        πραγματοποιήσετε πλοήγηση; Πατήστε "ΟΚ" για να συνεχίσετε ή "Άκυρο" για να
                        ακυρώσετε τη λειτουργία πλοήγησης.
                    </p>
                </div>
                <div align="center">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={forceChangeTab}
                        style={{ marginRight: "5px" }}
                    >
                        ΟΚ
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => setTabAlertPopUp(false)}
                    >
                        ΑΚΥΡΟ
                    </Button>
                </div>
            </PopUp>
        </div>
    );
};

export default Esyntagografisi;
