import React, { useState } from "react";
import PopUp from "../../../../../Utilities/PopUp";
import "./AddNewEkaaCard.css";
import EkaaCardForm from "./EkaaCardForm";

function AddNewEkaaCard() {
    const [addCardPopUp, setAddCardPopUp] = useState(false);

    let data = {
        firstName: "",
        lastName: "",
        amka: "",
        euCardStartDate: "",
        euCardExpiration: "",
        euRegistryNo: "",
        euPpa: false,
        sex: { id: 0, name: "" },
        birthDate: "",
        address: "",
        postalCode: "",
        city: "",
        county: "",
        telephone: "",
        email: "",
        country: { Id: 0 },
        euSocialInsurance: { id: 0 },
    };

    return (
        <div>
            <button
                className="btn buttonPrimary"
                style={{ marginLeft: 5 }}
                onClick={() => setAddCardPopUp(true)}
            >
                Καταχώρηση Νέας Κάρτας Ε.Κ.Α.Α.
            </button>
            <PopUp
                openPopUp={addCardPopUp}
                setOpenPopUp={setAddCardPopUp}
                title={"Καταχώρηση Νέας Κάρτας"}
                maxWidth="md"
                showCloseButton={true}
            >
                <EkaaCardForm setAddCardPopUp={setAddCardPopUp} data={data} edit={false} />
            </PopUp>
        </div>
    );
}

export default AddNewEkaaCard;
