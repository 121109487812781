import React, { useState, useEffect, useContext } from "react";
import "./DoctorVisit.css";

import DoctorInfo from "./DoctorInfo/DoctorInfo.js";
import InsuredDetails from "./InsuredDetails/InsuredDetails.js";
import VisitDetails from "./VisitDetails/VisitDetails.js";
import VisitListingDetails from "./VisitListingDetails/VisitListingDetails.js";

import IdikaDoctorService from "../../../../services/MediPlus/Idika/IdikaDoctorService.js";
import MediPlusUserService from "../../../../services/MediPlus/MediPlusUserService.js";
import { Spinner } from "../../../Utilities/Utilities.js";
import PopUp from "../../../Utilities/PopUp.js";
import Unit from "./Unit/Unit.js";
import IdikaCredentialsForm from "./IdikaCredentialsForm/IdikaCredentialsForm.js";
import Announcements from "./Announcements/Announcements.js";

import Alert from "@material-ui/lab/Alert";

import { DoctorContext } from "../../Doctor.js";
import { PatientContext } from "../Esyntagografisi.js";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import NewDHPCMessages from "../NewDHPCMessages/NewDHPCMessages";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
        flexGrow: 1,
    },
}));

function DoctorVisit({
    addTab,
    tabIndex,
    tabItem,
    setTabItem,
    setSelectedTab,
    setSelectedTabBttn,
    popPrescriptionOrReferralForRegistration,
}) {
    const { doctorData, setDoctorData, unitPopUp, setUnitPopUp, dhpc } = useContext(DoctorContext);
    const patientData = useContext(PatientContext);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openPopUp, setOpenPopUp] = useState(false);
    const [modalResult, setModalResult] = useState("");

    const [infoPopUp, setInfoPopUp] = useState(tabItem.infoPopUp);

    useEffect(() => {
        if (doctorData.firstname !== "") {
            sessionStorage.setItem("mediPlusDoctorData", JSON.stringify(doctorData));
        }
    }, [doctorData]);

    // get doctor information after submitting new idika credentials
    // if the credentials are not correct then fetch doctor's stored data
    useEffect(() => {
        if (modalResult === "success") {
            setLoading(true);
            setError(null);
            IdikaDoctorService.getMe()
                .then((response) => {
                    const data = response.data.User;
                    setDoctorData({
                        id: data.id,
                        firstname: data.name.firstname,
                        lastname: data.name.lastname,
                        specialty: data.doctorSpecialty.name,
                        amka: data.amka,
                        etaaRegNo: data.etaaRegNo,
                    });
                    setLoading(false);
                    setUnitPopUp(true);
                })
                .catch((error) => {
                    setError(error.response.data);
                    setLoading(false);
                    setOpenPopUp(true);
                });
        } else if (modalResult === "failure") {
            MediPlusUserService.getUserByAuthToken()
                .then((response) => {
                    const data =
                        response.data.userDetails.idikaUserData !== null
                            ? JSON.parse(response.data.userDetails.idikaUserData).User
                            : null;
                    if (data !== null) {
                        setDoctorData({
                            id: data.id,
                            firstname: data.name.firstname,
                            lastname: data.name.lastname,
                            specialty: data.doctorSpecialty.name,
                            amka: data.amka,
                            etaaRegNo: data.etaaRegNo,
                        });
                    }
                })
                .catch((error) => {
                    setError(error.response.data);
                    setLoading(false);
                });
        }
        // eslint-disable-next-line
    }, [modalResult]);

    // fetch doctor's data
    useEffect(() => {
        if (doctorData.id !== -1) return;
        setLoading(true);
        IdikaDoctorService.getMe()
            .then((response) => {
                const data = response.data.User;
                setDoctorData({
                    id: data.id,
                    firstname: data.name.firstname,
                    lastname: data.name.lastname,
                    specialty: data.doctorSpecialty.name,
                    amka: data.amka,
                    etaaRegNo: data.etaaRegNo,
                });
                setTimeout(function () {
                    setUnitPopUp(true);
                }, 1000);
                setLoading(false);
            })
            .catch((error) => {
                setError(error.response.data);
                setLoading(false);
                setOpenPopUp(true);
            });
        // eslint-disable-next-line
    }, []);

    const [announcementsPopUp, setAnnouncementsPopUp] = useState(false);
    const [announcements, setAnnouncements] = useState(null);
    const [DHPCMessagesPopUp, setDHPCMessagesPopUp] = useState(false);

    const [visitDetails, setVisitDetails] = useState(tabItem.visitDetails);

    useEffect(() => {
        if (tabItem !== undefined && visitDetails !== undefined) {
            let referralsToBeRegistered = tabItem.visitDetails.referralsToBeRegistered;
            let prescriptionsToBeRegistered = tabItem.visitDetails.prescriptionsToBeRegistered;
            let vstDtls = {
                ...visitDetails,
                referralsToBeRegistered: referralsToBeRegistered,
                prescriptionsToBeRegistered: prescriptionsToBeRegistered,
            };
            setTabItem({ ...tabItem, visitDetails: vstDtls });
        }
        // eslint-disable-next-line
    }, [visitDetails]);

    const classes = useStyles();

    if (loading === true) return <Spinner align="center" animation="spinner-border" />;

    return (
        <div className="flexBoxWrap">
            {error !== null && openPopUp === false && (
                <Alert
                    severity="error"
                    action={
                        <button
                            className="btn buttonPrimary"
                            onClick={() => {
                                setOpenPopUp(true);
                            }}
                        >
                            Ενημέρωση στοιχείων
                        </button>
                    }
                >
                    {error.errors.error === "No IDIKA credentials are provided"
                        ? "Δεν έχετε εισάγει τα ΗΔΙΚΑ στοιχεία σας!"
                        : "Σας Παρακαλώ ενημερώστε τα ΗΔΙΚΑ στοιχεία σας!"}
                </Alert>
            )}
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={5}>
                        <DoctorInfo
                            firstname={doctorData.firstname}
                            lastname={doctorData.lastname}
                            specialty={doctorData.specialty}
                            amka={doctorData.amka}
                            etaaRegNo={doctorData.etaaRegNo}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <InsuredDetails
                            addTab={addTab}
                            patientData={patientData}
                            visitDetails={visitDetails}
                            setVisitDetails={setVisitDetails}
                            tabIndex={tabIndex}
                            tabItem={tabItem}
                            setTabItem={setTabItem}
                            setSelectedTab={setSelectedTab}
                        />
                    </Grid>
                </Grid>
            </div>

            {addTab !== null && patientData === null ? null : (
                <div className={classes.root}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={5}>
                            <VisitDetails
                                patientData={patientData}
                                doctorData={doctorData}
                                visitDetails={visitDetails}
                                setVisitDetails={setVisitDetails}
                                tabIndex={tabIndex}
                                tabItem={tabItem}
                                setTabItem={setTabItem}
                                popPrescriptionOrReferralForRegistration={
                                    popPrescriptionOrReferralForRegistration
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <VisitListingDetails
                                patientData={patientData}
                                tabItem={tabItem}
                                setTabItem={setTabItem}
                                setSelectedTabBttn={setSelectedTabBttn}
                            />
                        </Grid>
                    </Grid>
                </div>
            )}
            {error !== null ? (
                <PopUp
                    openPopUp={openPopUp}
                    setOpenPopUp={setOpenPopUp}
                    setModalResult={setModalResult}
                    title={"Ενημέρωση ΗΔΙΚΑ στοιχείων"}
                >
                    <IdikaCredentialsForm
                        errorMessage={error.errors.error}
                        setOpenPopUp={setOpenPopUp}
                        setModalResult={setModalResult}
                    />
                </PopUp>
            ) : null}
            {unitPopUp === true ? (
                <PopUp
                    openPopUp={unitPopUp}
                    showCloseButton={false}
                    title={"Μονάδα Συνταγογράφησης"}
                >
                    <Unit
                        setUnitPopUp={setUnitPopUp}
                        setAnnouncementsPopUp={setAnnouncementsPopUp}
                        announcements={announcements}
                        setAnnouncements={setAnnouncements}
                        setDHPCMessagesPopUp={setDHPCMessagesPopUp}
                        dhpc={dhpc}
                    />
                </PopUp>
            ) : null}
            {announcementsPopUp === true ? (
                <PopUp
                    openPopUp={announcementsPopUp}
                    showCloseButton={false}
                    title={"Ανακοινώσεις"}
                >
                    <Announcements
                        setAnnouncementsPopUp={setAnnouncementsPopUp}
                        announcements={announcements.announcements}
                    />
                </PopUp>
            ) : null}
            {infoPopUp === true ? (
                <PopUp openPopUp={infoPopUp} setOpenPopUp={setInfoPopUp} title={"Προειδοποίηση"}>
                    <div>
                        <ul>
                            {tabItem.infoArray.map((item, index) => {
                                return <li key={index}>{item}</li>;
                            })}
                        </ul>
                        <div align="center">
                            <button
                                className="btn buttonPrimary"
                                onClick={() => {
                                    setTabItem({ ...tabItem, infoPopUp: false });
                                    setInfoPopUp(false);
                                }}
                            >
                                Συνέχεια
                            </button>
                        </div>
                    </div>
                </PopUp>
            ) : null}
            {DHPCMessagesPopUp === true ? (
                <PopUp
                    openPopUp={DHPCMessagesPopUp}
                    setOpenPopUp={setDHPCMessagesPopUp}
                    showCloseButton={true}
                    title={`${dhpc.notPresented.length > 1 ? "Yπάρχουν" : "Υπάρχει"} (${dhpc.notPresented.length}) νέα μηνύματα DHPC`}
                >
                    <NewDHPCMessages messages={dhpc.notPresented} />
                </PopUp>
            ) : null}
        </div>
    );
}

export default DoctorVisit;
