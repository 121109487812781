import axios from "axios";
import { baseUrl, getOptions, postOptions } from "../../config.js";

class IdikaPatientService {
    constructor(baseUrl) {
        this.basePath = "/idika/patients";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getPatient(parameters) {
        return axios(getOptions(this.baseUrl, "", parameters));
    }

    getPatientBiovalues(parameters) {
        return axios(getOptions(this.baseUrl, "/biovalues", parameters));
    }

    getPrescriptions(parameters, amkaOrEkaa) {
        return axios(
            getOptions(
                this.baseUrl,
                `/${amkaOrEkaa}/prescriptions/${parameters.patientsConsent}`,
                parameters
            )
        );
    }

    saveOrAddBiovalues(data, amkaOrEkaa) {
        return axios(postOptions(this.baseUrl, `/${amkaOrEkaa}/biovalues`, data));
    }

    getReferrals(parameters, amkaOrEkaa) {
        return axios(
            getOptions(
                this.baseUrl,
                `/${amkaOrEkaa}/referrals/${parameters.patientsConsent}`,
                parameters
            )
        );
    }

    getMedicineHistoryIntolerances(parameters, amkaOrEkaa) {
        return axios(
            getOptions(this.baseUrl, `/${amkaOrEkaa}/medicinehistory/intolerances`, parameters)
        );
    }

    getMedicineHistoryProtocolParams(parameters, amkaOrEkaa) {
        return axios(
            getOptions(this.baseUrl, `/${amkaOrEkaa}/medicinehistory/protocolparams`, parameters)
        );
    }

    getNoPaperPrescriptionConsent(parameters) {
        return axios(getOptions(this.baseUrl, "/nopaper/prescription/consent", parameters));
    }

    getNoPaperReferralConsent(parameters) {
        return axios(getOptions(this.baseUrl, "/nopaper/referral/consent", parameters));
    }

    getMedicineHistoryFullPrescriptionFromAnotherDoctor(amkaOrEkaa, barcode, data) {
        return axios(
            getOptions(
                this.baseUrl,
                `/${amkaOrEkaa}/medicinehistory/full/prescription/${barcode}`,
                data
            )
        );
    }

    getMedicineHistoryFullReferralFromAnotherDoctor(amkaOrEkaa, barcode, data) {
        return axios(
            getOptions(
                this.baseUrl,
                `/${amkaOrEkaa}/medicinehistory/full/referral/${barcode}`,
                data
            )
        );
    }

    saveByEkaa(data) {
        return axios(postOptions(this.baseUrl, `/savebyekaa`, data));
    }
}

export default new IdikaPatientService(baseUrl);
