import React from "react";
import "./SearchItem.css";

import MediPlusUserService from "../../../../../../../services/MediPlus/MediPlusUserService.js";

function SearchItem({
    firstname,
    lastname,
    amkaOrEkaa,
    address,
    telephone,
    setError,
    setEkaaPatientPopUp,
    setDataForPatientTab,
    onOpenTabAfterCheckingForEkaaPatient,
}) {
    const handleOnClick = (e) => {
        let request = {};
        if (amkaOrEkaa.length === 20) request.ekaa = amkaOrEkaa;
        else request.amka = amkaOrEkaa;

        MediPlusUserService.getPatient(request)
            .then(({ data }) => {
                if (data.amka.length === 20) {
                    setEkaaPatientPopUp(true);
                    setDataForPatientTab(data);
                    return;
                }
                onOpenTabAfterCheckingForEkaaPatient(data);
            })
            .catch((error) => {
                // console.log(error);
                setError(error.response);
            });
    };

    return (
        <div className="seachItemRow" onClick={handleOnClick} value={amkaOrEkaa}>
            <div className="searchItem searchItemNumberBig">
                <p className="infoP">ΑΜΚΑ/ΕΚΑΑ</p>
                <p className="searchItemBig">{amkaOrEkaa}</p>
            </div>
            <div className="searchItem">
                <p className="infoP">Ονοματεπώνυμο</p>
                <p className="searchItemBig">{firstname + " " + lastname}</p>
            </div>

            <div className="searchItem">
                <p className="infoP">Διεύθυνση</p>
                <p className="searchItemBig">{address}</p>
            </div>
            <div className="searchItem searchItemNumber">
                <p className="infoP">Τηλέφωνο</p>
                <p className="searchItemBig">{telephone}</p>
            </div>
        </div>
    );
}

export default SearchItem;
