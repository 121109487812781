import React, { useEffect, useState } from "react";
import SimpleMenu from "../../../../../../Utilities/CustomMenu";
import PopUp from "../../../../../../Utilities/PopUp";
import Comments from "./Comments/Comments";
import CustomPrescription from "./CustomPrescription/CustomPrescription";
import EditInsuredData from "./EditInsuredData/EditInsuredData";
import FavoriteExams from "./FavoriteExams/FavoriteExams";
import FavoritePrescriptions from "./FavoritePrescriptions/FavoritePrescriptions";
import Insurances from "./Insurances/Insurances";
import MakeAppointment from "./MakeAppointment/MakeAppointment";
import PrescriptionHistory from "./PrescriptionHistory/PrescriptionHistory";
import ReferralHistory from "./ReferralHistory/ReferralHistory";
import VisitHistory from "./VisitHistory/VisitHistory";
import CustomPrescriptionsHistory from "./CustomPrescription/CustomPrescriptionHistory";
import EkaaCardForm from "../../AddNewEkaaCard/EkaaCardForm";

function Buttons({
    patient,
    setPatient,
    visitDetails,
    setVisitDetails,
    tabIndex,
    tabItem,
    setTabItem,
    setSelectedTab,
}) {
    const [visitHistory, setVisitHistory] = useState(false);
    const [referralHistory, setReferralHistory] = useState(false);
    const [prescriptionHistory, setPrescriptionHistory] = useState(false);
    const [customPrescriptionsHistory, setCustomPrescriptionsHistory] = useState(false);

    const [customPrescriptionBody, setCustomPrescriptionBody] = useState("");

    const [favoriteExams, setFavoriteExams] = useState(false);
    const [favoritePrescriptions, setFavoritePrescriptions] = useState(false);
    const [customPrescription, setCustomPrescription] = useState(false);

    const [insurances, setInsurances] = useState(false);
    const [editInsuredData, setEditInsuredData] = useState(false);
    const [editInsuredDataWithEkaa, setEditInsuredDataWithEkaa] = useState(false);
    const [ekaaData, setEkaaData] = useState({});
    const [makeAppointment, setMakeAppointment] = useState(false);
    const [comments, setComments] = useState(false);

    const handleVisitHistory = () => {
        setVisitHistory(true);
    };
    const handleReferralHistory = () => {
        setReferralHistory(true);
    };
    const handlePrescriptionHistory = () => {
        setPrescriptionHistory(true);
    };
    const handleCustomPrescriptionHistory = () => {
        setCustomPrescriptionsHistory(true);
    };

    const handleFavoriteExams = () => {
        setFavoriteExams(true);
    };
    const handleFavoritePrescriptions = () => {
        setFavoritePrescriptions(true);
    };
    const handleCustomPrescription = () => {
        setCustomPrescriptionBody("");
        setCustomPrescription(true);
    };

    const handleInsurances = () => {
        setInsurances(true);
    };
    const handleEditInsuredData = () => {
        if (patient.amka.length === 20) {
            let patientInformation = {
                amka: patient.amka,
                address: patient.address,
                postalCode: patient.postalCode,
                city: patient.city,
                telephone: patient.telephone,
                email: patient.email,
                firstName: patient.firstName,
                lastName: patient.lastName,
                euCardStartDate: patient.euCardStartDate,
                euCardExpiration: patient.euCardExpiration,
                euRegistryNo: patient.euRegistryNo,
                euPpa: patient.euPpa,
                sex: patient.sex,
                county: patient.county,
                country: patient.country,
                euSocialInsurance: { id: patient.euSocialInsurance.id },
                birthDate: patient.birthDate,
                firstName: patient.firstName,
                firstName: patient.firstName,
            };

            setEkaaData(patientInformation);
        }

        setEditInsuredDataWithEkaa(patient.amka.length === 20);
        setEditInsuredData(patient.amka.length !== 20);
    };
    const handleMakeAppointment = () => {
        sessionStorage.setItem(
            "patientInfoForAppointment",
            `${patient.firstName} ${patient.lastName} `
        );
        setSelectedTab(2);
    };
    const handleComments = () => {
        setComments(true);
    };

    const [otherButtonList, setOtherButtonList] = useState([]);

    useEffect(() => {
        let newOtherButtonList = [
            {
                name: "Επεξεργασία Στοιχείων Ασφαλισμένου",
                onClick: handleEditInsuredData,
            },
            { name: "Δημιουργία Ραντεβού", onClick: handleMakeAppointment },
            // { name: "Έκδοση Παραστατικού", onClick: null },
            { name: "Σχόλια", onClick: handleComments },
        ];
        if (tabItem.visitDetails.id === -1 && tabItem.visitDetails.status === "")
            newOtherButtonList.unshift({
                name: "Στοιχεία Ασφαλιστικής Ικανότητας",
                onClick: handleInsurances,
            });
        setOtherButtonList(newOtherButtonList);
        // eslint-disable-next-line
    }, [tabItem.visitDetails.id, tabItem.visitDetails.status]);

    return (
        <div className="mt-3">
            <div className="d-flex justify-content-around">
                <SimpleMenu
                    name={"Ιστορικο"}
                    menuItems={[
                        { name: "Ιστορικό Επισκέψεων", onClick: handleVisitHistory },
                        { name: "Ιστορικό Παραπεμπτικών", onClick: handleReferralHistory },
                        { name: "Ιστορικό Συνταγών", onClick: handlePrescriptionHistory },
                        {
                            name: "Ιστορικό Ιατρικών Σημειωμάτων",
                            onClick: handleCustomPrescriptionHistory,
                        },
                    ]}
                />
                <SimpleMenu
                    name={"Αγαπημενα"}
                    menuItems={[
                        { name: "Αγαπημένες Εξετάσεις", onClick: handleFavoriteExams },
                        { name: "Αγαπημένες Συνταγές", onClick: handleFavoritePrescriptions },
                        { name: "Ιατρικό Σημείωμα", onClick: handleCustomPrescription },
                    ]}
                />
                <SimpleMenu name={"Διαφορα"} menuItems={otherButtonList} />
            </div>

            <PopUp
                openPopUp={visitHistory}
                setOpenPopUp={setVisitHistory}
                showCloseButton={true}
                title={"Ιστορικό Επισκέψεων"}
                maxWidth="lg"
            >
                <VisitHistory
                    patient={patient}
                    visitDetails={visitDetails}
                    setVisitDetails={setVisitDetails}
                    tabIndex={tabIndex}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                />
            </PopUp>
            <PopUp
                openPopUp={referralHistory}
                setOpenPopUp={setReferralHistory}
                showCloseButton={true}
                title={"Ιστορικό Παραπεμπτικών"}
                maxWidth="lg"
            >
                <ReferralHistory
                    patient={patient}
                    setReferralHistory={setReferralHistory}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                />
            </PopUp>
            <PopUp
                openPopUp={prescriptionHistory}
                setOpenPopUp={setPrescriptionHistory}
                showCloseButton={true}
                title={"Ιστορικό Συνταγών"}
                maxWidth="lg"
            >
                <PrescriptionHistory
                    patient={patient}
                    setPrescriptionHistory={setPrescriptionHistory}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                />
            </PopUp>

            <PopUp
                openPopUp={customPrescriptionsHistory}
                setOpenPopUp={setCustomPrescriptionsHistory}
                showCloseButton={true}
                title={"Ιστορικό Ιατρικών Σημειωμάτων"}
                maxWidth="lg"
            >
                <CustomPrescriptionsHistory
                    setBody={setCustomPrescriptionBody}
                    setCustomPrescription={setCustomPrescription}
                />
            </PopUp>

            <PopUp
                openPopUp={favoriteExams}
                setOpenPopUp={setFavoriteExams}
                showCloseButton={true}
                title={"Αγαπημένες Εξετάσεις"}
                maxWidth="lg"
            >
                <FavoriteExams tabItem={tabItem} setTabItem={setTabItem} />
            </PopUp>
            <PopUp
                openPopUp={favoritePrescriptions}
                setOpenPopUp={setFavoritePrescriptions}
                showCloseButton={true}
                title={"Αγαπημένες Συνταγές"}
                maxWidth="lg"
            >
                <FavoritePrescriptions tabItem={tabItem} setTabItem={setTabItem} />
            </PopUp>
            <PopUp
                openPopUp={customPrescription}
                setOpenPopUp={setCustomPrescription}
                showCloseButton={true}
                title={"Ιατρικό Σημείωμα"}
                maxWidth="lg"
            >
                <CustomPrescription
                    body={customPrescriptionBody}
                    setBody={setCustomPrescriptionBody}
                />
            </PopUp>

            <PopUp
                openPopUp={insurances}
                setOpenPopUp={setInsurances}
                title={"Επιλογή Ασφαλιστικού Φορέα"}
                showCloseButton={false}
            >
                <Insurances
                    patientData={patient}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                    setShowInsurances={setInsurances}
                />
            </PopUp>
            <PopUp
                openPopUp={editInsuredData}
                setOpenPopUp={setEditInsuredData}
                showCloseButton={true}
                title={"Επεξεργασία στοιχείων ασφαλισμένου"}
            >
                <EditInsuredData patient={patient} setPatient={setPatient} />
            </PopUp>
            <PopUp
                openPopUp={editInsuredDataWithEkaa}
                setOpenPopUp={setEditInsuredDataWithEkaa}
                showCloseButton={true}
                title={"Επεξεργασία στοιχείων ασφαλισμένου"}
            >
                <EkaaCardForm
                    setAddCardPopUp={setEditInsuredDataWithEkaa}
                    data={ekaaData}
                    edit={true}
                    patient={patient}
                    setPatient={setPatient}
                />
            </PopUp>
            <PopUp
                openPopUp={makeAppointment}
                setOpenPopUp={setMakeAppointment}
                showCloseButton={true}
                title={"Επεξεργασία στοιχείων ασφαλισμένου"}
                maxWidth="sm"
            >
                <MakeAppointment patient={patient} />
            </PopUp>
            <PopUp
                openPopUp={comments}
                setOpenPopUp={setComments}
                showCloseButton={true}
                title={"Σχόλια"}
            >
                <Comments patientId={patient.id} />
            </PopUp>
        </div>
    );
}

export default Buttons;
