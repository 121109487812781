import axios from "axios";
import { baseUrl, getOptions, putOptions } from "../../config.js";

class IdikaDoctorService {
    constructor(baseUrl) {
        this.basePath = "/idika/doctors";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getMe() {
        return axios(getOptions(this.baseUrl));
    }

    getUnits() {
        return axios(getOptions(this.baseUrl, "/units"));
    }

    getSpecialtyExams(parameters) {
        return axios(getOptions(this.baseUrl, "/specialty/examinations", parameters));
    }

    getSpecialtyExamsPrices(parameters) {
        return axios(getOptions(this.baseUrl, "/specialty/examination/prices", parameters));
    }

    getFavoriteExams(parameters) {
        return axios(getOptions(this.baseUrl, "/specialty/examinations/favorites", parameters));
    }

    getFavoriteExamsAll() {
        return axios(getOptions(this.baseUrl, "/specialty/examinations/favorites/all"));
    }

    addOrRemoveFavoriteExam(id) {
        return axios(putOptions(this.baseUrl, `/specialty/examinations/favorites/${id}`));
    }

    getAllSpecialtyExaminationsPricesFilteredBySocialInsuranceId(parameters, socialInsuranceId) {
        return axios(
            getOptions(
                this.baseUrl,
                `/specialty/examination/prices/all/socialInsurance/${socialInsuranceId}`,
                parameters
            )
        );
    }

    getSpecificSpecialtyExaminationsPricesFilteredBySocialInsuranceId(
        parameters,
        socialInsuranceId,
        examinationId
    ) {
        return axios(
            getOptions(
                this.baseUrl,
                `/specialty/examination/prices/specific/socialInsurance/${socialInsuranceId}/${examinationId}`,
                parameters
            )
        );
    }

    getAllSpecialtyExaminationsFavoritesFilteredByExaminationGroupIdAndSocialInsuranceId(
        parameters,
        socialInsuranceId
    ) {
        return axios(
            getOptions(
                this.baseUrl,
                `/specialty/examinations/favorites/all/socialInsurance/${socialInsuranceId}`,
                parameters
            )
        );
    }

    getUserUnitLimits(userUnitId) {
        return axios(getOptions(this.baseUrl, `/limits/userunit/${userUnitId}`));
    }

    getSpecialtyTreatmentProtocolMedicines(parameters) {
        return axios(
            getOptions(this.baseUrl, `/specialty/treatmentprotocols/medicines`, parameters)
        );
    }

    getQuotaVisits(unitId, socialInsuranceId) {
        return axios(getOptions(this.baseUrl, `/units/${unitId}/visits/quota/2`));
    }
}

export default new IdikaDoctorService(baseUrl);
