import React, { useContext } from "react";
import { FavoriteExamsContext } from "../FavoriteExams";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

function ExamResults({ exams }) {
    const { favoriteExams, addOrRemoveExam } = useContext(FavoriteExamsContext);

    if (exams === null || favoriteExams === null) return null;

    const checkIfExamIsInFavoriteExams = (examId) => {
        for (let exam of favoriteExams) {
            if (exam.id === examId) return true;
        }
        return false;
    };

    return (
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <b>ID</b>
                            </TableCell>
                            <TableCell>
                                <b>Εξέταση</b>
                            </TableCell>
                            <TableCell>
                                <b>Λέξεις-Κλειδιά</b>
                            </TableCell>
                            <TableCell>
                                <b>Τιμή Εξέτασης</b>
                            </TableCell>
                            {/* <TableCell>
                                <b>Προληπτική</b>
                            </TableCell> */}
                            <TableCell>
                                <b>Δράση</b>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {exams.exams.map((exam) => {
                            let check = checkIfExamIsInFavoriteExams(exam.id);
                            return (
                                <TableRow key={exam.id}>
                                    <TableCell component="th" scope="row">
                                        {exam.id}
                                    </TableCell>
                                    <TableCell>{exam.description}</TableCell>
                                    <TableCell>{exam.keyWords}</TableCell>
                                    <TableCell>{exam.price}</TableCell>
                                    {/* <TableCell>
                                            <input type="checkbox" disabled />
                                        </TableCell> */}
                                    <TableCell>
                                        <button
                                            className={
                                                check ? "btn buttonDanger" : "btn buttonPrimary"
                                            }
                                            id={exam.id}
                                            onClick={addOrRemoveExam}
                                        >
                                            {check ? "Διαγραφή" : "Προσθήκη"}
                                        </button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
        </div>
    );
}

export default ExamResults;
