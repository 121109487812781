import React, { useState, useEffect } from "react";
import "./Treatment.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IdikaMasterDataService from "../../../../../services/MediPlus/Idika/IdikaMasterDataService";
import IdikaDoctorService from "../../../../../services/MediPlus/Idika/IdikaDoctorService";
import { Spinner } from "../../../../Utilities/Utilities";
import PopUp from "../../../../Utilities/PopUp.js";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import { mainInputColumns, mainColumns, effectiveTimeArray } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TextareaAutosize } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import Pagination from "@material-ui/lab/Pagination";
import { Multiselect } from "multiselect-react-dropdown";
import IdikaPrescriptionService from "../../../../../services/MediPlus/Idika/IdikaPrescriptionService";
import { medicineHistoryTblColumns } from "./config.js";
import { formatMaterialUiDate } from "../../../../Utilities/Utilities";
import MediPlusBannerService from "../../../../../services/MediPlus/MediPlusBannerService";
import GalenicMedicine from "./GalenicMedicine";
import { DoctorContext } from "../../../Doctor";
import { useContext } from "react";
import DHPCAction from "../../DoctorHome/DHPCInformation/DHPCAction";

function MedicineDetails({ medicine }) {
    const headerColumnNames = ["Κωδικός ICD-10", "Τίτλος", "Από ηλικία", "Έως ηλικία", "Φύλο"];

    const booleanValueToGreek = (value) => {
        if (value === true) return "ΝΑΙ";
        else if (value === false) return "OXI";
        else return null;
    };

    return (
        <div>
            <Paper className="mb-3">
                <h3 className="ml-3">Βασικές</h3>
                <div className="flexBoxInfoWrap">
                    <div className="flexColumn">
                        <div className="flexWrap">
                            <p className="infoP alignRight">Εμπορική Ονομασία</p>
                            <p className="noMargin" id="insurdFullname">
                                {medicine.commercialName}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Μορφή</p>
                            <p className="noMargin" id="insurdFullname">
                                {medicine.formCode}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Περιεκτικότητα</p>
                            <p className="noMargin" id="insurdFullname">
                                {medicine.content}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Συσκευασία</p>
                            <p className="noMargin" id="insurdFullname">
                                {medicine.packageForm}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">ATC</p>
                            <p className="noMargin" id="insurdFullname">
                                {medicine.atcCode}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Τύπος Φαρμάκου</p>
                            <p className="noMargin" id="insurdFullname">
                                {medicine.medicineType}
                            </p>
                        </div>
                    </div>
                    <div className="flexColumn">
                        <div className="flexWrap">
                            <p className="infoP alignRight">Τιμή Αποζ. Ασφ.</p>
                            <p className="noMargin" id="insurdFullname">
                                {medicine.referencePrice}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Τιμή Λιανικής</p>
                            <p className="noMargin" id="insurdFullname">
                                {medicine.retailPrice}
                            </p>
                        </div>
                    </div>
                </div>
            </Paper>
            <Paper className="mb-3">
                <h3 className="ml-3">Λεπτομέρειες</h3>
                <div className="flexBoxInfoWrap">
                    <div className="flexColumn">
                        <div className="flexWrap">
                            <p className="infoP alignRight">Θετική λίστα</p>
                            <p className="noMargin" id="insurdFullname">
                                {booleanValueToGreek(medicine.positiveList)}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Αρνητική λίστα</p>
                            <p className="noMargin" id="insurdFullname">
                                {booleanValueToGreek(medicine.negativeList)}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Αποσυρμένο</p>
                            <p className="noMargin" id="insurdFullname">
                                {booleanValueToGreek(medicine.withdrawn)}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Σε κυκλοφορία</p>
                            <p className="noMargin" id="insurdFullname">
                                {booleanValueToGreek(medicine.inCirculation)}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Συνταγ/ση μόνο μέσω ΘΠΣ</p>
                            <p className="noMargin" id="insurdFullname">
                                {booleanValueToGreek(medicine.onlyByProtocol)}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Ειδική εισαγωγή</p>
                            <p className="noMargin" id="insurdFullname">
                                {booleanValueToGreek(medicine.specialImport)}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Φάρμακο Ι.Φ.Ε.Τ.</p>
                            <p className="noMargin" id="insurdFullname">
                                {booleanValueToGreek(medicine.ifet)}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Μη Συνταγογραφούμενο</p>
                            <p className="noMargin" id="insurdFullname">
                                {booleanValueToGreek(medicine.nonPrescriptable)}
                            </p>
                        </div>
                    </div>
                    <div className="flexColumn">
                        <div className="flexWrap">
                            <p className="infoP alignRight">Νόμου 3816 </p>
                            <p className="noMargin" id="insurdFullname">
                                {booleanValueToGreek(medicine.n3816)}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Εκτέλεση σε </p>
                            <p className="noMargin" id="insurdFullname">
                                {medicine.executionUnit !== "" && medicine.executionUnit.name}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Ναρκωτικά</p>
                            <p className="noMargin" id="insurdFullname">
                                {booleanValueToGreek(medicine.drug)}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Κατηγορία Ναρκωτικών</p>
                            <p className="noMargin" id="insurdFullname">
                                {medicine.drugCategoryId}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Έγκριση από ΕΟΠΥΥ</p>
                            <p className="noMargin" id="insurdFullname">
                                {booleanValueToGreek(medicine.eopyyPreapproval)}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Εμβόλιο Απευαισθητοποίησης</p>
                            <p className="noMargin" id="insurdFullname">
                                {booleanValueToGreek(medicine.desensitizationVaccine)}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Αναλώσιμο</p>
                            <p className="noMargin" id="insurdFullname">
                                {booleanValueToGreek(medicine.isConsumable)}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Αντιβιοτικό</p>
                            <p className="noMargin" id="insurdFullname">
                                {booleanValueToGreek(medicine.isAntibiotic)}
                            </p>
                        </div>
                    </div>
                </div>
            </Paper>
            <Paper className="mb-3">
                <h3 className="ml-3">SPC Φίλτρα - Σχετικές διαγνώσεις Icd10</h3>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headerColumnNames.map((name, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <b>{name}</b>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody></TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

const initialStateForSearchMedicine = {
    commercialName: "",
    atcCode: "",
    activeSubstance: "",
    barcode: "",
    formCode: "",
    content: "",
    packageForm: "",
    type: "",
    // medicineGroupId: "",
};

function SearchMedicine({
    addPrescriptionState,
    setAddPrescriptionState,
    setOpenPopUp,
    setInfoPopUp,
    setInfoMessage,
    checkBoxes,
    tabItem,
    setShowBanner,
    setBanner,
    galenic,
    galenicIndex,
    medicineInShortage,
    noInnCases,
}) {
    const doctor = useContext(DoctorContext);
    const [state, setState] = useState(initialStateForSearchMedicine);
    const handleStateChange = (event) => {
        //we added this for mozilla input autocomplete
        // https://github.com/facebook/react/issues/18986
        Object.defineProperty(event.target, "defaultValue", {
            configurable: true,
            get() {
                return "";
            },
            set() {},
        });
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const [radioButton, setRadioButton] = useState("commercialName");
    const handleRadioButtonChange = (event) => {
        setRadioButton(event.target.value);
        setState({ ...state, commercialName: "", atcCode: "", activeSubstance: "" });
    };

    const [page, setPage] = useState(1);
    const handlePageChange = (event, value) => {
        setPage(value);
        setFetch(true);
    };

    const [pageTreatmentProtocol, setPageTreatmentProtocol] = useState(1);
    const handlePageTreatmentProtocolChange = (event, value) => {
        setPageTreatmentProtocol(value);
        setFetch(true);
    };

    const [medicines, setMedicines] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const onSubmit = (e) => {
        e.preventDefault();
        setPage(1);
        setFetch(true);
    };

    const [fetch, setFetch] = useState(false);
    const [showPagination, setShowPagination] = useState(false);
    const [selectedTreatmentProtocol, setSelectedTreatmentProtocol] =
        useState("noTreatmentProtocol");
    const handleTreatmentProtocolSelect = (e) => {
        if (e.target.value !== "noTreatmentProtocol") {
            var selectedProtocol = JSON.parse(e.target.value);

            const doctorData = JSON.parse(sessionStorage.getItem("mediPlusDoctorData"));

            let request = {
                activeSubstance: selectedProtocol.activeSubstance.description,
                specialty: doctorData.specialty,
            };

            MediPlusBannerService.getBannerByActiveSubstance(request)
                .then(({ data }) => {
                    if (data !== "") {
                        setBanner(<img alt="img" src={`data:image/jpeg;base64,${data.bytes}`} />);
                        setShowBanner(true);
                    }
                })
                .catch((err) => {
                    // console.log(err.response);
                });
        }
        setSelectedTreatmentProtocol(e.target.value);
        setState(initialStateForSearchMedicine);
    };
    useEffect(() => {
        //change radio button to commercialName if there is a treatment protocol
        if (selectedTreatmentProtocol !== "noTreatmentProtocol") {
            setRadioButton("commercialName");
        }
        setShowPagination(false);
        setPage(1);
        setPageTreatmentProtocol(1);
    }, [selectedTreatmentProtocol]);

    useEffect(() => {
        if (fetch === false) return;
        setError(null);
        setLoading(true);
        if (selectedTreatmentProtocol === "noTreatmentProtocol") {
            IdikaMasterDataService.getMedicines({ ...state, size: 5, page: page - 1 })
                .then(({ data }) => {
                    setMedicines(data);
                })
                .catch((err) => {
                    // console.log(err.response);
                    setError(err);
                })
                .finally(() => {
                    setLoading(false);
                    setShowPagination(true);
                    setFetch(false);
                });
        } else {
            let treatmentProtocol = JSON.parse(selectedTreatmentProtocol);
            let request = {
                activeSubstanceId: treatmentProtocol.activeSubstance.id,
                content: treatmentProtocol.content,
                atcCode: treatmentProtocol.atcCode,
                searchFilter: state.commercialName,
                page: pageTreatmentProtocol - 1,
                size: 5,
            };
            IdikaDoctorService.getSpecialtyTreatmentProtocolMedicines(request)
                .then(({ data }) => {
                    let treatmentProtocolPatientStepId = treatmentProtocol.patientStep.id;
                    let treatmentProtocolVisitPastId = treatmentProtocol.visitPastId;
                    let filteredMedicines = {
                        ...data,
                        medicines: data.medicines.map((med) => {
                            med.protocolDetails = {
                                treatmentProtocolPatientStepId,
                                treatmentProtocolVisitPastId,
                            };
                            return med;
                        }),
                    };
                    setMedicines(filteredMedicines);
                })
                .catch((err) => {
                    //console.log(err.response);
                    setError(err);
                })
                .finally(() => {
                    setLoading(false);
                    setShowPagination(true);
                    setFetch(false);
                });
        }
        // eslint-disable-next-line
    }, [fetch]);

    const [medicineDetails, setMedicineDetails] = useState(null);
    const [medicineDetailsPopUp, setMedicineDetailsPopUp] = useState(false);
    const showMedicineDetails = (medicine) => {
        setMedicineDetails(medicine);
        setMedicineDetailsPopUp(true);
    };

    const medicineObjectForRequest = {
        prescriptionBarcodeId: 0,
        barcode: "",
        comment: "",
        eofCode: "",
        displayName: "",
        doseQuantity: { value: "0", unit: "" },
        rateQuantity: { value: 0, unit: "d" },
        effectiveTime: { id: 0, value: 0, unit: "" },
        diagnoses: [],
        genericMedicine: false,
        insuredParticipationRate: "",
        quantity: 0,
        treatmentProtocolPatientStepId: 0,
        diseaseId: -1,
        highRiskGroupId: 0,
        isConsumable: false,
    };

    const galenicMedicineObjectForRequest = {
        prescriptionBarcodeId: 0,
        barcode: "",
        comment: "",
        eofCode: "",
        displayName: "",
        doseQuantity: { value: "0", unit: "" },
        rateQuantity: { value: 0, unit: "d" },
        effectiveTime: { id: 0, value: 0, unit: "" },
        diagnoses: [],
        genericMedicine: false,
        insuredParticipationRate: "",
        quantity: 1,
        treatmentProtocolPatientStepId: 0,
        diseaseId: -1,
        highRiskGroupId: 0,
        isConsumable: false,
    };

    const findIcd10sFromTreatmentProtocolIcd10s = (treatmentProtocols, stepId, icd10s) => {
        let returnedIcd10s = [];
        for (let tp of treatmentProtocols) {
            for (let step of tp.steps.step) {
                if (step.id === stepId) {
                    for (let icd10 of icd10s) {
                        for (let tpIcd10 of tp.treatmentProtocolICD10s.treatmentProtocolICD10) {
                            if (tpIcd10.icd10.id === icd10.id) {
                                returnedIcd10s.push(icd10);
                            }
                        }
                    }
                }
            }
        }
        return returnedIcd10s;
    };

    const [rulesErrorPopUp, setRulesErrorPopUp] = useState(false);
    const [rulesErrors, setRulesErrors] = useState([]);
    // this is a function that is used to apply the rules that we found
    // from IDIKA when a medicine is going to be added to our medicines array
    const rules = (medicine) => {
        let errors = [];

        if (addPrescriptionState.medicines.length > 0) {
            if (checkBoxes.negativeList !== medicine.negativeList) {
                errors.push(
                    "Δεν επιτρέπεται συμμετοχή αρνητικής λίστας φαρμάκων με φάρμακα μη αρνητικής λίστας"
                );
            }

            if (
                checkBoxes.limitedExec !== medicine.limitedExec ||
                checkBoxes.limitedExecutionUnitId !== medicine.limitedExecutionUnitId
            ) {
                errors.push(
                    "Όλα τα φάρμακα πρέπει να έχουν περιορισμό στην εκτέλεση και την ίδια μονάδα περιορισμένης εκτέλεσης"
                );
            }

            if (checkBoxes.highCost !== medicine.highCost) {
                errors.push(
                    "Δεν επιτρέπεται συμμετοχή υψηλού κόστους φαρμάκων με φάρμακα μη υψηλού κόστους"
                );
            }
            if (checkBoxes.ifet !== medicine.ifet) {
                errors.push("Δεν επιτρέπεται συμμετοχή ifet φαρμάκων με φάρμακα μη ifet");
            }
            if (checkBoxes.desensitizationVaccine !== medicine.desensitizationVaccine) {
                errors.push(
                    "Δεν επιτρέπεται συμμετοχή φαρμάκων με εμβόλιο απευαισθητοποίησης με φάρμακα χωρίς εμβόλιο απευαισθητοποίησης"
                );
            }
            if (checkBoxes.n3816 !== medicine.n3816) {
                errors.push(
                    "Δεν επιτρέπεται συμμετοχή σοβαρών παθήσεων φαρμάκων με φάρμακα μη σοβαρών παθήσεων"
                );
            }
            if (checkBoxes.executedAtIka !== medicine.executedAtIka) {
                errors.push(
                    "Δεν επιτρέπεται συμμετοχή φαρμάκων που εκτελούνται μόνο από Φαρμακείο του Ε.Ο.Π.Y.Υ. με φάρμακα που δεν εκτελούνται από Φαρμακείο του Ε.Ο.Π.Y.Υ."
                );
            }
            if (checkBoxes.eopyyPreapproval !== medicine.eopyyPreapproval) {
                errors.push(
                    "Δεν επιτρέπεται συμμετοχή φαρμάκων που απαιτούν προέγκριση από επιτροπή με φάρμακα που δεν απαιτούν προέγκριση από επιτροπή"
                );
            }
            if (checkBoxes.notInPharCost !== medicine.notInPharCost) {
                errors.push(
                    "Δεν επιτρέπεται συμμετοχή φαρμάκων εκτός φαρμακευτικής δαπάνης Ε.Ο.Π.Υ.Υ. με φάρμακα που δεν είναι εκτός φαρμακευτικής δαπάνης Ε.Ο.Π.Υ.Υ."
                );
            }
            if (checkBoxes.drug !== medicine.drug) {
                errors.push(
                    "Δεν επιτρέπεται συμμετοχή φαρμάκων με ναρκωτικά με φάρμακα χωρίς ναρκωτικά"
                );
            }
            if (checkBoxes.isConsumable !== medicine.isConsumable) {
                errors.push(
                    "Η συνταγή πρέπει να περιέχει αναλώσιμα φάρμακα μόνο εάν περιέχει τουλάχιστον ένα αναλώσιμο φάρμακο"
                );
            }
        }

        if (medicine.desensitizationVaccine && addPrescriptionState.repeatId > 1) {
            errors.push(
                "Δεν επιτρέπεται η συγκεκριμένη επαναληψιμότητα για συνταγές που περιέχουν εμβόλια απευαισθητοποίησης."
            );
        }

        if (medicine.isConsumable === true && addPrescriptionState.repeatId !== "1") {
            errors.push("Η συνταγή με αναλώσιμα δεν μπορεί να επαναληφθεί");
        }

        if (medicine.drug === true) {
            const prescriptionRepeatIds = ["1", "2", "3"];
            let found = prescriptionRepeatIds.find((id) => id === addPrescriptionState.repeatId);
            if (found === undefined) {
                errors.push(
                    "Η συνταγή με φάρμακα δεν μπορεί να επαναληφθεί περισσότερο από 3 μήνες"
                );
            }
            if (addPrescriptionState.medicines.length > 0) {
                found = addPrescriptionState.medicines.find(
                    (med) => med.drugCategoryId === medicine.drugCategoryId
                );
                if (found === undefined) {
                    errors.push(
                        "Η συνταγή με φάρμακα πρέπει να περιέχει φάρμακα από την ίδια κατηγορία φαρμάκων"
                    );
                }
            }
        }

        if (medicine.n3816 === true && medicine.executionUnit === "") {
            errors.push(
                "Το φάρμακο πρέπει να έχει αναγνωριστικό μονάδας εκτέλεσης για το φάρμακο N3816"
            );
        }

        let patientData = tabItem.patientData;
        let activeInsurances = tabItem.patientData.activeInsurances.activeInsurance;
        let activeInsurance = activeInsurances[patientData.selectedActiveInsurance];

        if (
            medicine.isConsumable === true &&
            activeInsurance.socialInsurance.canPrescribeConsumable === false
        ) {
            errors.push(
                "Η συνταγογράφηση με αναλώσιμα φάρμακα δεν επιτρέπεται για την κοινωνική ασφάλιση του ασθενούς"
            );
        }

        if (medicine.ePresEnabled === false) {
            errors.push("Το φάρμακο δεν είναι συνταγογραφούμενο");
        }

        if (medicine.inCirculation === false) {
            errors.push("Το φάρμακο δεν είναι σε κυκλοφορία");
        }

        if (
            addPrescriptionState.prescriptionBasedOnCommercialNameId === true &&
            medicine.noInn === false
        ) {
            errors.push("Το φάρμακο δεν μπορεί να συνταγογραφηθεί με εμπορική ονομασία");
        }

        let noInnCase = noInnCases.noInNCases.find(
            (element) =>
                element.id.toString() ===
                addPrescriptionState.prescriptionBasedOnCommercialNameReasonId
        );

        if (
            medicine.negativeList &&
            (addPrescriptionState.prescriptionBasedOnCommercialNameId === false ||
                (addPrescriptionState.prescriptionBasedOnCommercialNameId === true &&
                    noInnCase.isNegativeList === false))
        ) {
            errors.push(
                "Τα φάρμακα αρνητικής λίστας συνταγογραφούνται υποχρεωτικά με εμπορική ονομασία με αιτιολογία Μη αποζημιούμενο φάρμακο."
            );
        }

        return errors;
    };

    const [openDHPCPopUp, setOpenDHPCPopUp] = useState(false);
    const [dhpcInfo, setDhpcInfo] = useState({});

    const [medicineInShortagePopUp, setMedicineInShortagePopUp] = useState(false);
    const [medicineInShortageInfo, setMedicineInShortageInfo] = useState({});

    const checkIfMedicineIsInShortage = (medicine) => {
        let tempPatientData = tabItem.patientData;
        let tempActiveInsurances = tabItem.patientData.activeInsurances.activeInsurance;
        let tempActiveInsurance = tempActiveInsurances[tempPatientData.selectedActiveInsurance];
        const noInnMandatory = tempActiveInsurance.socialInsurance.noInnMandatory;

        let noInnCase = noInnCases.noInNCases.find(
            (element) =>
                element.id.toString() ===
                addPrescriptionState.prescriptionBasedOnCommercialNameReasonId
        );

        let isNegativeList = noInnCase ? noInnCase.isNegativeList : false;

        let shortageMed = medicineInShortage.find((element) => element.medicineId === medicine.id);

        if (
            !isNegativeList &&
            !noInnMandatory &&
            addPrescriptionState.prescriptionBasedOnCommercialNameId &&
            shortageMed
        ) {
            let effectiveFrom = new Date(shortageMed.effectiveFrom);
            let effectiveTo = new Date(shortageMed.effectiveTo);
            let currentDate = new Date();
            if (isNaN(effectiveTo.getTime())) {
                return effectiveFrom < currentDate;
            } else {
                return effectiveFrom < currentDate && currentDate < effectiveTo;
            }
        } else {
            return false;
        }
    };

    const addMedicine = (medicine) => {
        // add medicine

        const isInShortage = checkIfMedicineIsInShortage(medicine);

        if (isInShortage) {
            setMedicineInShortageInfo(medicine);
            setMedicineInShortagePopUp(true);
            return;
        }

        let medicineDHPCByMedicineId = doctor.dhpc.allMessages.filter(
            (element) => element.medicines.item.id === medicine.id
        );
        let medicineDHPCByMedicineGroupId = doctor.dhpc.allMessages.filter(
            (element) => element.medicines.item.medicineGroupId === medicine.medicineGroupId
        );

        let medicineDHPC = [...medicineDHPCByMedicineId, ...medicineDHPCByMedicineGroupId];
        medicineDHPC = medicineDHPC.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);

        medicineDHPC.forEach((item) => {
            let dhpcToCheck = doctor.dhpc.myMessages.find(
                (element) => element.dhpcMessageId === item.id
            );
            if (dhpcToCheck && dhpcToCheck.acknowledged === "") {
                // Not acknowledged
                setDhpcInfo({
                    dhpc: item,
                    close: () => {
                        setOpenDHPCPopUp(false);
                    },
                });
                setOpenDHPCPopUp(true);
                return;
            }
        });

        let errors = rules(medicine);
        if (errors.length > 0) {
            setRulesErrors(errors);
            setRulesErrorPopUp(true);
            return;
        }
        medicine.request = medicineObjectForRequest;
        medicine.request.barcode = medicine.barcode.toString();
        medicine.request.eofCode = medicine.eofCode.toString();
        medicine.request.displayName = medicine.commercialName;
        medicine.request.doseQuantity.unit = medicine.doseUnit;
        medicine.request.isConsumable = medicine.isConsumable;

        let patientData = tabItem.patientData;
        let activeInsurances = tabItem.patientData.activeInsurances.activeInsurance;
        let activeInsurance = activeInsurances[patientData.selectedActiveInsurance];
        if (
            addPrescriptionState.prescriptionBasedOnCommercialNameId === false &&
            activeInsurance.socialInsurance.Eopyy === true
        ) {
            if (
                medicine.medicineType === "G" ||
                (medicine.medicineTypeAll === "O" &&
                    medicine.typeOException === true &&
                    (medicine.positiveList === true ||
                        medicine.specialImport === true ||
                        medicine.ifet === true) &&
                    medicine.inCirculation === true &&
                    medicine.withdrawn === false)
            )
                medicine.request.genericMedicine = true;
        }
        if (medicine.defaultPercentage === true && medicine.participationPercentage !== "") {
            medicine.request.insuredParticipationRate = medicine.participationPercentage;
        }
        if (medicine.protocolDetails !== undefined) {
            medicine.request.treatmentProtocolPatientStepId =
                medicine.protocolDetails.treatmentProtocolPatientStepId;
            medicine.request.diagnoses = findIcd10sFromTreatmentProtocolIcd10s(
                addPrescriptionState.allTreatmentProtocols.treatmentProtocols,
                medicine.protocolDetails.treatmentProtocolPatientStepId,
                addPrescriptionState.icd10s
            );
        }
        if (addPrescriptionState.medicines.length === 0) {
            medicine.request.prescriptionBarcodeId = 1;
        } else {
            let maxPrescriptionId = 1;
            for (let med of addPrescriptionState.medicines) {
                if (med.request.prescriptionBarcodeId > maxPrescriptionId) {
                    maxPrescriptionId = med.request.prescriptionBarcodeId;
                }
            }
            medicine.request.prescriptionBarcodeId = maxPrescriptionId + 1;
        }
        if (medicine.protocolDetails === undefined) {
            setAddPrescriptionState({
                ...addPrescriptionState,
                medicines: [...addPrescriptionState.medicines, medicine],
            });
        } else {
            let newTreatmentProtocols =
                addPrescriptionState.insertedTreatmentProtocols.treatmentProtocols.filter(
                    (treatmentProtocol) => {
                        if (
                            treatmentProtocol.visitPastId ===
                            medicine.protocolDetails.treatmentProtocolVisitPastId
                        ) {
                            treatmentProtocol.treatmentInserted = true;
                        }
                        return treatmentProtocol;
                    }
                );
            setAddPrescriptionState({
                ...addPrescriptionState,
                medicines: [...addPrescriptionState.medicines, medicine],
                insertedTreatmentProtocols: {
                    ...addPrescriptionState.insertedTreatmentProtocols,
                    treatmentProtocols: newTreatmentProtocols,
                },
            });
        }
        if (medicine.infoPopUp !== "") {
            setInfoMessage(medicine.infoPopUp.replaceAll("&#xd;", ""));
            setInfoPopUp(true);
        }

        const doctorData = JSON.parse(sessionStorage.getItem("mediPlusDoctorData"));

        let mainActiveSubstance = "";
        //case 1: one active substance
        //case 2: more than one active substances --> get main
        if (medicine.activeSubstances.activeSubstance.length === undefined) {
            mainActiveSubstance =
                medicine.activeSubstances.activeSubstance.activeSubstance.description;
        } else {
            for (let sub of medicine.activeSubstances.activeSubstance) {
                if (sub.mainActiveSubstance === true) {
                    mainActiveSubstance = sub.activeSubstance.description;
                }
            }
        }

        let request = {
            activeSubstance: mainActiveSubstance,
            specialty: doctorData.specialty,
        };

        MediPlusBannerService.getBannerByActiveSubstance(request)
            .then(({ data }) => {
                if (data !== "") {
                    setBanner(<img alt="img" src={`data:image/jpeg;base64,${data.bytes}`} />);
                    setShowBanner(true);
                }
            })
            .catch((err) => {
                // console.log(err.response);
            });
        setOpenPopUp(false);
    };

    const addGalenicMedicine = (medicine) => {
        const isInShortage = checkIfMedicineIsInShortage(medicine);

        if (isInShortage) {
            setMedicineInShortageInfo(medicine);
            setMedicineInShortagePopUp(true);
            return;
        }

        let medicineDHPCByMedicineId = doctor.dhpc.allMessages.filter(
            (element) => element.medicines.item.id === medicine.id
        );
        let medicineDHPCByMedicineGroupId = doctor.dhpc.allMessages.filter(
            (element) => element.medicines.item.medicineGroupId === medicine.medicineGroupId
        );

        let medicineDHPC = [...medicineDHPCByMedicineId, ...medicineDHPCByMedicineGroupId];
        medicineDHPC = medicineDHPC.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);

        medicineDHPC.forEach((item) => {
            let dhpcToCheck = doctor.dhpc.myMessages.find(
                (element) => element.dhpcMessageId === item.id
            );
            if (dhpcToCheck && dhpcToCheck.acknowledged === "") {
                // Not acknowledged
                setDhpcInfo({
                    dhpc: item,
                    close: () => {
                        setOpenDHPCPopUp(false);
                    },
                });
                setOpenDHPCPopUp(true);
                return;
            }
        });

        let errors = rules(medicine);
        if (errors.length > 0) {
            setRulesErrors(errors);
            setRulesErrorPopUp(true);
            return;
        }

        medicine.request = galenicMedicineObjectForRequest;
        medicine.request.barcode = medicine.barcode.toString();
        medicine.request.eofCode = medicine.eofCode.toString();
        medicine.request.displayName = medicine.commercialName;
        medicine.request.doseQuantity.unit = medicine.doseUnit;
        medicine.request.isConsumable = medicine.isConsumable;
        medicine.comments = "";

        let patientData = tabItem.patientData;
        let activeInsurances = tabItem.patientData.activeInsurances.activeInsurance;
        let activeInsurance = activeInsurances[patientData.selectedActiveInsurance];
        if (
            addPrescriptionState.prescriptionBasedOnCommercialNameId === false &&
            activeInsurance.socialInsurance.Eopyy === true
        ) {
            if (
                medicine.medicineType === "G" ||
                (medicine.medicineTypeAll === "O" &&
                    medicine.typeOException === true &&
                    (medicine.positiveList === true ||
                        medicine.specialImport === true ||
                        medicine.ifet === true) &&
                    medicine.inCirculation === true &&
                    medicine.withdrawn === false)
            )
                medicine.request.genericMedicine = true;
        }
        if (medicine.defaultPercentage === true && medicine.participationPercentage !== "") {
            medicine.request.insuredParticipationRate = medicine.participationPercentage;
        }

        let meds = addPrescriptionState.medicines;
        meds[galenicIndex].request.medicines = [...meds[galenicIndex].request.medicines, medicine];

        setAddPrescriptionState({
            ...addPrescriptionState,
            medicines: [...meds],
        });

        // if (medicine.infoPopUp !== "") {
        //     setInfoMessage(medicine.infoPopUp.replaceAll("&#xd;", ""));
        //     setInfoPopUp(true);
        // }

        setOpenPopUp(false);
    };

    const checkIfMedicineIsAlreadyInserted = (medicine) => {
        for (let med of addPrescriptionState.medicines) {
            if (med.barcode === medicine.barcode) return true;
        }
        return false;
    };

    return (
        <div className="flexColumnCenter">
            {!galenic && (
                <div className="m-2">
                    Επιλέξτε Δραστική Ουσία:{" "}
                    <select
                        onChange={handleTreatmentProtocolSelect}
                        value={selectedTreatmentProtocol}
                    >
                        <option value={"noTreatmentProtocol"}>
                            {"--Χωρίς Θεραπευτικό Πρωτόκολλο--"}
                        </option>
                        {addPrescriptionState.insertedTreatmentProtocols.treatmentProtocols
                            .filter(
                                (treatmentProtocol) => treatmentProtocol.treatmentInserted === false
                            )
                            .map((treatmentProtocol) => {
                                return (
                                    <option
                                        key={treatmentProtocol.visitPastId}
                                        value={JSON.stringify(treatmentProtocol)}
                                    >
                                        {treatmentProtocol.atcaDescription}
                                    </option>
                                );
                            })}
                    </select>
                </div>
            )}

            <FormControl>
                <RadioGroup
                    value={radioButton}
                    onChange={handleRadioButtonChange}
                    className="d-flex flex-row"
                >
                    <FormControlLabel
                        value="commercialName"
                        control={<Radio color="primary" />}
                        label="Με Εμπορική Ονομασία"
                        disabled={
                            selectedTreatmentProtocol === "noTreatmentProtocol" ? false : true
                        }
                    />
                    <FormControlLabel
                        value="atcCode"
                        control={<Radio color="primary" />}
                        label="Με ATC"
                        disabled={
                            selectedTreatmentProtocol === "noTreatmentProtocol" ? false : true
                        }
                    />
                    <FormControlLabel
                        value="activeSubstance"
                        control={<Radio color="primary" />}
                        label="Με Δραστική Ουσία"
                        disabled={
                            selectedTreatmentProtocol === "noTreatmentProtocol" ? false : true
                        }
                    />
                </RadioGroup>
            </FormControl>
            <div className="form-inline m-2">
                <form onSubmit={onSubmit}>
                    <input
                        type="text"
                        name={radioButton}
                        onChange={handleStateChange}
                        value={state[radioButton]}
                        className="form-control"
                        // disabled={
                        //     selectedTreatmentProtocol !== "noTreatmentProtocol" ? true : false
                        // }
                        required={
                            selectedTreatmentProtocol === "noTreatmentProtocol" ? true : false
                        }
                    />
                    <button disabled={loading} className="btn buttonPrimary">
                        Αναζήτηση
                    </button>
                </form>
            </div>
            <div className="m-2">
                Οι τιμές είναι σύμφωνα με το ισχύον Δ.Τ.Φ. (* Τύπος φαρμάκου: Π - Πρωτότυπο, Γ -
                Γενόσημο, Ο - Off patent)
            </div>
            {medicines != null && medicines.totalPages === 0 && (
                <Alert severity="info">
                    Δεν βρέθηκαν φάρμακα με τα συγκεκριμένα κριτήρια αναζήτησης
                </Alert>
            )}

            {medicines !== null && medicines.medicines.length > 0 && (
                <div>
                    {error && <Alert severity="error">Κάτι πήγε στραβά!</Alert>}
                    <TableContainer component={Paper} id="table">
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {mainInputColumns.map((column, index) => {
                                        return (
                                            <TableCell key={index}>
                                                {column.name !== "" && (
                                                    <input
                                                        type="text"
                                                        name={column.name}
                                                        onChange={handleStateChange}
                                                        className="form-control"
                                                        disabled={
                                                            selectedTreatmentProtocol !==
                                                            "noTreatmentProtocol"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                                <TableRow>
                                    {mainColumns.map((column, index) => {
                                        return (
                                            <TableCell key={index}>
                                                <b>{column.name}</b>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {medicines.medicines.map((medicine, index) => {
                                    let activeSubstances = [];
                                    if (
                                        Array.isArray(medicine.activeSubstances.activeSubstance) ===
                                        true
                                    )
                                        activeSubstances =
                                            medicine.activeSubstances.activeSubstance;
                                    else if (
                                        medicine.activeSubstances.activeSubstance !== undefined
                                    )
                                        activeSubstances.push(
                                            medicine.activeSubstances.activeSubstance
                                        );
                                    return (
                                        <React.Fragment key={index}>
                                            <TableRow>
                                                <TableCell>
                                                    <button
                                                        className="btn buttonPrimary"
                                                        onClick={() =>
                                                            showMedicineDetails(medicine)
                                                        }
                                                        style={{ margin: "0px", padding: "1px" }}
                                                    >
                                                        <i className="fa fa-info"></i>Πληροφορίες
                                                    </button>
                                                </TableCell>
                                                <TableCell>{medicine.barcode}</TableCell>
                                                <TableCell>{medicine.commercialName}</TableCell>
                                                <TableCell>{medicine.formCode}</TableCell>
                                                <TableCell>{medicine.content}</TableCell>
                                                <TableCell>{medicine.packageForm}</TableCell>
                                                <TableCell>{medicine.atcCode}</TableCell>
                                                <TableCell>{medicine.medicineType}</TableCell>
                                                <TableCell>
                                                    {medicine.ifet && (
                                                        <i className="fa fa-check"></i>
                                                    )}
                                                </TableCell>
                                                <TableCell>{medicine.retailPrice}</TableCell>
                                                <TableCell>{medicine.referencePrice}</TableCell>
                                                <TableCell>
                                                    <button
                                                        className="btn buttonPrimary"
                                                        onClick={() => {
                                                            if (!galenic) {
                                                                addMedicine(medicine);
                                                            } else {
                                                                addGalenicMedicine(medicine);
                                                            }
                                                        }}
                                                        disabled={checkIfMedicineIsAlreadyInserted(
                                                            medicine
                                                        )}
                                                        style={{ margin: "0px", padding: "1px" }}
                                                    >
                                                        <i className="fa fa-plus"></i>Προσθήκη
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={13}>
                                                    <div className="form-inline">
                                                        <div className="gold mr-2">
                                                            Δραστική Ουσία
                                                        </div>
                                                        {activeSubstances.map(
                                                            (activeSubstance, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        {
                                                                            activeSubstance
                                                                                .activeSubstance
                                                                                .description
                                                                        }
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {showPagination && (
                            <Pagination
                                count={medicines.totalPages}
                                page={
                                    selectedTreatmentProtocol === "noTreatmentProtocol"
                                        ? page
                                        : pageTreatmentProtocol
                                }
                                onChange={
                                    selectedTreatmentProtocol === "noTreatmentProtocol"
                                        ? handlePageChange
                                        : handlePageTreatmentProtocolChange
                                }
                            />
                        )}
                    </TableContainer>
                </div>
            )}
            <PopUp
                openPopUp={medicineDetailsPopUp}
                setOpenPopUp={setMedicineDetailsPopUp}
                title={"Λεπτομέρειες Φαρμάκου"}
            >
                <MedicineDetails medicine={medicineDetails} />
            </PopUp>
            <PopUp
                openPopUp={rulesErrorPopUp}
                setOpenPopUp={setRulesErrorPopUp}
                title={"Προσοχή!"}
                showCloseButton={false}
            >
                <div>
                    <ul>
                        {rulesErrors.map((error, index) => {
                            return <li key={index}>{error}</li>;
                        })}
                    </ul>
                    <div align="center">
                        <button
                            className="btn buttonPrimary"
                            onClick={() => setRulesErrorPopUp(false)}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </PopUp>
            <PopUp
                openPopUp={openDHPCPopUp}
                setOpenPopUp={setOpenDHPCPopUp}
                title={"Μήνυμα DHPC"}
                showCloseButton={true}
            >
                <DHPCAction dhpc={dhpcInfo.dhpc} close={dhpcInfo.close} />
            </PopUp>

            <PopUp
                openPopUp={medicineInShortagePopUp}
                setOpenPopUp={setMedicineInShortagePopUp}
                title={"Έλλειψη Φαρμάκων"}
                showCloseButton={true}
            >
                To {medicineInShortageInfo.commercialName} {medicineInShortageInfo.formCode}{" "}
                {medicineInShortageInfo.content} {medicineInShortageInfo.packageForm} είναι σε
                έλλειψη, δεν μπορεί να συνταγογραφηθεί με εμπορική ονομασία.
            </PopUp>
        </div>
    );
}

function Medicine({
    participationPercentages,
    doseUnits,
    medicineFrequencies,
    medicine,
    index,
    addPrescriptionState,
    setAddPrescriptionState,
    tabItem,
    noParticipationCases,
    highRiskGroups,
    setPrescriptionsHistoryPopUp,
    setPrescriptionsHistotyData,
    setMedError,
}) {
    const deleteMedicine = () => {
        let newInsertedMedicines = addPrescriptionState.medicines.filter((medicine, idx) => {
            return index !== idx;
        });
        let insertedTreatmentProtocols = addPrescriptionState.insertedTreatmentProtocols;
        if (addPrescriptionState.medicines[index].protocolDetails !== undefined) {
            insertedTreatmentProtocols.treatmentProtocols =
                insertedTreatmentProtocols.treatmentProtocols.filter((treatmentProtocol) => {
                    if (
                        treatmentProtocol.visitPastId ===
                        medicine.protocolDetails.treatmentProtocolVisitPastId
                    ) {
                        treatmentProtocol.treatmentInserted = false;
                    }
                    return treatmentProtocol;
                });
        }

        if (addPrescriptionState.prescriptionBasedOnCommercialNameId) {
            let unsetOnCommercialNameId = addPrescriptionState.medicines.length === 1;
            let vaccine = addPrescriptionState.desensitizationVaccine;
            setAddPrescriptionState({
                ...addPrescriptionState,
                medicines: newInsertedMedicines,
                insertedTreatmentProtocols: insertedTreatmentProtocols,
                prescriptionBasedOnCommercialNameId: !unsetOnCommercialNameId,
                desensitizationVaccine: !unsetOnCommercialNameId && vaccine,
            });
        } else {
            setAddPrescriptionState({
                ...addPrescriptionState,
                medicines: newInsertedMedicines,
                insertedTreatmentProtocols: insertedTreatmentProtocols,
            });
        }
    };

    const prescriptionHistory = (barcode) => {
        setPrescriptionsHistotyData(null);
        let request = {
            amkaOrEkaa: tabItem.patientData.amka,
            sociId: tabItem.patientData.activeInsurances.activeInsurance[
                tabItem.patientData.selectedActiveInsurance
            ].socialInsurance.id,
            medBarcode: barcode,
        };

        IdikaPrescriptionService.getExecutedOrPendingPrescriptions(request)
            .then(({ data }) => {
                if (data.ApiInfo.extraDetails) {
                    setPrescriptionsHistotyData(data.ApiInfo.extraDetails.extraDetails);
                }
                setMedError(false);
                setPrescriptionsHistoryPopUp(true);
            })
            .catch((error) => {
                setMedError(true);
                setPrescriptionsHistoryPopUp(true);
            });
    };

    let activeSubstances = [];
    if (medicine !== undefined && Array.isArray(medicine.activeSubstances.activeSubstance) === true)
        activeSubstances = medicine.activeSubstances.activeSubstance;
    else if (medicine !== undefined && medicine.activeSubstances.activeSubstance !== undefined)
        activeSubstances.push(medicine.activeSubstances.activeSubstance);

    const onSelect = (selectedList, removedItem) => {
        let medicines = addPrescriptionState.medicines;
        let medicine = addPrescriptionState.medicines[index];
        medicine.request.diagnoses = selectedList;
        medicines[index] = medicine;
        setAddPrescriptionState({ ...addPrescriptionState, medicines: medicines });
    };

    const onRemove = (selectedList, removedItem) => {
        let medicines = addPrescriptionState.medicines;
        let medicine = addPrescriptionState.medicines[index];
        medicine.request.diagnoses = selectedList;
        medicines[index] = medicine;
        setAddPrescriptionState({ ...addPrescriptionState, medicines: medicines });
    };

    const handleMedicineCommentChange = (e) => {
        e.preventDefault();
        let medicines = addPrescriptionState.medicines;
        let medicine = addPrescriptionState.medicines[index];
        medicine.request.comment = e.target.value;
        medicines[index] = medicine;
        setAddPrescriptionState({ ...addPrescriptionState, medicines: medicines });
    };

    const handleMedicineDoseQuantityChange = (e) => {
        e.preventDefault();
        let medicines = addPrescriptionState.medicines;
        let medicine = addPrescriptionState.medicines[index];
        medicine.request.doseQuantity.value = e.target.value;
        medicines[index] = medicine;
        setAddPrescriptionState({ ...addPrescriptionState, medicines: medicines });
    };

    const handleMedicineQuantityChange = (e) => {
        e.preventDefault();
        let medicines = addPrescriptionState.medicines;
        let medicine = addPrescriptionState.medicines[index];
        medicine.request.quantity = parseInt(e.target.value);
        medicines[index] = medicine;
        setAddPrescriptionState({ ...addPrescriptionState, medicines: medicines });
    };

    const handleMedicineInsuredParticipationRateChange = (e) => {
        e.preventDefault();
        let medicines = addPrescriptionState.medicines;
        let medicine = addPrescriptionState.medicines[index];
        medicine.request.insuredParticipationRate = parseInt(e.target.value);
        medicines[index] = medicine;
        setAddPrescriptionState({ ...addPrescriptionState, medicines: medicines });
    };

    const handleMedicineGenericChange = () => {
        let medicines = addPrescriptionState.medicines;
        let medicine = addPrescriptionState.medicines[index];
        medicine.request.genericMedicine = !medicine.request.genericMedicine;
        medicines[index] = medicine;
        setAddPrescriptionState({ ...addPrescriptionState, medicines: medicines });
    };

    const handleMedicineRateQuantityChange = (e) => {
        e.preventDefault();
        let medicines = addPrescriptionState.medicines;
        let medicine = addPrescriptionState.medicines[index];
        medicine.request.rateQuantity.value = parseInt(e.target.value);
        medicines[index] = medicine;
        setAddPrescriptionState({ ...addPrescriptionState, medicines: medicines });
    };

    const [effectiveTimeError, setEffectiveTimeError] = useState("");
    const handleMedicineEffectiveTimeChange = (e) => {
        e.preventDefault();
        let medicines = addPrescriptionState.medicines;
        let medicine = addPrescriptionState.medicines[index];
        let id = parseInt(e.target.value);
        if (id >= effectiveTimeArray.length) {
            setEffectiveTimeError(
                "Αυτή η επιλογή δεν υποστηρίζεται από το MediPlus σας Παρακαλώ επικοινωνήστε μαζί μας"
            );
            return;
        }
        setEffectiveTimeError("");
        medicine.request.effectiveTime = effectiveTimeArray[id];
        medicines[index] = medicine;
        setAddPrescriptionState({ ...addPrescriptionState, medicines: medicines });
    };

    const handleMedicineDiseaseChange = (e) => {
        e.preventDefault();
        let medicines = addPrescriptionState.medicines;
        let medicine = addPrescriptionState.medicines[index];
        medicine.request.diseaseId = parseInt(e.target.value);
        if (medicine.request.diseaseId === -1) {
            medicine.request.insuredParticipationRate = "";
        } else {
            for (let i = 0; i < medicine.diseases.count; i++) {
                if (medicine.diseases.diseases[i].disease.id === medicine.request.diseaseId) {
                    medicine.request.insuredParticipationRate =
                        medicine.diseases.diseases[i].participationPercent.name;
                    break;
                }
            }
        }
        medicines[index] = medicine;
        setAddPrescriptionState({ ...addPrescriptionState, medicines: medicines });
    };

    const handleMedicineHighRiskChange = (e) => {
        e.preventDefault();
        let medicines = addPrescriptionState.medicines;
        let medicine = addPrescriptionState.medicines[index];
        medicine.request.highRiskGroupId = parseInt(e.target.value);
        medicines[index] = medicine;
        setAddPrescriptionState({ ...addPrescriptionState, medicines: medicines });
    };

    const [quantity, setQuantity] = useState([]);
    useEffect(() => {
        if (addPrescriptionState.medicineOnedose === true) {
            setQuantity([...Array(120)]);
        } else if (addPrescriptionState.chronicDisease === true) setQuantity([...Array(10)]);
        else setQuantity([...Array(2)]);
    }, [addPrescriptionState]);

    const [participationPercent, setParticipationPercent] = useState([]);

    useEffect(() => {
        let patientData = tabItem.patientData;
        let activeInsurances = tabItem.patientData.activeInsurances.activeInsurance;
        let activeInsurance = activeInsurances[patientData.selectedActiveInsurance];
        if (activeInsurance.socialInsurance.isPronoia === true) {
            setParticipationPercent([
                { name: 0, id: 1 },
                ...participationPercentages.participationPercentages.slice(4, 32),
            ]);
        } else if (addPrescriptionState.noPartitionCaseId !== "0") {
            let found = noParticipationCases.list.find(
                (noPartCase) => noPartCase.id.toString() === addPrescriptionState.noPartitionCaseId
            );
            if (found !== undefined && found.filterMedicine === false) {
                setParticipationPercent(
                    participationPercentages.participationPercentages.slice(0, 1)
                );
            } else {
                setParticipationPercent(
                    participationPercentages.participationPercentages.slice(0, 3)
                );
            }
        } else if (addPrescriptionState.beneficialEKAS === true) {
            setParticipationPercent(participationPercentages.participationPercentages.slice(0, 1));
        } else if (tabItem.patientData.ekasLoss) {
            setParticipationPercent(participationPercentages.participationPercentages.slice(0, 1));
            setAddPrescriptionState({ ...addPrescriptionState, beneficialEKAS: true });
        } else {
            setParticipationPercent(participationPercentages.participationPercentages.slice(0, 3));
        }
    }, [
        addPrescriptionState,
        participationPercentages.participationPercentages,
        tabItem.patientData,
        noParticipationCases.list,
        setAddPrescriptionState,
    ]);

    return (
        <div className="mb-3">
            <div className="d-flex first">
                <div>
                    <p className="infoP">Δραστική Ουσία</p>
                    <p style={{ margin: "2px 5px" }}>
                        {activeSubstances.map((activeSubstance) => {
                            return activeSubstance.activeSubstance.description;
                        })}
                    </p>
                </div>
                <div>
                    <p className="infoP">Προτεινόμενη Θεραπεία</p>
                    <p
                        style={{ margin: "2px 5px" }}
                    >{`${medicine.formCode} ${medicine.content} ${medicine.packageForm}`}</p>
                </div>
            </div>
            {medicine.isFluantiviral && (
                <div>
                    <p className="infoP">* Όμαδα υψηλού κινδύνου</p>
                    <select
                        style={{ width: "50%" }}
                        className="form-control"
                        onChange={(e) => handleMedicineHighRiskChange(e)}
                        value={addPrescriptionState.medicines[index].request.highRiskGroupId}
                    >
                        <option value={0}>{""}</option>
                        {highRiskGroups.highRiskGroups.map((highRiskGroup) => {
                            return (
                                <option key={highRiskGroup.id} value={highRiskGroup.id}>
                                    {highRiskGroup.description}
                                </option>
                            );
                        })}
                    </select>
                </div>
            )}
            <div style={{ color: "grey" }}>{medicine.groupInfo}</div>
            <div className="d-flex justify-content-between">
                <div id="commentSection">
                    <p className="infoP">Σχόλια</p>
                    <input
                        type="text"
                        className="form-control"
                        value={addPrescriptionState.medicines[index].request.comment}
                        onChange={(e) => handleMedicineCommentChange(e)}
                    />
                    {!addPrescriptionState.prescriptionBasedOnCommercialNameId && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        addPrescriptionState.medicines[index].request
                                            .genericMedicine
                                    }
                                    onChange={() => handleMedicineGenericChange()}
                                    name="monthlyRecipe"
                                    color="primary"
                                    disabled={
                                        !addPrescriptionState.medicines[index].inClusterWithGeneric
                                    }
                                />
                            }
                            label="Προτείνεται η χορήγηση γενόσημου σκευάσματος"
                        />
                    )}
                    <div style={{ width: "300px", color: "grey" }}>
                        {addPrescriptionState.medicines[index].request.genericMedicine === true &&
                            "*Εάν επιθυμείτε μπορείτε να αποεπιλέξετε το πεδίο 'Προτείνεται η χορήγηση γενόσημου σκευάσματος'"}
                    </div>
                </div>
                <div id="quantity">
                    <p className="infoP">* Ποσότητα</p>
                    <select
                        className="form-control"
                        onChange={(e) => handleMedicineQuantityChange(e)}
                        value={addPrescriptionState.medicines[index].request.quantity}
                    >
                        <option value={0}>{""}</option>
                        {quantity.map((q, index) => {
                            return <option key={index + 1}>{index + 1}</option>;
                        })}
                    </select>
                    {addPrescriptionState.medicines[index].diseases &&
                        addPrescriptionState.medicines[index].diseases.count > 0 && (
                            <div>
                                <p className="infoP">* Περιγραφή Ασθένειας</p>
                                <select
                                    className="form-control"
                                    onChange={(e) => handleMedicineDiseaseChange(e)}
                                    value={addPrescriptionState.medicines[index].request.diseaseId}
                                >
                                    <option value={-1}>{""}</option>
                                    {addPrescriptionState.medicines[index].diseases.diseases.map(
                                        (disease, index) => {
                                            return (
                                                <option key={index} value={disease.disease.id}>
                                                    {disease.disease.name}
                                                </option>
                                            );
                                        }
                                    )}
                                </select>
                            </div>
                        )}
                </div>
                <div id="participation">
                    <p className="infoP">* % Συμμετοχή</p>
                    <select
                        className="form-control"
                        onChange={(e) => handleMedicineInsuredParticipationRateChange(e)}
                        value={
                            addPrescriptionState.medicines[index].request.insuredParticipationRate
                        }
                    >
                        <option value={-1}>{""}</option>
                        {participationPercent.map((participationPercentage) => {
                            return (
                                <option
                                    key={participationPercentage.id}
                                    value={participationPercentage.name}
                                >
                                    {participationPercentage.name}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div>
                    <p className="infoP">* Ποσό Δόσης</p>
                    <select
                        className="form-control"
                        onChange={(e) => handleMedicineDoseQuantityChange(e)}
                        value={addPrescriptionState.medicines[index].request.doseQuantity.value}
                    >
                        <option value={"0"}>{""}</option>;
                        {doseUnits.doseUnits.map((doseUnit, index) => {
                            return (
                                <option key={index} value={doseUnit.toString()}>
                                    {doseUnit}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div id="doseUnitSection">
                    <p className="infoP">Μονάδα Δόσης</p>
                    <span>{medicine.doseUnit}</span>
                </div>
                <div id="frequency">
                    <p className="infoP">* Συχνότητα</p>
                    <select
                        className="form-control"
                        onChange={(e) => handleMedicineEffectiveTimeChange(e)}
                        value={addPrescriptionState.medicines[index].request.effectiveTime.id}
                    >
                        <option value={0}>{""}</option>;
                        {medicineFrequencies.medicineFrequencies
                            .filter((medicineFrequency) => medicineFrequency.id !== 0)
                            .map((medicineFrequency) => {
                                return (
                                    <option key={medicineFrequency.id} value={medicineFrequency.id}>
                                        {medicineFrequency.name}
                                    </option>
                                );
                            })}
                    </select>
                    <div style={{ width: "200px" }}>
                        {effectiveTimeError !== "" && effectiveTimeError}
                    </div>
                </div>
                <div>
                    <p className="infoP">* Ημέρες</p>
                    <select
                        className="form-control"
                        onChange={(e) => handleMedicineRateQuantityChange(e)}
                        value={addPrescriptionState.medicines[index].request.rateQuantity.value}
                    >
                        <option value={0}>{""}</option>
                        <option value={1}>1 ημέρα</option>
                        {[
                            ...Array(
                                addPrescriptionState.twoMonthRecipe === false
                                    ? addPrescriptionState.intervalDays !== ""
                                        ? parseInt(addPrescriptionState.intervalDays) - 1
                                        : 29
                                    : 59
                            ),
                        ].map((elementInArray, index) => (
                            <option key={index} value={index + 2}>
                                {index + 2} ημέρες
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <p className="infoP">Διαγνώσεις</p>
                    {/* <select>
                        <option value={0}>{"Όλες"}</option>;
                    </select> */}
                    <div id="multiselect">
                        <Multiselect
                            options={addPrescriptionState.icd10s}
                            selectedValues={addPrescriptionState.medicines[index].request.diagnoses}
                            displayValue="code"
                            onSelect={onSelect}
                            onRemove={onRemove}
                            placeholder={"Διαγνώσεις"}
                            id={medicine.barcode}
                            style={{
                                multiselectContainer: {
                                    color: "black",
                                },
                                option: {
                                    backgroundColor: "white",
                                    color: "#13335a",
                                },
                                chips: {
                                    backgroundColor: "#13335a",
                                    color: "white",
                                    height: "17px",
                                },
                            }}
                            emptyRecordMsg={"Δεν υπάρχουν άλλες διαγνώσεις"}
                        />
                    </div>
                    <div onClick={() => deleteMedicine()}>
                        <a href="#/" style={{ color: "blue" }}>
                            Διαγραφή
                        </a>
                    </div>
                    <div>
                        <a
                            href="#/"
                            style={{ color: "green" }}
                            onClick={() =>
                                prescriptionHistory(addPrescriptionState.medicines[index].barcode)
                            }
                        >
                            Εκτελεσμένες/Εκκρεμείς συνταγές
                        </a>
                    </div>
                </div>
            </div>
            {medicine.defaultPercentage === false && (
                <div style={{ color: "green" }}>
                    Προτεινόμενο Ποσοστό Συμμετοχής Ασφαλισμένου από τον Ε.Ο.Φ.: Μη Διαθέσιμο για
                    {` ${medicine.commercialName} ${medicine.formCode} ${medicine.content} ${medicine.packageForm}`}
                </div>
            )}
        </div>
    );
}

function Medicines({
    addPrescriptionState,
    setAddPrescriptionState,
    setInfoPopUp,
    setInfoMessage,
    checkBoxes,
    tabItem,
    noParticipationCases,
    highRiskGroups,
    setShowBanner,
    setBanner,
    galenicPrescription,
    noInnCases,
}) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [doseUnits, setDoseUnits] = useState(null);
    const [participationPercentages, setParticipationPercentages] = useState(null);
    const [medicineFrequencies, setMedicineFrequencies] = useState(null);
    const [openPopUp, setOpenPopUp] = useState(false);

    const [prescriptionsHistopryPopUp, setPrescriptionsHistoryPopUp] = useState(false);
    const [prescriptionsHistotyData, setPrescriptionsHistotyData] = useState(null);
    const [medError, setMedError] = useState(false);
    const [galenicIndex, setGalenicIndex] = useState(-1);

    const [medicineInShortage, setMedicineInShortage] = useState([]);

    useEffect(() => {
        const requestDoseUnits = IdikaMasterDataService.getDoseUnitNo();
        const requestParticipationPercentages =
            IdikaMasterDataService.getParticipationPercentages();
        const requestMedicineFrequencies = IdikaMasterDataService.getMedicineFrequencies();

        axios
            .all([requestDoseUnits, requestParticipationPercentages, requestMedicineFrequencies])
            .then(
                axios.spread((...responses) => {
                    responses[0].data.doseUnits.sort(function (a, b) {
                        return a - b;
                    });

                    responses[2].data.medicineFrequencies.sort((a, b) => (a.id > b.id ? 1 : -1));

                    setDoseUnits(responses[0].data);
                    setParticipationPercentages(responses[1].data);
                    setMedicineFrequencies(responses[2].data);
                })
            )
            .catch((errors) => {
                setError(errors);
            })
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        IdikaMasterDataService.getAllMedicineInShortage()
            .then(({ data }) => {
                setMedicineInShortage(data.medicinesInShortage);
            })
            .catch((error) => {
                // console.log(error.response);
            });
    }, []);

    const medicineObjectForRequest = {
        prescriptionBarcodeId: 0,
        barcode: "",
        comment: "",
        eofCode: "",
        displayName: "",
        doseQuantity: { value: "0", unit: "" },
        rateQuantity: { value: 0, unit: "d" },
        effectiveTime: { id: 0, value: 0, unit: "" },
        diagnoses: [],
        genericMedicine: false,
        insuredParticipationRate: "",
        quantity: 1,
        treatmentProtocolPatientStepId: 0,
        diseaseId: -1,
        highRiskGroupId: 0,
        isConsumable: false,
        pharmaceuticalForm: "",
        galenicMeasurementUnit: "",
        galenicQuantity: "",
        medicines: [],
        acts: [],
        ramas: [],
    };

    const addGalenicMedicine = (medicine) => {
        // let errors = rules(medicine);
        // if (errors.length > 0) {
        //     setRulesErrors(errors);
        //     setRulesErrorPopUp(true);
        //     return;
        // }
        medicine.request = medicineObjectForRequest;
        medicine.request.barcode = medicine.barcode.toString();
        medicine.request.eofCode = medicine.eofCode.toString();
        medicine.request.displayName = medicine.commercialName;
        // medicine.request.doseQuantity.unit = String(medicine.doseUnit);
        medicine.request.isConsumable = medicine.isConsumable;

        let patientData = tabItem.patientData;
        let activeInsurances = tabItem.patientData.activeInsurances.activeInsurance;
        let activeInsurance = activeInsurances[patientData.selectedActiveInsurance];
        if (
            addPrescriptionState.prescriptionBasedOnCommercialNameId === false &&
            activeInsurance.socialInsurance.Eopyy === true
        ) {
            if (
                medicine.medicineType === "G" ||
                (medicine.medicineTypeAll === "O" &&
                    medicine.typeOException === true &&
                    (medicine.positiveList === true ||
                        medicine.specialImport === true ||
                        medicine.ifet === true) &&
                    medicine.inCirculation === true &&
                    medicine.withdrawn === false)
            )
                medicine.request.genericMedicine = true;
        }
        if (medicine.defaultPercentage === true && medicine.participationPercentage !== "") {
            medicine.request.insuredParticipationRate = medicine.participationPercentage;
        }
        if (medicine.protocolDetails !== undefined) {
            medicine.request.treatmentProtocolPatientStepId =
                medicine.protocolDetails.treatmentProtocolPatientStepId;
            medicine.request.diagnoses = findIcd10sFromTreatmentProtocolIcd10s(
                addPrescriptionState.allTreatmentProtocols.treatmentProtocols,
                medicine.protocolDetails.treatmentProtocolPatientStepId,
                addPrescriptionState.icd10s
            );
        }
        if (addPrescriptionState.medicines.length === 0) {
            medicine.request.prescriptionBarcodeId = 1;
        } else {
            let maxPrescriptionId = 1;
            for (let med of addPrescriptionState.medicines) {
                if (med.request.prescriptionBarcodeId > maxPrescriptionId) {
                    maxPrescriptionId = med.request.prescriptionBarcodeId;
                }
            }
            medicine.request.prescriptionBarcodeId = maxPrescriptionId + 1;
        }

        if (medicine.protocolDetails === undefined) {
            setAddPrescriptionState({
                ...addPrescriptionState,
                medicines: [...addPrescriptionState.medicines, medicine],
            });
        }

        if (medicine.infoPopUp !== "") {
            setInfoMessage(medicine.infoPopUp.replaceAll("&#xd;", ""));
            setInfoPopUp(true);
        }
    };

    const addGalenic = () => {
        IdikaMasterDataService.getGalenicMedicine()
            .then(({ data }) => {
                addGalenicMedicine(data.medicines[0]);
            })
            .catch((error) => {
                // console.log("Fail!");
                // console.log(error.response);
            });
    };

    return (
        <div>
            {loading ? (
                <Spinner align="center" animation="spinner-border" />
            ) : error ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div>
                    <h6>{!galenicPrescription ? "Φάρμακα" : "Γαληνικά Σκευάσματα"}</h6>
                    <button
                        className="btn buttonPrimary"
                        onClick={() => {
                            if (!galenicPrescription) {
                                setOpenPopUp(true);
                            } else {
                                addGalenic();
                            }
                        }}
                        disabled={
                            addPrescriptionState.prescriptionBasedOnCommercialNameId === true &&
                            !addPrescriptionState.desensitizationVaccine &&
                            !galenicPrescription &&
                            addPrescriptionState.prescriptionBasedOnCommercialNameReasonId === "-1"
                                ? true
                                : false
                        }
                    >
                        {!galenicPrescription ? "Προσθήκη Φαρμάκου" : "Προσθήκη Γαληνικού"}
                    </button>
                    <br />
                    <br />
                    {!galenicPrescription &&
                        addPrescriptionState.medicines.map((insertedMedicine, index) => {
                            return (
                                <Medicine
                                    key={index}
                                    participationPercentages={participationPercentages}
                                    doseUnits={doseUnits}
                                    medicineFrequencies={medicineFrequencies}
                                    medicine={insertedMedicine}
                                    index={index}
                                    addPrescriptionState={addPrescriptionState}
                                    setAddPrescriptionState={setAddPrescriptionState}
                                    tabItem={tabItem}
                                    noParticipationCases={noParticipationCases}
                                    highRiskGroups={highRiskGroups}
                                    setPrescriptionsHistoryPopUp={setPrescriptionsHistoryPopUp}
                                    setPrescriptionsHistotyData={setPrescriptionsHistotyData}
                                    setMedError={setMedError}
                                />
                            );
                        })}
                    {galenicPrescription &&
                        addPrescriptionState.medicines.map((insertedMedicine, index) => {
                            return (
                                <GalenicMedicine
                                    addPrescriptionState={addPrescriptionState}
                                    setAddPrescriptionState={setAddPrescriptionState}
                                    medicine={insertedMedicine}
                                    index={index}
                                    key={index}
                                    tabItem={tabItem}
                                    noParticipationCases={noParticipationCases}
                                    participationPercentages={participationPercentages}
                                    doseUnits={doseUnits}
                                    medicineFrequencies={medicineFrequencies}
                                    setOpenPopUp={setOpenPopUp}
                                    setGalenicIndex={setGalenicIndex}
                                />
                            );
                        })}
                </div>
            )}
            <PopUp
                openPopUp={openPopUp}
                setOpenPopUp={setOpenPopUp}
                title={"Αναζήτηση Φαρμάκου"}
                maxWidth="xl"
            >
                <SearchMedicine
                    addPrescriptionState={addPrescriptionState}
                    setAddPrescriptionState={setAddPrescriptionState}
                    setOpenPopUp={setOpenPopUp}
                    setInfoPopUp={setInfoPopUp}
                    setInfoMessage={setInfoMessage}
                    checkBoxes={checkBoxes}
                    tabItem={tabItem}
                    setShowBanner={setShowBanner}
                    setBanner={setBanner}
                    galenic={galenicPrescription}
                    galenicIndex={galenicIndex}
                    medicineInShortage={medicineInShortage}
                    noInnCases={noInnCases}
                />
            </PopUp>
            <PopUp
                openPopUp={prescriptionsHistopryPopUp}
                setOpenPopUp={setPrescriptionsHistoryPopUp}
                title={"Στοιχεία συνταγογραφήσεων ίδιας δραστικής ουσίας/μορφής/περιεκτικότητας"}
                maxWidth="xl"
            >
                <ExecutedOrPendingPrescriptions
                    data={prescriptionsHistotyData}
                    medError={medError}
                />
            </PopUp>
        </div>
    );
}

function ExecutedOrPendingPrescriptions({ data, medError }) {
    return (
        <div className="d-flex flex-column">
            {data === null && !medError && <Alert severity="info">Δεν βρέθηκαν εγγραφές</Alert>}
            {medError && <Alert severity="error">Κάτι πήγε στραβά</Alert>}

            <TableContainer component={Paper} className="mt-3 mb-3">
                <Table>
                    <TableHead>
                        <TableRow>
                            {medicineHistoryTblColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    {data !== null && (
                        <TableBody>
                            {data.map((value, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{value.commercialName}</TableCell>
                                        <TableCell>
                                            {formatMaterialUiDate(new Date(value.issueDate))}
                                        </TableCell>
                                        <TableCell>
                                            {formatMaterialUiDate(new Date(value.startDate))}
                                        </TableCell>
                                        <TableCell>
                                            {formatMaterialUiDate(new Date(value.expiryDate))}
                                        </TableCell>
                                        <TableCell>
                                            {value.executionDate !== ""
                                                ? formatMaterialUiDate(
                                                      new Date(value.executionDate)
                                                  )
                                                : value.executionDate}
                                        </TableCell>
                                        <TableCell>{value.quantityPrescribed}</TableCell>
                                        <TableCell>{value.quantityExecuted}</TableCell>
                                        <TableCell>
                                            <Checkbox
                                                color="primary"
                                                checked={value.limitExceeded}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
        </div>
    );
}

function Treatment({
    addPrescriptionState,
    setAddPrescriptionState,
    tabItem,
    checkBoxes,
    setCheckBoxes,
    setShowBanner,
    setBanner,
    galenicPrescription,
    setGalenicPrescription,
}) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [noParticipationCases, setNoParticipationCases] = useState(null);
    const [noInNCases, setNoInNCases] = useState(null);
    const [highRiskGroups, setHighRiskGroups] = useState(null);

    useEffect(() => {
        if (addPrescriptionState.noPartitionCaseId === "0") return;

        let newMedicines = addPrescriptionState.medicines.map((medicine) => {
            medicine.request.insuredParticipationRate = 0;
            return medicine;
        });
        setAddPrescriptionState({ ...addPrescriptionState, medicines: newMedicines });
        // eslint-disable-next-line
    }, [addPrescriptionState.noPartitionCaseId]);

    useEffect(() => {
        if (tabItem.patientData.ekasLoss === false || tabItem.patientData.ekasLoss === "") return;
        let newMedicines = addPrescriptionState.medicines.map((medicine) => {
            medicine.request.insuredParticipationRate = 0;
            return medicine;
        });
        setAddPrescriptionState({ ...addPrescriptionState, medicines: newMedicines });
    }, [addPrescriptionState, tabItem.patientData.ekasLoss, setAddPrescriptionState]);

    useEffect(() => {
        axios
            .all([
                IdikaMasterDataService.getNoPaticipationCases({ isConsumable: false }),
                IdikaMasterDataService.getNoInNCases(),
                IdikaMasterDataService.getHighRiskGroups(),
            ])
            .then(
                axios.spread((...responses) => {
                    setNoParticipationCases(responses[0].data);
                    setNoInNCases(responses[1].data);
                    setHighRiskGroups(responses[2].data);
                })
            )
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const initialStateForCheckBoxesInner = {
            limitedExecution: false,
            limitedExecutionUnitId: "",

            highCost: false,
            n3816: false,
            executedAtIka: false,
            drug: false,
            notInPharCost: false,

            eopyyPreapproval: false,
            ifet: false,
            desensitizationVaccine: false,
            negativeList: false,
            isAntibiotic: false,

            galenicPrescription: false,
            isConsumable: false,
        };
        let checkBoxesKeys = Object.keys(initialStateForCheckBoxesInner);
        let newCheckBoxes = initialStateForCheckBoxesInner;
        for (let medicine of addPrescriptionState.medicines) {
            for (let key of checkBoxesKeys) {
                if (medicine[key] === true) {
                    newCheckBoxes[key] = true;
                    if (key === "desensitizationVaccine") {
                        setAddPrescriptionState({
                            ...addPrescriptionState,
                            desensitizationVaccine: true,
                            prescriptionBasedOnCommercialNameId: true,
                        });
                    }
                }
            }
        }
        setCheckBoxes(newCheckBoxes);
    }, [addPrescriptionState.medicines, setCheckBoxes]);

    const [infoPopUp, setInfoPopUp] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");

    useEffect(() => {
        setGalenicPrescription(checkBoxes.galenicPrescription);
    }, [checkBoxes.galenicPrescription]);

    return (
        <div className="fullBox">
            <div className="boxTitle">
                <h6>Θεραπείες</h6>
            </div>
            {loading ? (
                <Spinner align="center" animation="spinner-border" />
            ) : error ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div className="boxContent">
                    <div className="checkBoxesFlex">
                        <div>
                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={addPrescriptionState.monthlyRecipe}
                                            onChange={() => {
                                                setAddPrescriptionState({
                                                    ...addPrescriptionState,
                                                    monthlyRecipe:
                                                        !addPrescriptionState.monthlyRecipe,
                                                    chronicDisease:
                                                        !addPrescriptionState.monthlyRecipe === true
                                                            ? true
                                                            : false,
                                                });
                                            }}
                                            name="monthlyRecipe"
                                            color="primary"
                                            disabled={addPrescriptionState.twoMonthRecipe}
                                        />
                                    }
                                    label="Θεραπεία Μηνός"
                                />
                            </div>
                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={addPrescriptionState.twoMonthRecipe}
                                            onChange={() => {
                                                setAddPrescriptionState({
                                                    ...addPrescriptionState,
                                                    twoMonthRecipe:
                                                        !addPrescriptionState.twoMonthRecipe,
                                                    chronicDisease:
                                                        !addPrescriptionState.twoMonthRecipe ===
                                                        true
                                                            ? true
                                                            : false,
                                                });
                                            }}
                                            name="twoMonthRecipe"
                                            color="primary"
                                            disabled={
                                                addPrescriptionState.monthlyRecipe ||
                                                addPrescriptionState.medicines.length > 0
                                                    ? true
                                                    : false
                                            }
                                        />
                                    }
                                    label="Δίμηνη"
                                />
                            </div>
                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={addPrescriptionState.medicineOnedose}
                                            onChange={() => {
                                                setAddPrescriptionState({
                                                    ...addPrescriptionState,
                                                    medicineOnedose:
                                                        !addPrescriptionState.medicineOnedose,
                                                });
                                            }}
                                            name="medicineOnedose"
                                            color="primary"
                                        />
                                    }
                                    label="Μονοδοσικά"
                                />
                            </div>
                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled
                                            name="lostEkas"
                                            color="primary"
                                            checked={tabItem.patientData.ekasLoss === true}
                                        />
                                    }
                                    label="Απώλεια Ε.Κ.Α.Σ."
                                />
                            </div>
                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled
                                            name="limitedExecution"
                                            color="primary"
                                            checked={checkBoxes.limitedExecution}
                                        />
                                    }
                                    label="Περιορισμός στην εκτέλεση"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled
                                            name="highCost"
                                            color="primary"
                                            checked={checkBoxes.highCost}
                                        />
                                    }
                                    label="Υψηλού κόστους"
                                />
                            </div>
                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled
                                            name="n3816"
                                            color="primary"
                                            checked={checkBoxes.n3816}
                                        />
                                    }
                                    label="Σοβαρών Παθήσεων"
                                />
                            </div>
                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled
                                            name="executedAtIka"
                                            color="primary"
                                            checked={checkBoxes.executedAtIka}
                                        />
                                    }
                                    label="Εκτελείται μόνο από Φαρμακείο του Ε.Ο.Π.Y.Υ."
                                />
                            </div>
                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled
                                            name="drug"
                                            color="primary"
                                            checked={checkBoxes.drug}
                                        />
                                    }
                                    label="Ναρκωτικά"
                                />
                            </div>
                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled
                                            name="notInPharCost"
                                            color="primary"
                                            checked={checkBoxes.notInPharCost}
                                        />
                                    }
                                    label="Εκτός φαρμακευτικής δαπάνης Ε.Ο.Π.Υ.Υ."
                                />
                            </div>
                        </div>
                        <div>
                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled
                                            name="eopyyPreapproval"
                                            color="primary"
                                            checked={checkBoxes.eopyyPreapproval}
                                        />
                                    }
                                    label="Απαιτείται προέγκριση από επιτροπή"
                                />
                            </div>
                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled
                                            name="ifet"
                                            color="primary"
                                            checked={checkBoxes.ifet}
                                        />
                                    }
                                    label="Ι.Φ.Ε.Τ."
                                />
                            </div>
                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled
                                            name="desensitizationVaccine"
                                            color="primary"
                                            checked={checkBoxes.desensitizationVaccine}
                                        />
                                    }
                                    label="Εμβόλιο Απευαισθητοποίησης"
                                />
                            </div>
                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled
                                            name="negativeList"
                                            color="primary"
                                            checked={checkBoxes.negativeList}
                                        />
                                    }
                                    label="Αρνητική Λίστα"
                                />
                            </div>
                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled
                                            name="isAntibiotic"
                                            color="primary"
                                            checked={checkBoxes.isAntibiotic}
                                        />
                                    }
                                    label="Αντιβιοτικά"
                                />
                            </div>
                        </div>
                        <div className="">
                            <p className="infoP alignRight">
                                Περιπτώσεις Μηδενικής Συμμετοχής για όλη τη συνταγή
                            </p>
                            <select
                                value={addPrescriptionState.noPartitionCaseId}
                                onChange={(e) =>
                                    setAddPrescriptionState({
                                        ...addPrescriptionState,
                                        noPartitionCaseId: e.target.value,
                                    })
                                }
                            >
                                <option value={"0"}>{""}</option>;
                                {noParticipationCases.list.map((noParticipationCase) => {
                                    return (
                                        <option
                                            key={noParticipationCase.id}
                                            value={noParticipationCase.id}
                                        >
                                            {noParticipationCase.name}
                                        </option>
                                    );
                                })}
                            </select>
                            {addPrescriptionState.noPartitionCaseId !== "0" && (
                                <div style={{ maxWidth: "380px" }}>
                                    <br />
                                    <span>
                                        Η συνταγή θα πρέπει να περιλαμβάνει μόνο φάρμακα για την
                                        ασθένεια με μηδενική συμμετοχή που επιλέξατε.
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex flex-row">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        addPrescriptionState.prescriptionBasedOnCommercialNameId
                                    }
                                    onChange={() => {
                                        setAddPrescriptionState({
                                            ...addPrescriptionState,
                                            prescriptionBasedOnCommercialNameId:
                                                !addPrescriptionState.prescriptionBasedOnCommercialNameId,
                                            prescriptionBasedOnCommercialNameReasonId:
                                                !addPrescriptionState.prescriptionBasedOnCommercialNameId ===
                                                false
                                                    ? "-1"
                                                    : addPrescriptionState.prescriptionBasedOnCommercialNameReasonId,
                                            prescriptionBasedOnCommercialNameComment:
                                                !addPrescriptionState.prescriptionBasedOnCommercialNameId ===
                                                false
                                                    ? ""
                                                    : addPrescriptionState.prescriptionBasedOnCommercialNameComment,
                                        });
                                    }}
                                    name="prescriptionBasedOnCommercialNameId"
                                    color="primary"
                                    disabled={
                                        addPrescriptionState.medicines.length > 0 ? true : false
                                    }
                                />
                            }
                            label="Συνταγογράφηση με εμπορική ονομασία"
                        />
                        {addPrescriptionState.prescriptionBasedOnCommercialNameId &&
                            !checkBoxes.desensitizationVaccine && (
                                <div className="form-inline">
                                    <p className="infoP">Αιτιολογία</p>
                                    <select
                                        value={
                                            addPrescriptionState.prescriptionBasedOnCommercialNameReasonId
                                        }
                                        onChange={(e) => {
                                            setAddPrescriptionState({
                                                ...addPrescriptionState,
                                                prescriptionBasedOnCommercialNameReasonId:
                                                    e.target.value,
                                            });
                                        }}
                                        disabled={
                                            addPrescriptionState.medicines.length > 0 ? true : false
                                        }
                                    >
                                        <option value={"-1"}>{""}</option>
                                        {noInNCases.noInNCases.map((noInNCase) => {
                                            return (
                                                <option key={noInNCase.id} value={noInNCase.id}>
                                                    {noInNCase.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            )}
                        {addPrescriptionState.prescriptionBasedOnCommercialNameId &&
                            !checkBoxes.desensitizationVaccine && (
                                <div className="prercriptionComments">
                                    <p className="infoP alignRight">Σχόλια Συνταγής</p>
                                    <TextareaAutosize
                                        rowsMin="3"
                                        cols="60"
                                        placeholder="(Ελεύθερο Κείμενο)"
                                        value={
                                            addPrescriptionState.prescriptionBasedOnCommercialNameComment
                                        }
                                        onChange={(e) =>
                                            setAddPrescriptionState({
                                                ...addPrescriptionState,
                                                prescriptionBasedOnCommercialNameComment:
                                                    e.target.value,
                                            })
                                        }
                                        disabled={
                                            addPrescriptionState.medicines.length > 0 ? true : false
                                        }
                                    />
                                </div>
                            )}
                    </div>
                    <hr />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={addPrescriptionState.galenicPrescription}
                                onChange={setGalenicPrescription}
                                name="galenicPrescription"
                                color="primary"
                                // disabled={true}
                            />
                        }
                        label="Συνταγογράφηση με γαληνικά σκευάσματα"
                        disabled={addPrescriptionState.medicines.length > 0}
                    />
                    <hr />
                    <br />
                    <Medicines
                        addPrescriptionState={addPrescriptionState}
                        setAddPrescriptionState={setAddPrescriptionState}
                        setInfoPopUp={setInfoPopUp}
                        setInfoMessage={setInfoMessage}
                        checkBoxes={checkBoxes}
                        tabItem={tabItem}
                        noParticipationCases={noParticipationCases}
                        highRiskGroups={highRiskGroups}
                        setShowBanner={setShowBanner}
                        setBanner={setBanner}
                        galenicPrescription={galenicPrescription}
                        noInnCases={noInNCases}
                    />
                </div>
            )}
            <PopUp openPopUp={infoPopUp} setOpenPopUp={setInfoPopUp} title={"Προσοχή"}>
                <div align="center">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: infoMessage,
                        }}
                        style={{ width: "max-content" }}
                    />
                    <br />
                    <button className="btn buttonPrimary" onClick={() => setInfoPopUp(false)}>
                        ΟΚ
                    </button>
                </div>
            </PopUp>
        </div>
    );
}

export default Treatment;
