import React, { useState, useEffect } from "react";
import PopUp from "../../../../../Utilities/PopUp";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
} from "@material-ui/core";
import IdikaMasterDataService from "../../../../../../services/MediPlus/Idika/IdikaMasterDataService";
import { Spinner, formatMaterialUiDate } from "../../../../../Utilities/Utilities";
import { KeyboardDatePicker } from "@material-ui/pickers";
import IdikaPatientService from "../../../../../../services/MediPlus/Idika/IdikaPatientService";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import MediPlusPatientService from "../../../../../../services/MediPlus/MediPlusPatientService";

function EkaaCardForm({ setAddCardPopUp, data = {}, edit = false, setPatient, patient }) {
    const [loading, setLoading] = useState(true);
    const [genders, setGenders] = useState([]);
    const [countryInsuranceData, setCountryInsuranceData] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [carriers, setCarriers] = useState([]);
    const [errorPopUp, setErrorPopUp] = useState(false);
    const [errors, setErrors] = useState([]);
    const [generalError, setGeneralError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [formData, setFormData] = useState(data);

    function toString(o) {
        Object.keys(o).forEach((k) => {
            if (typeof o[k] === "object") {
                return toString(o[k]);
            }

            o[k] = "" + o[k];
        });

        return o;
    }

    useEffect(() => {
        const requestCountrySocialInsuarance = IdikaMasterDataService.getCountrySocialInsuarance();
        const requestGenders = IdikaMasterDataService.getGenders();

        axios
            .all([requestCountrySocialInsuarance, requestGenders])
            .then(
                axios.spread((...responses) => {
                    const responseCountrySocialInsuarance = responses[0].data;
                    const responseGenders = responses[1].data;

                    const unique = [
                        ...Array.from(
                            new Set(
                                responseCountrySocialInsuarance.list.map((item) => item.country.Id)
                            )
                        ),
                    ];
                    let countries = [];
                    unique.forEach((element) => {
                        let country = responseCountrySocialInsuarance.list.find(
                            (item) => item.country.Id === element
                        );
                        countries.push(country.country);
                    });
                    setCountryData(countries);
                    let carriers = [];
                    unique.forEach((element) => {
                        let carrierObject = {};
                        let filteredCarriers = responseCountrySocialInsuarance.list.filter(
                            (item) => item.country.Id === element
                        );
                        carrierObject.countryId = element;
                        carrierObject.carriers = filteredCarriers;
                        carriers.push(carrierObject);
                    });

                    setCountryInsuranceData(carriers);

                    setGenders(responseGenders.genders);
                    setGeneralError(false);
                })
            )
            .catch((errors) => {
                setGeneralError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (formData.country.Id !== 0) {
            let carriers = countryInsuranceData.find(
                (element) => element.countryId.toString() === formData.country.Id.toString()
            );
            if (carriers) {
                setCarriers(carriers.carriers);
            }
        } else {
            setCarriers([]);
        }
    }, [formData.country.Id, countryInsuranceData]);

    const handleChange = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        if (name === "city") {
            value = value.toUpperCase();
        }
        setFormData({ ...formData, [name]: value });
    };

    const onGenderChange = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let gender = genders.find((element) => element.id === value);
        if (gender) {
            setFormData({ ...formData, [name]: gender });
        } else {
            setFormData({ ...formData, [name]: { id: 0, name: "" } });
        }
    };

    const onCountryChange = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let country = countryData.find((element) => element.Id.toString() === value.toString());
        if (country) {
            setFormData({ ...formData, [name]: { Id: country.Id } });
        } else {
            setFormData({ ...formData, [name]: { Id: 0 }, euSocialInsurance: { id: 0 } });
        }
    };

    const onEuSocialInsuranceChange = (e) => {
        let { name, value } = e.target;
        let carrier = carriers.find((element) => element.id.toString() === value.toString());
        if (carrier) {
            setFormData({ ...formData, [name]: { id: carrier.id } });
        } else {
            setFormData({ ...formData, [name]: { id: 0 } });
        }
    };

    const handleCheckboxChange = (e) => {
        let { name, checked } = e.target;
        setFormData({ ...formData, [name]: checked });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setSuccess(false);

        let onSubmitErrors = [];
        if (formData.euSocialInsurance.id.toString() === "0") {
            onSubmitErrors.push("Πρέπει να επιλέξετε Χώρα Έκδοσης.");
        }
        if (formData.country.Id.toString() === "0") {
            onSubmitErrors.push("Πρέπει να επιλέξετε Φορέα.");
        }

        if (formData.sex.id.toString() === "0") {
            onSubmitErrors.push("Πρέπει να επιλέξετε Φύλο.");
        }

        if (Object.keys(onSubmitErrors).length !== 0) {
            setErrors(onSubmitErrors);
            setErrorPopUp(true);
            return;
        }

        let request = {
            ...formData,
            euCardStartDate: formData.euCardStartDate.toString() + " 00:00:00.000+0000",
            euCardExpiration: formData.euCardExpiration.toString() + " 00:00:00.000+0000",
        };

        if (!edit) {
            IdikaPatientService.saveByEkaa(toString(request))
                .then(({ data }) => {
                    setSuccess(true);
                    sessionStorage.setItem("newEkaa", formData.amka);
                })
                .catch((error) => {
                    setSuccess(false);
                    onHandleErrors(error);
                });
        } else {
            MediPlusPatientService.updatePatientWithEkaa(toString(request))
                .then(({ data }) => {
                    setSuccess(true);
                    if (setPatient && patient) {
                        setPatient({ ...patient, ...data.Patient });
                    }
                })
                .catch((error) => {
                    setSuccess(false);
                    onHandleErrors(error);
                });
        }
    };

    const onHandleErrors = (error) => {
        let onSubmitErrors = [];
        if (error.response.data.status === 835) {
            onSubmitErrors.push(
                "Το τηλέφωνο πρέπει να ξεκινάει με 00 και να ακολουθείται από τον κωδικό της χώρας και τον αριθμό."
            );
        } else if (error.response.data.status === 838) {
            onSubmitErrors.push(
                "Ο αριθμός Ε.Κ.Κ.Α. πρέπει να αποτελείται από 20 ψηφία, όταν ο κωδικός χώρας δεν είναι 000 τότε πρέπει να ξεκινά με 80 ακολουθούμενο από τον κωδικό χώρας"
            );
        } else if ([839, 840].includes(error.response.data.status)) {
            onSubmitErrors.push(
                "Η Ημ/νία Λήξης Κάρτας πρέπει να είναι μεγαλύτερη απο την Ημ/νία Έναρξης."
            );
        } else if (error.response.data.status === 824) {
            onSubmitErrors.push("Υπάρχει ήδη ασθενής με αυτό το Ε.Κ.Κ.Α.");
        } else if (error.response.status === 400) {
            onSubmitErrors.push(error.response.data.errors.error);
        } else {
            onSubmitErrors.push("Κάτι πήγε στραβά!");
        }
        setErrors(onSubmitErrors);
        setErrorPopUp(true);
    };

    return (
        <div>
            {loading ? (
                <Spinner align="center" animation="spinner-border" />
            ) : generalError ? (
                <Alert severity="error">Κάτι πήγε στραβά! Δοκιμάστε ξανά αργότερα.</Alert>
            ) : (
                <form className="mb-3" id="add-ekaa-form">
                    <div>
                        <div className="d-flex">
                            <TextField
                                className="formInputUI mr-2 ml-2"
                                label="Όνομα"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                            <TextField
                                className="formInputUI mr-2 ml-2"
                                label="Επώνυμο"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="d-flex" style={{ flexDirection: "column" }}>
                            <TextField
                                className="formInputUI mr-2 ml-2"
                                label="Ε.Κ.Κ.Α."
                                name="amka"
                                value={formData.amka}
                                onChange={handleChange}
                                required
                                disabled={edit}
                            />
                            <div className="mr-2 ml-2" id="ekaa-descriptions">
                                <p>
                                    *** Για τους φορείς ΚΥΠΡΟΥ (CY) που δε συμπεριλαμβάνονται στη
                                    λίστα, θα επιλέγεται ο γενικός Κωδικός Φορέα: 1146507 -
                                    YPOURGEIO YGEIAS.
                                </p>
                                <p>
                                    *** Για τους φορείς ΣΟΥΗΔΙΑΣ (SE) που δε συμπεριλαμβάνονται στη
                                    λίστα, λόγω του ότι δεν υφίστανται πλέον, θα επιλέγεται ο
                                    γενικός Κωδικός Φορέα: 12600 - THE SWEDISH SOCIAL INSURANCE
                                    AGENCY.
                                </p>
                                <p>
                                    *** Οι αριθμοί καρτών ΙΡΛΑΝΔΙΑΣ (IE), λόγω του ότι αποτελούνται
                                    από λιγότερα των είκοσι (20) ψηφίων, θα πρέπει να συμπληρώνονται
                                    ως εξής 80 + 372 + τόσα μηδενικά ώσπου να συμπληρωθούν 20 ψηφία
                                    + Αριθμός Κάρτας.
                                </p>
                            </div>
                        </div>
                        <br />
                        <div className="d-flex ">
                            <FormControl className="formInputUI mr-2 ml-2" required>
                                <InputLabel>Χώρα έκδοσης</InputLabel>
                                <Select
                                    value={formData.country.Id}
                                    onChange={(e) => onCountryChange(e)}
                                    displayEmpty
                                    name="country"
                                    required
                                    disabled={edit}
                                >
                                    <MenuItem value={0}>{""}</MenuItem>
                                    {countryData.map((country, index) => {
                                        return (
                                            <MenuItem key={index} value={country.Id}>
                                                {country.name}{" "}
                                                {country.codeNum !== ""
                                                    ? `( ${country.codeNum} )`
                                                    : ""}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl className="formInputUI mr-2 ml-2" required>
                                <InputLabel>Φορέας</InputLabel>
                                <Select
                                    value={formData.euSocialInsurance.id}
                                    onChange={(e) => onEuSocialInsuranceChange(e)}
                                    displayEmpty
                                    name="euSocialInsurance"
                                    required
                                >
                                    <MenuItem value={0}>{""}</MenuItem>
                                    {carriers.map((carrier, index) => {
                                        return (
                                            <MenuItem key={index} value={carrier.id}>
                                                {carrier.socialInsuranceName}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <br />
                        <div className="d-flex">
                            <>
                                <KeyboardDatePicker
                                    format="dd/MM/yyyy"
                                    className="formInputUI mr-2 ml-2"
                                    disableToolbar
                                    label="Ημ/νία Έναρξης Κάρτας"
                                    required
                                    value={
                                        formData.euCardStartDate === ""
                                            ? null
                                            : formData.euCardStartDate
                                    }
                                    onChange={(date) => {
                                        setFormData({
                                            ...formData,
                                            euCardStartDate: formatMaterialUiDate(date),
                                        });
                                    }}
                                />
                            </>

                            <>
                                <KeyboardDatePicker
                                    format="dd/MM/yyyy"
                                    className="formInputUI mr-2 ml-2"
                                    value={
                                        formData.euCardExpiration === ""
                                            ? null
                                            : formData.euCardExpiration
                                    }
                                    onChange={(date) => {
                                        setFormData({
                                            ...formData,
                                            euCardExpiration: formatMaterialUiDate(date),
                                        });
                                    }}
                                    disableToolbar
                                    label="Ημ/νία Λήξης Κάρτας"
                                    required
                                />
                            </>
                        </div>
                        <div className="d-flex ">
                            <TextField
                                className="formInputUI mr-2 ml-2"
                                label="Α.Μ. Φορέα"
                                name="euRegistryNo"
                                value={formData.euRegistryNo}
                                onChange={handleChange}
                                required
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="euPpa"
                                        color="primary"
                                        checked={formData.euPpa}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                label="Π.Π.Α."
                                labelPlacement="start"
                                style={{ marginBottom: "-10px" }}
                            />
                        </div>
                        <br />
                        <div className="d-flex ">
                            <FormControl className="formInputUI mr-2 ml-2" required>
                                <InputLabel>Φύλο</InputLabel>
                                <Select
                                    value={formData.sex.id}
                                    onChange={(e) => onGenderChange(e)}
                                    displayEmpty
                                    name="sex"
                                    required
                                >
                                    <MenuItem value={0}>{""}</MenuItem>
                                    {genders.map((gender, index) => {
                                        return (
                                            <MenuItem key={index} value={gender.id}>
                                                {gender.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>

                            <>
                                <KeyboardDatePicker
                                    format="dd/MM/yyyy"
                                    className="formInputUI mr-2 ml-2"
                                    value={formData.birthDate === "" ? null : formData.birthDate}
                                    onChange={(date) => {
                                        setFormData({
                                            ...formData,
                                            birthDate: formatMaterialUiDate(date),
                                        });
                                    }}
                                    disableToolbar
                                    label="Ημ/νία Γέννησης"
                                    required
                                />
                            </>
                        </div>
                        <div className="d-flex ">
                            <TextField
                                className="formInputUI mr-2 ml-2"
                                label="Οδός/Αριθμός"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                required
                            />
                            <TextField
                                className="formInputUI mr-2 ml-2"
                                label="Τ.Κ."
                                name="postalCode"
                                value={formData.postalCode}
                                onChange={handleChange}
                                required
                                type="number"
                            />
                        </div>
                        <div className="d-flex ">
                            <TextField
                                className="formInputUI mr-2 ml-2"
                                label="Πόλη"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                                required
                            />
                            <TextField
                                className="formInputUI mr-2 ml-2"
                                label="Νομος"
                                name="county"
                                value={formData.county}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="d-flex">
                            <Tooltip title="Το τηλέφωνο πρέπει να ξεκινάει με 00 και να ακολουθείται από τον κωδικό της χώρας και τον αριθμό.">
                                <TextField
                                    className="formInputUI mr-2 ml-2"
                                    label="Τηλέφωνο"
                                    name="telephone"
                                    value={formData.telephone}
                                    onChange={handleChange}
                                    required
                                />
                            </Tooltip>
                            <TextField
                                className="formInputUI mr-2 ml-2"
                                label="E-mail"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <br />
                    <div align="center">
                        {success && (
                            <Alert severity="success">
                                Επιβεβαίωση αποθήκευσης. Ο αριθμός της κάρτας δεν είναι διαθέσιμος
                                για επεξεργασία μετά την αρχική καταχώρηση.
                            </Alert>
                        )}
                        {success && !edit ? (
                            <button
                                className="btn buttonPrimary"
                                onClick={() => setAddCardPopUp(false)}
                            >
                                Συνέχεια
                            </button>
                        ) : (
                            <button onClick={(e) => onSubmit(e)} className="btn buttonPrimary">
                                Αποθήκευση
                            </button>
                        )}
                    </div>
                </form>
            )}

            <PopUp openPopUp={errorPopUp} setOpenPopUp={setErrorPopUp} title={"Σφάλμα"}>
                <div>
                    <ul>
                        {errors.map((error, index) => {
                            return <li key={index}>{error}</li>;
                        })}
                    </ul>
                    <div align="center">
                        <button className="btn buttonPrimary" onClick={() => setErrorPopUp(false)}>
                            OK
                        </button>
                    </div>
                </div>
            </PopUp>
        </div>
    );
}

export default EkaaCardForm;
