import React, { useState, useEffect, useRef, useContext } from "react";
import "./AddPrescription.css";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import DoctorInfo from "../DoctorVisit/DoctorInfo/DoctorInfo";
import InsuredDetails from "./InsuredDetails/InsuredDetails";
import PrescriptionDetails from "./Prescription/Prescription";
import TreatmentProtocols from "./Protocols/Protocols";
import Diagnose from "./Diagnose/Diagnose";
import Treatment from "./Treatment/Treatment";
import PopUp from "../../../Utilities/PopUp";
import IdikaPrescriptionService from "../../../../services/MediPlus/Idika/IdikaPrescriptionService";
import MediPlusFavoritePrescriptionPackageService from "../../../../services/MediPlus/MediPlusFavoritePrescriptionPackageService";
import { initialStateForCheckBoxes } from "./Treatment/config";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IdikaTreatmentProtocolService from "../../../../services/MediPlus/Idika/IdikaTreatmentProtocolService";
import IdikaMasterDataService from "../../../../services/MediPlus/Idika/IdikaMasterDataService";
import Alert from "@material-ui/lab/Alert";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Checkbox, CircularProgress } from "@material-ui/core";
import { DoctorContext } from "../../Doctor";
import DHPCAction from "../DoctorHome/DHPCInformation/DHPCAction";

const useStyles = makeStyles(() => ({
    root: {
        margin: "0 12px 8px 12px",
        flexGrow: 1,
    },
}));

const AddPrescriptionErrorsPopUpContent = ({ errors }) => {
    return (
        <ul>
            {errors.map((error, index) => {
                return <li key={index}>{error}</li>;
            })}
        </ul>
    );
};

const addPrescriptionInitialState = {
    id: "",
    patientAmka: "",
    visitId: "",

    prescriptionTypeId: "1",
    repeatId: "1",
    interval: "",
    intervalDays: "",
    otherDoctorOpinion: false,
    otherDoctorOpinionAmka: "",
    otherDoctorOpinionDate: "",
    chronicDisease: false,

    insertedTreatmentProtocols: { count: 0, treatmentProtocols: [] },

    icd10s: [],
    diagnosisComment: "",

    medicines: [],
    prescriptionBasedOnCommercialNameId: false,
    prescriptionBasedOnCommercialNameReasonId: "-1",
    prescriptionBasedOnCommercialNameComment: "",
    noPartitionCaseId: "0",
    beneficialEKAS: false,
    monthlyRecipe: false,
    twoMonthRecipe: false,
    medicineOnedose: false,
    galenicPrescription: false,
    desensitizationVaccine: false,

    prescriptionNotes: "",

    // this field is used for holding all treatment protocols
    allTreatmentProtocols: { count: 0, treatmentProtocols: [] },
};

const AddPrescription = ({
    tabItem,
    setTabItem,
    setSelectedTabBttn,
    error,
    setError,
    openError,
    setOpenError,
    setShowBanner,
    setBanner,
    openDiagnosePopUp,
    setOpenDiagnosePopUp,
}) => {
    const doctor = useContext(DoctorContext);

    //error with prescription repeat with protocol
    const protocolErrorInfo = error.map((error, index) => <li key={index}>{error}</li>);

    useEffect(() => {
        if (openError === false) {
            setError([]);
        }
    }, [openError, setError]);

    const classes = useStyles();

    const doctorData = JSON.parse(sessionStorage.getItem("mediPlusDoctorData"));

    const [addPrescriptionState, setAddPrescriptionState] = useState({
        ...tabItem.addPrescription,
        patientAmka: tabItem.patientData.amka.toString(),
        visitId: tabItem.visitDetails.id.toString(),
        galenicPrescription: tabItem.addPrescription.galenicTherapy
            ? tabItem.addPrescription.galenicTherapy
            : false,
    });

    // we use firstUpdate variable to cancel the first execution of useEffect below
    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (addPrescriptionState.monthlyRecipe === true) return;
        let medicines = addPrescriptionState.medicines.map((medicine) => {
            medicine.request.quantity = 0;
            return medicine;
        });
        setAddPrescriptionState({ ...addPrescriptionState, medicines });
        // eslint-disable-next-line
    }, [addPrescriptionState.monthlyRecipe]);

    const [noParticipationCases, setNoParticipationCases] = useState({ count: 0, list: [] });
    useEffect(() => {
        IdikaMasterDataService.getNoPaticipationCases({ isConsumable: false }).then(({ data }) => {
            setNoParticipationCases(data);
        });
    }, []);

    useEffect(() => {
        if (addPrescriptionState.insertedTreatmentProtocols.count === 0) {
            return;
        }
        IdikaTreatmentProtocolService.getPatientTreatmentProtocols(tabItem.patientData.amka).then(
            ({ data }) => {
                setAddPrescriptionState({ ...addPrescriptionState, allTreatmentProtocols: data });
            }
        );
        // eslint-disable-next-line
    }, []);

    const cleanAddPrescriptionState = () => {
        let id = addPrescriptionState.id;
        let patientAmka = addPrescriptionState.patientAmka;
        let visitId = addPrescriptionState.visitId;
        let beneficialEKAS = addPrescriptionState.beneficialEKAS;
        let insertedTreatmentProtocols = addPrescriptionState.insertedTreatmentProtocols;
        for (let protocol of insertedTreatmentProtocols.treatmentProtocols) {
            protocol.treatmentInserted = false;
        }
        let cleanAddPrescriptionState = {
            ...addPrescriptionInitialState,
            id: id,
            patientAmka: patientAmka,
            visitId: visitId,
            beneficialEKAS: beneficialEKAS,
            insertedTreatmentProtocols: insertedTreatmentProtocols,
        };
        setAddPrescriptionState(cleanAddPrescriptionState);
        setTabItem({ ...tabItem, addPrescription: cleanAddPrescriptionState });
    };

    const [addPrescriptionErrors, setAddPrescriptionErrors] = useState([]);
    const [addPrescriptionErrorPopUp, setAddPrescriptionErrorPopUp] = useState(false);
    useEffect(() => {
        if (addPrescriptionErrors.length === 0) return;
        setAddPrescriptionErrorPopUp(true);
    }, [addPrescriptionErrors]);

    const [overridePopUp, setOverridePopUp] = useState(false);
    const [overrides, setOverrides] = useState([]);
    const [addPrescriptionLoading, setAddPrescriptionLoading] = useState(false);
    const [addPrescriptionTempLoading, setAddPrescriptionTempLoading] = useState(false);
    // we use the errors array to apply the rules that we found from IDIKA
    // when a prescription is submitted
    const addPrescription = (isTemp) => {
        let errors = [];
        if (addPrescriptionState.otherDoctorOpinion === true) {
            if (addPrescriptionState.otherDoctorOpinionAmka === "")
                errors.push(
                    "Έχετε επιλέξει να εισάγετε γνωμάτευση. Παρακαλώ συμπληρώστε τα στοιχεία γνωμάτευσης."
                );
            else if (addPrescriptionState.otherDoctorOpinionDate === "")
                errors.push("Παρακαλώ εισάγετε ημερομηνία γνωμάτευσης.");
        }

        if (addPrescriptionState.icd10s.length === 0 || addPrescriptionState.icd10s.length > 10)
            errors.push("Η Συνταγή πρέπει να περιέχει τουλάχιστον 1 Διάγνωση και το πολύ 10.");

        if (addPrescriptionState.medicines.length === 0)
            errors.push("Η Συνταγή θα πρέπει να έχει τουλάχιστον μία θεραπεία.");
        if (addPrescriptionState.prescriptionBasedOnCommercialNameId === true) {
            if (
                addPrescriptionState.prescriptionBasedOnCommercialNameReasonId === "-1" &&
                !addPrescriptionState.desensitizationVaccine
            ) {
                errors.push(
                    "Η επιλογή της αιτιολογίας για την συνταγογράφηση με εμπορική ονομασία είναι υποχρεωτική."
                );
            }
        }

        let fieldsFlag = false;
        let fieldsFlag2 = false;
        let diseaseFlag = false;
        let highRiskFlag = false;
        let consumableWithNoParticipationCase = false;
        for (let medicine of addPrescriptionState.medicines) {
            if (
                !addPrescriptionState.galenicPrescription &&
                fieldsFlag === false &&
                (medicine.request.quantity === 0 ||
                    medicine.request.rateQuantity.value === 0 ||
                    medicine.request.doseQuantity.value === "0" ||
                    medicine.request.effectiveTime.id === 0 ||
                    medicine.request.insuredParticipationRate === "" ||
                    medicine.request.insuredParticipationRate === -1)
            ) {
                errors.push(
                    "Οι συνταγογραφούμενες θεραπείες πρέπει να έχουν ποσότητα, ποσοστό συμμετοχής, ποσότητα δοσολογίας, συχνότητα και ημέρες δοσολογίας."
                );
                fieldsFlag = true;
            }
            if (
                addPrescriptionState.galenicPrescription &&
                fieldsFlag2 === false &&
                (medicine.request.displayName.replace(/ /g, "").length === 0 ||
                    medicine.request.pharmaceuticalForm.replace(/ /g, "").length === 0 ||
                    medicine.request.insuredParticipationRate.length === 0 ||
                    medicine.request.insuredParticipationRate === -1 ||
                    medicine.request.doseQuantity.value === "0" ||
                    medicine.request.doseQuantity.value === 0 ||
                    medicine.request.rateQuantity.value === 0 ||
                    medicine.request.effectiveTime.id === 0 ||
                    medicine.request.galenicQuantity === 0 ||
                    (medicine.request.galenicQuantity > 0 &&
                        medicine.request.galenicMeasurementUnit.replace(/ /g, "").length === 0))
            ) {
                errors.push(
                    "Οι συνταγογραφούμενες θεραπείες πρέπει να έχουν Ονομασία, Φαρμ/κή Μορφή, Ποσοστό Συμμετοχής, Ποσό & Μονάδα Δόσης, Συχνότητα & Ημέρες δοσολογίας. Άν έχετε συμπληρώσει Συνολική Ποσότητα θα πρέπει να έχουν και Μονάδα Μέτρησης"
                );

                fieldsFlag2 = true;
            }
            if (medicine.diseases) {
                if (
                    diseaseFlag === false &&
                    medicine.diseases.count > 0 &&
                    medicine.request.diseaseId === -1
                ) {
                    errors.push("Υπάρχουν θεραπείες που δεν τους έχετε αναθέσει ασθένεια.");
                    diseaseFlag = true;
                }
            }

            if (
                highRiskFlag === false &&
                medicine.isFluantiviral === true &&
                medicine.request.highRiskGroupId === 0
            ) {
                errors.push(
                    "Υπάρχουν θεραπείες που δεν τους έχετε αναθέσει ομάδα υψηλού κινδύνου."
                );
                highRiskFlag = true;
            }

            if (
                consumableWithNoParticipationCase === false &&
                medicine.isConsumable === true &&
                addPrescriptionState.noPartitionCaseId !== "0"
            ) {
                let found = noParticipationCases.list.find(
                    (noParticipationCase) =>
                        noParticipationCase.id.toString() === addPrescriptionState.noPartitionCaseId
                );

                if (found !== undefined && found.consumable === false) {
                    errors.push(
                        "Η συνταγή με αναλώσιμα πρέπει να έχει περιπτώσεις συμμετοχής για αναλώσιμα."
                    );
                    consumableWithNoParticipationCase = true;
                }
            }

            if (
                medicine.defaultDosageRequired !== "" &&
                medicine.defaultDosageRequired === true &&
                ((medicine.defaultDoseDaysNo !== "" &&
                    medicine.defaultDoseDaysNo !== medicine.request.rateQuantity.value) ||
                    (medicine.defaultDoseUnitNo !== "" &&
                        medicine.defaultDoseUnitNo.toString() !==
                            medicine.request.doseQuantity.value) ||
                    (medicine.defaultQuantityPrescribed !== "" &&
                        medicine.defaultQuantityPrescribed !== medicine.request.quantity) ||
                    (medicine.defaultMedicineFrequency !== "" &&
                        medicine.defaultMedicineFrequency.id !== medicine.request.effectiveTime.id))
            ) {
                errors.push(
                    `Το φάρμακο με barcode ${medicine.barcode} πρέπει να έχει ίδιες τιμές με τις προκαθορισμένες του.`
                );
            }
        }

        const unit = sessionStorage.getItem("unit");
        if (unit?.canPrescribeMed === false) {
            errors.push("Η συνταγή δεν μπορεί να συνταγογραφηθεί εάν η μονάδα δεν το επιτρέπει.");
        }
        let patientData = tabItem.patientData;
        let activeInsurances = tabItem.patientData.activeInsurances.activeInsurance;
        let activeInsurance = activeInsurances[patientData.selectedActiveInsurance];
        if (
            activeInsurance.socialInsurance.prescribedOnlyAtHospital === true &&
            unit?.healthCareUnit?.unitType?.hospital === false
        ) {
            errors.push(
                "Η συνταγή πρέπει να συνταγογραφείται μόνο στο νοσοκομείο εάν η κοινωνική ασφάλιση του ασθενούς δεν επιτρέπει τίποτα άλλο."
            );
        }

        if (
            addPrescriptionState.prescriptionBasedOnCommercialNameId === true &&
            addPrescriptionState.prescriptionBasedOnCommercialNameReasonId === "-1" &&
            activeInsurance.socialInsurance.noInnMandatory === false &&
            checkBoxes.negativeList === true
        ) {
            errors.push("Η συνταγή με εμπορική ονομασία πρέπει να έχει και αιτιολογία.");
        }
        if (
            addPrescriptionState.prescriptionBasedOnCommercialNameId === false &&
            activeInsurance.socialInsurance.noInnMandatory === true &&
            checkBoxes.negativeList === false
        ) {
            errors.push(
                "Η συνταγή με εμπορική ονομασία είναι απαραίτητη από την ασφάλεια του ασθενή."
            );
        }

        for (let medicine of addPrescriptionState.medicines) {
            if (medicine.request.diagnoses.length === 0) {
                errors.push(
                    "Η συνταγή μπορεί να έχει μόνο φάρμακα που συνδέονται με τουλάχιστον 1 διάγνωση."
                );
                break;
            }
        }

        if (errors.length > 0) {
            setAddPrescriptionErrors(errors);
        } else {
            let medicineRequests = addPrescriptionState.medicines.map(
                (medicine) => medicine.request
            );
            let request = {
                ...addPrescriptionState,
                medicines: medicineRequests,
            };
            if (!isTemp) {
                setAddPrescriptionLoading(true);
                IdikaPrescriptionService.createNewPrescription(request)
                    .then(({ data }) => {
                        setTabItem({
                            ...tabItem,
                            viewPrescription: data,
                            subTab: 7,
                            addPrescription: addPrescriptionInitialState,
                        });
                        setSelectedTabBttn(7);
                    })
                    .catch((err) => {
                        if (err.response.data.status === 6047)
                            errors.push(
                                "Κάθε ICD10 διάγνωση πρέπει να συσχετιστεί με τουλάχιστον ένα φάρμακο."
                            );
                        else if (err.response.data.status === 976)
                            errors.push(
                                "Δεν είναι δυνατή η καταχώρηση του φαρμάκου σε άτομο αυτής της ηλικίας."
                            );
                        else if (err.response.data.status === 977)
                            errors.push(
                                "Δεν είναι δυνατή η καταχώρηση του φαρμάκου με αυτά τα ICD10."
                            );
                        else if (err.response.data.status === 986)
                            errors.push(
                                "Δεν είναι δυνατή η καταχώρηση του φαρμάκου σε άτομο αυτού του φύλου."
                            );
                        else if (err.response.data.status === 6050)
                            errors.push(
                                "Η συνταγή περιέχει φάρμακο που δεν είναι συμβατό με τα επιτρεπόμενα. Πιθανά προβλήματα: ηλικία, φύλο, βάρος ασθενούς, ειδικότητα ιατρού ή διάγνωση ICD10"
                            );
                        else if (err.response.data.status === 6051)
                            errors.push(
                                // "Τα ICD10 της συνταγής δεν αντιστοιχούν με τα επιτρεπόμενα ICD10."
                                "Υπάρχουν ICD10 τα οποία δεν επιτρέπονται για φάρμακο της συνταγης."
                            );
                        else if (err.response.data.status === 6036) {
                            setOverrides(JSON.parse(err.response.data.errors.error));
                            setOverridePopUp(true);
                            return;
                        } else if (err.response.data.status === 6001) {
                            errors.push(
                                "Υπέρβαση του επιτρεπόμενου ορίου για συνταγογράφηση με εμπορική ονομασία."
                            );
                        } else if (err.response.data.status === 991) {
                            errors.push(
                                "Καθε γαληνικό σκεύασμα πρέπει να περιέχει τουλάχιστον ένα φάρμακο ή μια δραστική ουσία."
                            );
                        } else if (err.response.data.status === 6010) {
                            errors.push(
                                "Δεν μπορεί να προταθεί η χρήση γενόσημου σκευάσματος. Δεν υπάρχει γενόσημη σύνθεση ή εκτός ευρεσιτεχνίας που να αποκλείεται και να θεωρείται γενόσημο στα ισοδύναμα φάρμακα."
                            );
                        } else if (err.response.data.status === 1044) {
                            errors.push(
                                "Συνταγές με επαναλήψεις πρέπει να έχουν καθορισμένη περίοδο επανάληψης"
                            );
                        } else if (err.response.data.status === 1042) {
                            errors.push(
                                "Συνταγές με εμβόλια απευαισθητοποίησης δεν μπορούν να επαναληφθούν."
                            );
                        } else if (err.response.data.status === 6034) {
                            let idikaErrorMessage = err.response.data.errors.error;
                            let barcode = idikaErrorMessage
                                .split("barcode:")[1]
                                .replace(/\s+/g, "");
                            let med = addPrescriptionState.medicines.find(
                                (medicine) => medicine.barcode.toString() === barcode
                            );
                            let msg =
                                med.commercialName +
                                " " +
                                med.formCode +
                                " " +
                                med.content +
                                " " +
                                med.packageForm;

                            errors.push(
                                <div>
                                    <div>Θεραπέια: {msg}</div>
                                    <div>
                                        Μήνυμα: Η συνταγογραφημένη ποσότητα είναι αρκετά μεγαλύτερη
                                        απο την απαιτούμενη.
                                    </div>
                                </div>
                            );
                        } else if (err.response.data.status === 6033) {
                            let idikaErrorMessage = err.response.data.errors.error;
                            let barcode = idikaErrorMessage
                                .split("barcode:")[1]
                                .replace(/\s+/g, "");
                            let med = addPrescriptionState.medicines.find(
                                (medicine) => medicine.barcode.toString() === barcode
                            );
                            let msg =
                                med.commercialName +
                                " " +
                                med.formCode +
                                " " +
                                med.content +
                                " " +
                                med.packageForm;

                            errors.push(
                                <div>
                                    <div>Θεραπέια: {msg}</div>
                                    <div>
                                        Μήνυμα: Η συνταγογραφημένη ποσότητα είναι αρκετά μικρότερη
                                        απο την απαιτούμενη.
                                    </div>
                                </div>
                            );
                        } else if (err.response.data.status === 964) {
                            errors.push(
                                "Δεν επιτρέπεται έκδοση συνταγής που είναι δίμηνη και επαναληπτική ταυτόχρονα."
                            );
                        } else if (err.response.data.status === 457) {
                            errors.push(
                                "Υπάρχουν φάρμακα που είναι σε έλλειψη και δεν μποροούν να συνταγογραφηθούν με εμπορική ονομασία."
                            );
                        } else if (err.response.data.status === 2031) {
                            checkForDHPCMessages();
                        } else if (err.response.status === 400) {
                            errors.push(err.response.data.errors.error);
                        } else errors.push("Κάτι πήγε στραβά!");
                        setAddPrescriptionErrors(errors);
                    })
                    .finally(() => {
                        setAddPrescriptionLoading(false);
                    });
            } else {
                setAddPrescriptionTempLoading(true);
                IdikaPrescriptionService.createTempPrescription(request)
                    .then(({ data }) => {
                        setTabItem({
                            ...tabItem,
                            viewPrescription: data,
                            subTab: 7,
                            addPrescription: addPrescriptionInitialState,
                        });
                        setSelectedTabBttn(7);
                    })
                    .catch((err) => {
                        // console.log(err.response);
                        if (err.response.data.status === 6047)
                            errors.push(
                                "Κάθε ICD10 διάγνωση πρέπει να συσχετιστεί με τουλάχιστον ένα φάρμακο."
                            );
                        else if (err.response.data.status === 976)
                            errors.push(
                                "Δεν είναι δυνατή η καταχώρηση του φαρμάκου σε άτομο αυτής της ηλικίας."
                            );
                        else if (err.response.data.status === 977)
                            errors.push(
                                "Δεν είναι δυνατή η καταχώρηση του φαρμάκου με αυτά τα ICD10."
                            );
                        else if (err.response.data.status === 986)
                            errors.push(
                                "Δεν είναι δυνατή η καταχώρηση του φαρμάκου σε άτομο αυτού του φύλου."
                            );
                        else if (err.response.data.status === 6050)
                            errors.push(
                                "Η συνταγή περιέχει φάρμακο που δεν είναι συμβατό με τα επιτρεπόμενα. Πιθανά προβλήματα: ηλικία, φύλο, βάρος ασθενούς, ειδικότητα ιατρού ή διάγνωση ICD10"
                            );
                        else if (err.response.data.status === 6051)
                            errors.push(
                                // "Τα ICD10 της συνταγής δεν αντιστοιχούν με τα επιτρεπόμενα ICD10."
                                "Υπάρχουν ICD10 τα οποία δεν επιτρέπονται για φάρμακο της συνταγης."
                            );
                        else if (err.response.data.status === 6036) {
                            setOverrides(JSON.parse(err.response.data.errors.error));
                            setOverridePopUp(true);
                            return;
                        } else if (err.response.data.status === 6001) {
                            errors.push(
                                "Υπέρβαση του επιτρεπόμενου ορίου για συνταγογράφηση με εμπορική ονομασία."
                            );
                        } else if (err.response.data.status === 457) {
                            errors.push(
                                "Υπάρχουν φάρμακα που είναι σε έλλειψη και δεν μποροούν να συνταγογραφηθούν με εμπορική ονομασία."
                            );
                        } else if (err.response.status === 400) {
                            errors.push(err.response.data.errors.error);
                        } else errors.push("Κάτι πήγε στραβά!");
                        setAddPrescriptionErrors(errors);
                    })
                    .finally(() => {
                        setAddPrescriptionTempLoading(false);
                    });
            }
        }
    };

    const [openDHPCPopUp, setOpenDHPCPopUp] = useState(false);
    const [dhpcInfo, setDhpcInfo] = useState({});

    const checkSpecificDHPC = (medicineDHPC) => {
        let dhpcToCheck = doctor.dhpc.myMessages.find(
            (element) => element.dhpcMessageId === medicineDHPC.id
        );
        if (dhpcToCheck && dhpcToCheck.acknowledged === "") {
            // Not acknowledged
            setDhpcInfo({
                dhpc: medicineDHPC,
                close: () => {
                    setOpenDHPCPopUp(false);
                },
            });
            setOpenDHPCPopUp(true);
        }
    };

    const checkForDHPCMessages = () => {
        addPrescriptionState.medicines.forEach((medicine) => {
            let medicineDHPCByMedicineId = doctor.dhpc.allMessages.filter(
                (element) => element.medicines.item.id === medicine.id
            );
            let medicineDHPCByMedicineGroupId = doctor.dhpc.allMessages.filter(
                (element) => element.medicines.item.medicineGroupId === medicine.medicineGroupId
            );
            let medicineDHPC = [...medicineDHPCByMedicineId, ...medicineDHPCByMedicineGroupId];
            medicineDHPC = medicineDHPC.filter(
                (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
            );
            medicineDHPC.forEach((element) => {
                checkSpecificDHPC(element);
            });
        });
    };

    const [addFavoritePrescriptionPackagePopUp, setAddFavoritePrescriptionPackagePopUp] =
        useState(false);
    const addPrescriptionToFavoritePrescriptionPackages = () => {
        let errors = [];

        if (addPrescriptionState.insertedTreatmentProtocols.count !== 0)
            errors.push(
                "Δεν γίνεται να γίνει να πραγματοποιηθεί Προσθήκη στα Αγαπημένα Πακέτα Συνταγών εάν υπάρχουν καταχωρημένα Θεραπευτικά Πρωτόκολλα"
            );

        if (errors.length > 0) {
            // console.log(errors);
            setAddPrescriptionErrors(errors);
        } else {
            setAddFavoritePrescriptionPackagePopUp(true);
        }
    };

    const [checkBoxes, setCheckBoxes] = useState(initialStateForCheckBoxes);

    return (
        <div className="flexBoxWrap">
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={5}>
                        <DoctorInfo
                            firstname={doctorData.firstname}
                            lastname={doctorData.lastname}
                            specialty={doctorData.specialty}
                            amka={doctorData.amka}
                            etaaRegNo={doctorData.etaaRegNo}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <InsuredDetails />
                    </Grid>
                </Grid>
            </div>
            <div className="flexBox">
                <PrescriptionDetails
                    addPrescriptionState={addPrescriptionState}
                    setAddPrescriptionState={setAddPrescriptionState}
                />
            </div>
            <div className="flexBox">
                <TreatmentProtocols
                    tabItem={tabItem}
                    addPrescriptionState={addPrescriptionState}
                    setAddPrescriptionState={setAddPrescriptionState}
                />
            </div>
            <div className="flexBox">
                <Diagnose
                    addPrescriptionState={addPrescriptionState}
                    setAddPrescriptionState={setAddPrescriptionState}
                    setShowBanner={setShowBanner}
                    setBanner={setBanner}
                    openDiagnosePopUp={openDiagnosePopUp}
                    setOpenDiagnosePopUp={setOpenDiagnosePopUp}
                />
            </div>
            <div className="flexBox">
                <Treatment
                    addPrescriptionState={addPrescriptionState}
                    setAddPrescriptionState={setAddPrescriptionState}
                    tabItem={tabItem}
                    checkBoxes={checkBoxes}
                    setCheckBoxes={setCheckBoxes}
                    setShowBanner={setShowBanner}
                    setBanner={setBanner}
                    galenicPrescription={addPrescriptionState.galenicPrescription}
                    setGalenicPrescription={() => {
                        setAddPrescriptionState({
                            ...addPrescriptionState,
                            galenicPrescription: !addPrescriptionState.galenicPrescription,
                        });
                    }}
                />
            </div>
            <div className="flexBox">
                <div className="perscriptionEnd">
                    <div className="prercriptionComments">
                        <p className="infoP alignRight">Σχόλια Συνταγής</p>
                        <TextareaAutosize
                            rowsMin="6"
                            cols="100"
                            placeholder="(Ελεύθερο Κείμενο)"
                            value={addPrescriptionState.prescriptionNotes}
                            onChange={(e) =>
                                setAddPrescriptionState({
                                    ...addPrescriptionState,
                                    prescriptionNotes: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="prerscriptionButtons">
                        <button
                            className="btn buttonPrimary"
                            onClick={() => addPrescription(true)}
                            disabled={addPrescriptionTempLoading}
                        >
                            Προσωρινή Καταχώρηση
                        </button>
                        <button
                            className="btn buttonPrimary"
                            onClick={() => addPrescription(false)}
                            disabled={addPrescriptionLoading}
                        >
                            Καταχώρηση Συνταγής
                        </button>
                        <button
                            className="btn buttonPrimary"
                            onClick={() => cleanAddPrescriptionState()}
                        >
                            Καθαρισμός
                        </button>
                        {/* <button className="btn buttonPrimary">
                            Αλλεργίες/Δυσανεξίες/Αντενδείξεις Ασθενή
                        </button> */}
                        <button
                            className="btn buttonPrimary"
                            onClick={() => addPrescriptionToFavoritePrescriptionPackages()}
                        >
                            Προσθήκη στα Αγαπημένα Πακέτα Συνταγών
                        </button>
                    </div>
                </div>
            </div>
            <PopUp
                openPopUp={addPrescriptionErrorPopUp}
                setOpenPopUp={setAddPrescriptionErrorPopUp}
                title={"Σφάλματα Καταχώρηση Συνταγής"}
                maxWidth="sm"
            >
                <AddPrescriptionErrorsPopUpContent errors={addPrescriptionErrors} />
            </PopUp>
            <PopUp
                openPopUp={addFavoritePrescriptionPackagePopUp}
                setOpenPopUp={setAddFavoritePrescriptionPackagePopUp}
                showCloseButton={true}
                title={"Προσθήκη Αγαπημένου Πακέτου Συνταγών"}
            >
                <NewFavoritePrescriptionPackage addPrescriptionState={addPrescriptionState} />
            </PopUp>
            <PopUp
                openPopUp={overridePopUp}
                setOpenPopUp={setOverridePopUp}
                showCloseButton={true}
                title={"Προσοχή!"}
                maxWidth="lg"
            >
                <Override
                    addPrescriptionState={addPrescriptionState}
                    setAddPrescriptionState={setAddPrescriptionState}
                    overrides={overrides}
                    // setOverridePopUp={setOverridePopUp}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                    setSelectedTabBttn={setSelectedTabBttn}
                />
            </PopUp>
            <PopUp
                openPopUp={openError}
                setOpenPopUp={setOpenError}
                showCloseButton={true}
                title={"Σφάλμα επανάληψης συνταγής με πρωτόκολλο!"}
                maxWidth="lg"
            >
                <ul>{protocolErrorInfo}</ul>
            </PopUp>
            <PopUp
                openPopUp={openDHPCPopUp}
                setOpenPopUp={setOpenDHPCPopUp}
                title={"Μήνυμα DHPC"}
                showCloseButton={true}
            >
                <DHPCAction dhpc={dhpcInfo.dhpc} close={dhpcInfo.close} />
            </PopUp>
        </div>
    );
};

function Override({
    addPrescriptionState,
    setAddPrescriptionState,
    overrides,
    // , setOverridePopUp
    tabItem,
    setTabItem,
    setSelectedTabBttn,
}) {
    const mainColumns = ["Αποδοχή", "Περιγραφή υπέρβασης κανόνα", "Εξαίρεση", "Αιτιολόγηση"];

    const [prescriptionOverrides, setPrescriptionOverrides] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        IdikaMasterDataService.getOverridePrescription()
            .then(({ data }) => {
                setPrescriptionOverrides(data.List.contents.item);
            })
            .catch(() => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const [state, setState] = useState(
        overrides.map((override) => {
            let overrideState = { ...override };
            overrideState.accept = false;
            overrideState.reason = 0;
            overrideState.exception = "Επανασυνταγογράφηση δραστικής ουσίας";
            return overrideState;
        })
    );

    const [filledComponentsError, setFilledComponentsError] = useState(false);
    const [overrideLoading, setOverrideLoading] = useState(false);
    const onSubmit = () => {
        // setOverridePopUp(false);
        if (state.find((s) => s.accept === false || s.reason === 0) !== undefined) {
            setFilledComponentsError(true);
            return;
        }
        setFilledComponentsError(false);
        let medicineRequests = addPrescriptionState.medicines.map((medicine) => medicine.request);
        for (let override of state) {
            for (let index in medicineRequests) {
                if (override.lineNumber === medicineRequests[index].prescriptionBarcodeId) {
                    medicineRequests[index].override = override;
                }
            }
        }
        let request = {
            ...addPrescriptionState,
            medicines: medicineRequests,
        };
        setOverrideLoading(true);
        IdikaPrescriptionService.createNewPrescription(request)
            .then(({ data }) => {
                setTabItem({
                    ...tabItem,
                    viewPrescription: data,
                    subTab: 7,
                    addPrescription: addPrescriptionInitialState,
                });
                setSelectedTabBttn(7);
            })
            .catch((err) => {
                // console.log(err.response);
            })
            .finally(() => {
                setOverrideLoading(false);
            });
    };

    const handleStateAcceptChange = (e) => {
        let { name, checked } = e.target;
        let newState = state;
        newState[parseInt(name)].accept = checked;
        setState([...newState]);
    };

    const handleStateReasonChange = (e) => {
        let { name, value } = e.target;
        let newState = state;
        newState[parseInt(name)].reason = parseInt(value);
        setState([...newState]);
    };

    return (
        <div>
            Για να ολοκληρωθεί η καταχώρηση συνταγής πρέπει να γίνει αποδοχή των εξαιρέσεων και
            τεκμηρίωση για κάθε εξαίρεση.
            {isLoading ? (
                <CircularProgress />
            ) : isError ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div>
                    {filledComponentsError && (
                        <Alert className="mt-3" severity="error">
                            Παρακαλώ επιλέξτε όλα τα πλαίσια αποδοχής και αιτιολόγησης εξαιρέσεων,
                            προκειμένου να ολοκληρώσετε την καταχώρηση
                        </Alert>
                    )}
                    <TableContainer component={Paper} className="mt-3 mb-3">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {mainColumns.map((column, index) => {
                                        return (
                                            <TableCell key={index}>
                                                <b>{column}</b>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.map((override, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Checkbox
                                                    name={index.toString()}
                                                    color="primary"
                                                    checked={override.accept}
                                                    onChange={handleStateAcceptChange}
                                                />
                                            </TableCell>
                                            <TableCell>{override.violationDetails}</TableCell>
                                            <TableCell>{override.exception}</TableCell>
                                            <TableCell>
                                                <select
                                                    name={index.toString()}
                                                    value={override.reason}
                                                    onChange={handleStateReasonChange}
                                                >
                                                    <option
                                                        name={index.toString()}
                                                        value={0}
                                                    ></option>
                                                    {prescriptionOverrides.map(
                                                        (override, index) => {
                                                            return (
                                                                <option
                                                                    key={index}
                                                                    name={index.toString()}
                                                                    value={override.presOverrideId}
                                                                >
                                                                    {override.presOverrideReason}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </select>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div align="center">
                        <button
                            className="btn buttonPrimary"
                            onClick={onSubmit}
                            disabled={overrideLoading}
                        >
                            OK
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

function NewFavoritePrescriptionPackage({ addPrescriptionState }) {
    const [successAlert, setSuccessAlert] = useState(false);
    const [error, setError] = useState(null);
    const [existsError, setExistsError] = useState(false);
    const [name, setName] = useState("");

    const onSubmit = (e) => {
        e.preventDefault();
        setSuccessAlert(false);
        setError(null);
        let request = {
            name: name,
            numberOfPrescriptions: addPrescriptionState.medicines.length,
            prescriptions: JSON.stringify(addPrescriptionState),
        };
        // console.log(request);
        MediPlusFavoritePrescriptionPackageService.addFavoritePrescriptionPackage(request)
            .then(({ data }) => {
                if (data === "package exists") {
                    setExistsError(true);
                } else {
                    setSuccessAlert(true);
                    setExistsError(false);
                }
            })
            .catch((error) => {
                // console.log(error.response);
                setError(error);
            });
    };

    return (
        <div>
            {error && (
                <div>
                    <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                    <br />
                </div>
            )}
            {existsError && (
                <div>
                    <Alert severity="info">Υπάρχει ήδη πακέτο με όνομα {name}</Alert>
                    <br />
                </div>
            )}
            {successAlert && (
                <div>
                    <Alert severity="success">Το Πακέτο προστέθηκε με επιτυχία!</Alert>
                    <br />
                </div>
            )}
            <form onSubmit={onSubmit}>
                <span>Όνομα Αγαπημένου Πακέτου Συνταγής *</span>
                <div align="center">
                    <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    {!successAlert && (
                        <button type="submit" className="btn buttonPrimary">
                            Προσθήκη
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
}

export default AddPrescription;
