import React, { useContext, useEffect } from "react";
// import {useState}from react
// import Checkbox from "@material-ui/core/Checkbox";
import { mainColumns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IdikaDoctorService from "../../../../../../services/MediPlus/Idika/IdikaDoctorService.js";
import { useQuery } from "react-query";
import Alert from "@material-ui/lab/Alert";
import { PatientContext } from "../../../Esyntagografisi.js";
import { CircularProgress } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

function FavoriteExams({ examinationGroupId, addOrRemoveReferral, state }) {
    const patient = useContext(PatientContext);
    // const [keyword, setKeyword] = useState("");
    // const [checked, setChecked] = useState(false);
    // const handleChange = (event) => {
    //     setChecked(event.target.checked);
    // };

    const { isLoading, isError, data } = useQuery("favoriteExamsAll", () =>
        IdikaDoctorService.getAllSpecialtyExaminationsFavoritesFilteredByExaminationGroupIdAndSocialInsuranceId(
            { examinationGroupId },
            patient.activeInsurances.activeInsurance[patient.selectedActiveInsurance]
                .socialInsurance.parentSocialInsuranceId
        ).then(({ data }) => {
            return data;
        })
    );

    const checkIfInserted = (id) => {
        const element = state.referrals.find((item) => item.code === id.toString());
        if (element) {
            return true;
        } else {
            return false;
        }
    };

    const decideAction = (exam, index) => {
        if (checkIfInserted(exam.id)) {
            addOrRemoveReferral(exam, "remove", index);
        } else {
            addOrRemoveReferral(exam, "add", index);
        }
    };

    return (
        <div className="form-inline m-2">
            {/* <h1 className="infoP">Λέξεις - Κλειδιά </h1>
            <input type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} /> */}
            {/* <h1 className="infoP">Μόνο Προληπτικές Εξετάσεις </h1>
            <Checkbox checked={checked} onChange={handleChange} color="primary" /> */}
            {/* <button className="btn buttonPrimary">Αναζήτηση</button> */}
            {isLoading ? (
                <CircularProgress />
            ) : isError ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : data.exams.length === 0 ? (
                <Alert severity="info">Δεν υπάρχουν Αγαπημένες Εξετάσεις!</Alert>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {mainColumns.map((column, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <b>{column.name}</b>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.exams.map((exam, index) => {
                                return (
                                    <TableRow key={exam.id}>
                                        <TableCell>{exam.description}</TableCell>
                                        <TableCell>{exam.keyWords}</TableCell>
                                        <TableCell>{exam.price}</TableCell>
                                        {/* <TableCell>
                                            <input type="number" />
                                        </TableCell> */}
                                        {/* <TableCell>_</TableCell> */}
                                        <TableCell>
                                            {!checkIfInserted(exam.id) ? (
                                                <button
                                                    className="btn buttonPrimary"
                                                    onClick={() => {
                                                        decideAction(exam, index);
                                                    }}
                                                >
                                                    Προσθήκη
                                                </button>
                                            ) : (
                                                <DeleteIcon
                                                    onClick={() => {
                                                        decideAction(exam, index);
                                                    }}
                                                    color="secondary"
                                                    style={{ cursor: "pointer" }}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
}

export default FavoriteExams;
