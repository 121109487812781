import React, { useState, useContext, useEffect } from "react";

import DoctorInfo from "../DoctorVisit/DoctorInfo/DoctorInfo";
import InsuredDetails from "../AddPrescription/InsuredDetails/InsuredDetails";
import ReferralDetails from "./ReferralDetails/ReferralDetails";
import Examinations from "./Examinations/Examinations";
import IdikaReferralService from "../../../../services/MediPlus/Idika/IdikaReferralService";
import PopUp from "../../../Utilities/PopUp";
import { PatientContext } from "../Esyntagografisi.js";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IdikaMasterDataService from "../../../../services/MediPlus/Idika/IdikaMasterDataService";
import {
    Checkbox,
    CircularProgress,
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableRow,
    TableBody,
    TableHead,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import RemoveIcon from "@material-ui/icons/Remove";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import IdikaDoctorService from "../../../../services/MediPlus/Idika/IdikaDoctorService";
import { Spinner } from "../../../Utilities/Utilities";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
        flexGrow: 1,
    },
}));

const initialReferralState = {
    barcode: "",
    reason: "",
    comment: "",
    prescriptionTypeId: "1",
    examinationGroupId: "",
    noParticipationCase: '{"needsDecision":false}',
    decisionDate: "",
    decisionNumber: "",
    needsDecision: false,
    referrals: [],
    diagnoses: [],
};

const supColumns = ["Εξέταση", "Μήνυμα"];

const DoctorAddExamination = ({ visitId, tabItem, setTabItem, setSelectedTabBttn }) => {
    const doctorData = JSON.parse(sessionStorage.getItem("mediPlusDoctorData"));
    const patientData = useContext(PatientContext);

    const [state, setState] = useState(tabItem.addReferral);
    const handleOnChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
        setTabItem({ ...tabItem, addReferral: state });
    };
    const [errorPopUp, setErrorPopUp] = useState(false);
    const [errors, setErrors] = useState([]);

    const [overridePopUp, setOverridePopUp] = useState(false);
    const [supplementaryOverridePopUp, setSupplementaryOverridePopUp] = useState(false);
    const [overrides, setOverrides] = useState([]);
    const [requestExtraInfo, setRequestExtraInfo] = useState({});

    const isValidSubmission = () => {
        let onSubmitErrors = [];
        if (state.examinationGroupId === "")
            onSubmitErrors.push("Πρέπει να συμπληρώσετε την Κατηγορία Εξετάσεων");
        if (state.reason === "")
            onSubmitErrors.push("Πρέπει να συμπληρώσετε την Αιτιολογία Παραπεμπτικού");
        let noParticipationCase = JSON.parse(state.noParticipationCase);
        if (noParticipationCase.needsDecision === true) {
            if (state.decisionDate === "")
                onSubmitErrors.push("Πρέπει να συμπληρώσετε την Ημ/νία Απόφασης");
            if (state.decisionNumber === "")
                onSubmitErrors.push("Πρέπει να συμπληρώσετε τον Αριθμό Απόφασης");
        }
        if (state.diagnoses.length === 0)
            onSubmitErrors.push("Το παραπεμπτικό θα πρέπει να έχει τουλάχιστον μία διάγνωση");
        if (state.referrals.length === 0)
            onSubmitErrors.push("Το παραπεμπτικό θα πρέπει να έχει τουλάχιστον μία εξέταση");
        else {
            let diagnosisCopy = [...state.diagnoses];
            for (let referral of state.referrals) {
                diagnosisCopy = diagnosisCopy.filter(
                    (diagnosis) => diagnosis.code !== referral.diagnosisCode
                );
                if (referral.diagnosisCode === "") {
                    onSubmitErrors.push(
                        "Κάθε εξέταση πρέπει να συσχετιστεί με συγκεκριμένη ICD10 διάγνωση"
                    );
                    break;
                } else if (referral.relationType === "") {
                    onSubmitErrors.push("Κάθε εξέταση πρέπει να έχει και έναν Λόγο Παραπομπής");
                    break;
                }
            }
            if (diagnosisCopy.length !== 0)
                onSubmitErrors.push(
                    "Κάθε ICD10 διάγνωση πρέπει να συσχετιστεί με μία τουλάχιστον εξέταση"
                );
        }
        if (Object.keys(onSubmitErrors).length !== 0) return onSubmitErrors;
        else return null;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setErrors([]);

        let onSubmitErrors = isValidSubmission();
        if (onSubmitErrors !== null) {
            setErrors(onSubmitErrors);
            setErrorPopUp(true);
            return;
        }

        let request = {
            ...state,
            doctorId: doctorData.id.toString(),
            visitId: visitId.toString(),
            decisionDate: state.decisionDate.replaceAll("-", ""),
            patientAmka: patientData.amka.toString(),
        };
        setRequestExtraInfo({
            doctorId: doctorData.id.toString(),
            visitId: visitId.toString(),
            decisionDate: state.decisionDate.replaceAll("-", ""),
            patientAmka: patientData.amka.toString(),
        });

        IdikaReferralService.createReferral(request)
            .then(({ data }) => {
                setState(initialReferralState);
                setTabItem({
                    ...tabItem,
                    viewReferral: data,
                    addReferral: initialReferralState,
                    subTab: 8,
                });
                setSelectedTabBttn(8);
            })
            .catch((error) => {
                onSubmitErrors = [];
                if (error.response.data.status === 6130) {
                    onSubmitErrors.push("Λάθος Ημερομηνία Απόφασης");
                }
                if (error.response.data.status === 6036) {
                    setOverrides(JSON.parse(error.response.data.errors.error));
                    setOverridePopUp(true);
                    return;
                } else if (error.response.data.status === 6134) {
                    setSupplementaryOverridePopUp(true);
                    return;
                } else if (error.response.status === 400) {
                    onSubmitErrors.push(error.response.data.errors.error);
                } else {
                    onSubmitErrors.push("Κάτι πήγε στραβά!");
                }
                setErrors(onSubmitErrors);
                setErrorPopUp(true);
            });
    };

    const [tempSuccessPopUp, setTempSuccessPopUp] = useState(false);
    const [tempErrorPopUp, setTempErrorPopUp] = useState(false);
    const [tempErrors, setTempErrors] = useState([]);
    const onTempSubmit = (e) => {
        e.preventDefault();

        let onSubmitErrors = [];
        if (state.examinationGroupId === "")
            onSubmitErrors.push("Πρέπει να συμπληρώσετε την Κατηγορία Εξετάσεων");
        if (state.reason === "")
            onSubmitErrors.push("Πρέπει να συμπληρώσετε την Αιτιολογία Παραπεμπτικού");
        if (Object.keys(onSubmitErrors).length !== 0) {
            setTempErrors(onSubmitErrors);
            setTempErrorPopUp(true);
            return;
        }

        let request = {
            ...state,
            doctorId: doctorData.id.toString(),
            visitId: visitId.toString(),
            decisionDate: state.decisionDate.replaceAll("-", ""),
            patientAmka: patientData.amka.toString(),
        };

        IdikaReferralService.createTempReferral(request)
            .then(({ data }) => {
                setTempSuccessPopUp(true);
                setState(initialReferralState);
                setTabItem({
                    ...tabItem,
                    viewReferral: data,
                    addReferral: initialReferralState,
                    subTab: 8,
                });
                setSelectedTabBttn(8);
            })
            .catch((error) => {
                onSubmitErrors = [];
                if (error.response.data.status === 6130) {
                    onSubmitErrors.push("Λάθος Ημερομηνία Απόφασης");
                }
                if (error.response.data.status === 6036) {
                    setOverrides(JSON.parse(error.response.data.errors.error));
                    setOverridePopUp(true);
                    return;
                } else if (error.response.data.status === 6134) {
                    setSupplementaryOverridePopUp(true);
                    return;
                } else if (error.response.status === 400) {
                    onSubmitErrors.push(error.response.data.errors.error);
                } else {
                    onSubmitErrors.push("Κάτι πήγε στραβά!");
                }
                setErrors(onSubmitErrors);
                setErrorPopUp(true);
            });
    };

    const classes = useStyles();

    return (
        <div className="flexBoxWrap">
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={5}>
                        <DoctorInfo
                            firstname={doctorData.firstname}
                            lastname={doctorData.lastname}
                            specialty={doctorData.specialty}
                            amka={doctorData.amka}
                            etaaRegNo={doctorData.etaaRegNo}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <InsuredDetails />
                    </Grid>
                </Grid>
            </div>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={5}>
                        <ReferralDetails
                            state={state}
                            setState={setState}
                            handleOnChange={handleOnChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Examinations
                            state={state}
                            setState={setState}
                            isValidSubmission={isValidSubmission}
                        />
                    </Grid>
                </Grid>
            </div>
            <br />
            <div className="flexBox">
                <button className="btn buttonPrimary" onClick={onTempSubmit}>
                    Προσωρινή Καταχώρηση
                </button>
                <button className="btn buttonPrimary" onClick={onSubmit}>
                    Καταχώρηση
                </button>
                <button
                    className="btn buttonPrimary"
                    onClick={() => setState(initialReferralState)}
                >
                    Καθαρισμός
                </button>
            </div>
            <PopUp openPopUp={errorPopUp} setOpenPopUp={setErrorPopUp} title={"Σφάλμα"}>
                <div>
                    <ul>
                        {errors.map((error, index) => {
                            return <li key={index}>{error}</li>;
                        })}
                    </ul>
                    <div align="center">
                        <button className="btn buttonPrimary" onClick={() => setErrorPopUp(false)}>
                            OK
                        </button>
                    </div>
                </div>
            </PopUp>
            <PopUp openPopUp={tempErrorPopUp} setOpenPopUp={setTempErrorPopUp} title={"Σφάλμα"}>
                <div>
                    <ul>
                        {tempErrors.map((error, index) => {
                            return <li key={index}>{error}</li>;
                        })}
                    </ul>
                    <div align="center">
                        <button
                            className="btn buttonPrimary"
                            onClick={() => setTempErrorPopUp(false)}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </PopUp>
            <PopUp
                openPopUp={tempSuccessPopUp}
                setOpenPopUp={setTempSuccessPopUp}
                title={"Επιβεβαίωση"}
                maxWidth="sm"
            >
                <div align="center">
                    <p style={{ fontSize: "larger", color: "black" }}>
                        Η προσωρινή καταχώρηση ολοκληρώθηκε με επιτυχία
                    </p>
                    <br />
                    <button
                        className="btn buttonPrimary"
                        onClick={() => setTempSuccessPopUp(false)}
                    >
                        OK
                    </button>
                </div>
            </PopUp>
            <PopUp
                openPopUp={overridePopUp}
                setOpenPopUp={setOverridePopUp}
                showCloseButton={true}
                title={"Προσοχή!"}
                maxWidth="lg"
            >
                <Override
                    addExaminationState={state}
                    setAddExaminationState={setState}
                    overrides={overrides}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                    setSelectedTabBttn={setSelectedTabBttn}
                    extraInfo={requestExtraInfo}
                />
            </PopUp>
            <PopUp
                openPopUp={supplementaryOverridePopUp}
                setOpenPopUp={setSupplementaryOverridePopUp}
                showCloseButton={true}
                title={"Προσοχή!"}
                maxWidth="md"
            >
                <SupplementaryOverrides
                    examinationGroupId={state.examinationGroupId}
                    patientData={patientData}
                    state={state}
                />
            </PopUp>
        </div>
    );
};

function SupplementaryOverrides({ examinationGroupId, patientData, state }) {
    const [supplementaryExams, setSupplementaryExams] = useState([]);
    const [supplementaryExamsToUpdate, setSupplementaryExamsToUpdate] = useState([]);
    const [error, setError] = useState(false);
    useEffect(() => {
        if (supplementaryExams.length > 0) {
            let referrals = state.referrals;
            let finalSupplementaries = supplementaryExams.filter((element) => {
                let ref = referrals.find((referral) => referral.code === String(element.mainId));
                if (ref) {
                    return false;
                } else {
                    return true;
                }
            });
            setSupplementaryExamsToUpdate([...finalSupplementaries]);
        }
    }, [supplementaryExams]);

    useEffect(() => {
        state.referrals.forEach((element) => checkForSupplementaryExams(parseInt(element.code)));
    }, []);

    const checkForSupplementaryExams = (examinationId) => {
        let request = {
            examinationId: examinationId,
            socialInsuranceId:
                patientData.activeInsurances.activeInsurance[patientData.selectedActiveInsurance]
                    .socialInsurance.parentSocialInsuranceId,
        };

        IdikaMasterDataService.getExaminationsConnectedWithSocialIsurances(request)
            .then(({ data }) => {
                if (data.exams.length > 0) {
                    let examination = data.exams[0];
                    if (examination.mainExaminationId !== "") {
                        IdikaDoctorService.getSpecificSpecialtyExaminationsPricesFilteredBySocialInsuranceId(
                            { examinationGroupId },
                            patientData.activeInsurances.activeInsurance[
                                patientData.selectedActiveInsurance
                            ].socialInsurance.parentSocialInsuranceId,
                            examination.mainExaminationId
                        )
                            .then(({ data }) => {
                                if (data.length > 0) {
                                    let examObject = {
                                        exam: examination,
                                        mainExam: data.exams[0],
                                        mainId: examination.mainExaminationId,
                                        id: examination.id,
                                    };
                                    let existsObject = supplementaryExams.find(
                                        (element) =>
                                            element.id === examObject.id &&
                                            element.mainId === examObject.mainId
                                    );
                                    if (!existsObject) {
                                        setSupplementaryExams([...supplementaryExams, examObject]);
                                    }
                                }
                            })
                            .catch((error) => {
                                setError(true);
                            });
                    }
                }
            })
            .catch((error) => {
                setError(true);
            });
    };
    return (
        <div>
            {error && (
                <Alert severity="error">
                    Υπάρχουν εξετάσεις που μπορούν να συνταγογραφηθούν μόνο σε συνδυασμό με άλλες!
                </Alert>
            )}
            {supplementaryExamsToUpdate.length > 0 ? (
                <SupplementaryExams
                    supplementaryExamsToUpdate={supplementaryExamsToUpdate}
                    state={state}
                />
            ) : (
                <Spinner align="center" animation="spinner-border" />
            )}
        </div>
    );
}

function SupplementaryExams({ supplementaryExamsToUpdate, state }) {
    const findExamName = (id) => {
        let exam = state.referrals.find((element) => element.code === String(id));
        if (exam) {
            return exam.displayName;
        } else {
            return "";
        }
    };
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {supColumns.map((column, index) => {
                            return (
                                <TableCell key={index}>
                                    <b>{column}</b>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {supplementaryExamsToUpdate.map((exam, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell>{findExamName(exam.id)}</TableCell>
                                <TableCell>
                                    Η εξέταση μπορεί να συνταγογραφηθεί μόνο με συνδυασμό με{" "}
                                    {exam.mainExam.description}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function Override({
    addExaminationState,
    // setAddExaminationState,
    overrides,
    tabItem,
    setTabItem,
    setSelectedTabBttn,
    extraInfo,
}) {
    const mainColumns = [
        "Αποδοχή",
        "Διάγνωση",
        "Εξέταση",
        "Εξαίρεση",
        "Περιγραφή υπέρβασης κανόνα",
        "Αιτιολόγηση",
    ];

    const mainColumnsExceedsLimit = [
        "Αποδοχή",
        "",
        "Διάγνωση",
        "Εξέταση",
        "Εξαίρεση",
        "Περιγραφή υπέρβασης κανόνα",
        "Αιτιολόγηση",
    ];

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [reason, setReason] = useState(
        overrides.map((override) => {
            let reasonState = {};
            reasonState.reason = "";
            reasonState.accept = false;
            return reasonState;
        })
    );

    const [state, setState] = useState(
        overrides.map((override) => {
            let overrideState = { ...override };
            overrideState.accept = false;
            overrideState.reason = "";
            overrideState.code = "";
            overrideState.exception = "";
            overrideState.examination = "";
            return overrideState;
        })
    );

    useEffect(() => {
        setIsLoading(true);
        IdikaMasterDataService.getOverrideTypes()
            .then(({ data }) => {
                const examinationOverrides = data.Page.contents.item;
                var tempState = state;
                for (var i = 0; i < tempState.length; i++) {
                    //exception
                    tempState[i].exception = examinationOverrides.find(
                        (element) => element.id === tempState[i].overrideTypeId
                    ).description;
                    if (tempState[i].examinationId) {
                        tempState[i].examination = addExaminationState.referrals.find(
                            (element) => element.code === tempState[i].examinationId.toString()
                        ).displayName;
                    }
                }

                setState(tempState);

                setIsLoading(false);
            })
            .catch((error) => {
                setIsError(true);
                setIsLoading(false);
            });
    }, [addExaminationState, overrides, state]);

    useEffect(() => {
        var icd10s = [];
        for (var i = 0; i < overrides.length; i++) {
            if (overrides[i].icd10Id !== undefined) {
                icd10s.push(overrides[i].icd10Id);
            }
        }

        IdikaMasterDataService.getIcd10Code({ icd10s: icd10s })
            .then(({ data }) => {
                var tempState = state;
                for (var i = 0; i < tempState.length; i++) {
                    if (tempState[i].icd10Id) {
                        tempState[i].code = data.icds.find(
                            (element) => element.id === tempState[i].icd10Id
                        ).info.code;
                    }
                }

                setState(tempState);
            })
            .catch((error) => {
                setIsError(true);
                // setIsLoading(false);
            });
    }, []);

    const [filledComponentsError, setFilledComponentsError] = useState(false);
    const [overrideLoading, setOverrideLoading] = useState(false);
    const onSubmit = () => {
        let tempState = state;
        for (let i = 0; i < tempState.length; i++) {
            tempState[i].reason = reason[i].reason;
            tempState[i].accept = reason[i].accept;
        }

        if (tempState.find((s) => s.accept === false || s.reason.length === 0) !== undefined) {
            setFilledComponentsError(true);
            return;
        }
        setFilledComponentsError(false);
        let referralRequests = addExaminationState.referrals;
        // for overrides connected with icd10s etc.
        let referralWideOverrides = [];
        for (let override of tempState) {
            if (!override.lineNumber) {
                referralWideOverrides.push(override);
            } else {
                for (let index in referralRequests) {
                    if (String(override.lineNumber) === referralRequests[index].code) {
                        referralRequests[index].override = override;
                    }
                }
            }
        }
        let request = {
            ...addExaminationState,
            ...extraInfo,
            referrals: referralRequests,
            referralOverrides: referralWideOverrides,
        };

        setOverrideLoading(true);
        IdikaReferralService.createReferral(request)
            .then(({ data }) => {
                //setState(initialReferralState);
                setTabItem({
                    ...tabItem,
                    viewReferral: data,
                    addReferral: initialReferralState,
                    subTab: 8,
                });
                setSelectedTabBttn(8);
            })
            .catch((error) => {
                //console.log(error.response);
            })
            .finally(() => {
                setOverrideLoading(false);
            });
    };

    const handleStateAcceptChange = (e) => {
        let { name, checked } = e.target;
        let newState = reason;
        newState[parseInt(name)].accept = checked;
        setReason([...newState]);
        // let newState = state;
        // newState[parseInt(name)].accept = checked;
        // setState([...newState]);
    };

    const handleStateReasonChange = (e) => {
        let { name, value } = e.target;
        let newState = reason;
        newState[parseInt(name)].reason = value;
        setReason([...newState]);
    };

    const [notCheckable, setNotCheckable] = useState(false);
    const [exceedsLimit, setExceedsLimit] = useState(false);

    useEffect(() => {
        if (state.length > 0) {
            let obj = state.find((element) => element.notCheckable === true);
            if (obj) {
                setNotCheckable(true);
            }

            let excLimit = state.find((element) => element.exceedsLimit === true);
            if (excLimit) {
                setExceedsLimit(true);
            }
        }
    }, [state]);

    let columns = exceedsLimit ? mainColumnsExceedsLimit : mainColumns;

    return (
        <div>
            {!notCheckable ? (
                <>
                    Για να ολοκληρωθεί η καταχώρηση παραπεμπτικού πρέπει να γίνει αποδοχή των
                    εξαιρέσεων και τεκμηρίωση για κάθε εξαίρεση.
                </>
            ) : (
                <>
                    Η καταχώρηση παραπεμπτικού δεν μπορεί να ολοκληρωθεί καθώς υπάρχουν υπερβάσεις
                    που δεν επιδέχονται αποδοχής.
                </>
            )}

            {isLoading ? (
                <CircularProgress />
            ) : isError ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div>
                    {filledComponentsError && (
                        <Alert className="mt-3" severity="error">
                            Παρακαλώ επιλέξτε όλα τα πλαίσια αποδοχής και αιτιολόγησης εξαιρέσεων,
                            προκειμένου να ολοκληρώσετε την καταχώρηση
                        </Alert>
                    )}
                    <TableContainer component={Paper} className="mt-3 mb-3">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column, index) => {
                                        return (
                                            <TableCell key={index}>
                                                <b>{column}</b>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.map((override, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {override.notCheckable ? (
                                                    <RemoveIcon />
                                                ) : (
                                                    <Checkbox
                                                        name={index.toString()}
                                                        color="primary"
                                                        checked={reason[index].accept}
                                                        onChange={handleStateAcceptChange}
                                                    />
                                                )}
                                            </TableCell>
                                            {exceedsLimit && (
                                                <TableCell>
                                                    {override.exceedsLimit && <InfoOutlinedIcon />}
                                                </TableCell>
                                            )}
                                            <TableCell>{override.code}</TableCell>
                                            <TableCell>{override.examination}</TableCell>
                                            <TableCell>{override.exception}</TableCell>
                                            <TableCell>{override.violationDetails}</TableCell>
                                            <TableCell>
                                                <textarea
                                                    name={index.toString()}
                                                    rows="5"
                                                    cols="40"
                                                    value={reason[index].reason}
                                                    onChange={handleStateReasonChange}
                                                    required
                                                    disabled={override.notCheckable}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {exceedsLimit && (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <InfoOutlinedIcon />
                            Το παραπεμπτικό θα πρέπει να εκτελεστεί σε δημόσια δομή
                        </div>
                    )}

                    <div align="center">
                        <button
                            className="btn buttonPrimary"
                            onClick={onSubmit}
                            disabled={overrideLoading || notCheckable}
                        >
                            OK
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DoctorAddExamination;
