import axios from "axios";
import { baseUrl, getOptions, postOptions, putOptions } from "../../config.js";

class IdikaDHPCService {
    constructor(baseUrl) {
        this.basePath = "/idika/dhpc";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getMessages() {
        return axios(getOptions(this.baseUrl, "/mine/get"));
    }

    getNotPresented() {
        return axios(getOptions(this.baseUrl, "/mine/get-not-presented"));
    }

    getDHPCMessagePDF(id) {
        return axios(getOptions(this.baseUrl, `/${id}/print`));
    }

    updateReadStatus(data) {
        return axios(postOptions(this.baseUrl, "/update-read-status", data));
    }

    updatePresentStatus(parameters) {
        return axios(postOptions(this.baseUrl, "/update-present-status", parameters));
    }
}

export default new IdikaDHPCService(baseUrl);
