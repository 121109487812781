import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { mainColumns, formatDate } from "../DoctorHome/DHPCInformation/config";
import { useContext } from "react";
import { DoctorContext } from "../../Doctor";
import PopUp from "../../../Utilities/PopUp";
import DHPCAction from "../DoctorHome/DHPCInformation/DHPCAction";
import { useEffect } from "react";
import IdikaDHPCService from "../../../../services/MediPlus/Idika/IdikaDHPCService";

function NewDHPCMessages({ messages }) {
    // const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(null);
    const [openActionsPopUp, setOpenActionsPopUp] = useState(false);
    const doctor = useContext(DoctorContext);
    let myDhpc = doctor.dhpc.myMessages;

    const findMyDhpcAcknowledgedData = (id) => {
        let message = myDhpc.find((element) => element.dhpcMessageId === id);
        if (message) {
            let date = new Date(message.acknowledged);
            if (isNaN(date.getTime())) {
                return null;
            } else {
                return formatDate(message.acknowledged, true);
            }
        } else {
            return null;
        }
    };

    const onOpenMessage = (message) => {
        setSelected(message);
        // setSelectedData(findMyDhpcData(message.id));
        setOpenActionsPopUp(true);
    };

    const close = () => {
        setOpenActionsPopUp(false);
    };

    useEffect(() => {
        let idsPresented = [];
        messages.forEach((element) => idsPresented.push(element.id));

        let request = {
            idsPresented: idsPresented,
        };
        IdikaDHPCService.updatePresentStatus(request)
            .then((response) => {
                // console.log("presented!");
            })
            .catch((error) => {
                // console.log("not presented");
            });
    }, []);

    return (
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {mainColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column.name}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {messages.map((message, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{message.shortTitle}</TableCell>
                                    <TableCell>
                                        <a
                                            target="_Blank"
                                            href={message.messageUrl}
                                            rel="noopener noreferrer"
                                        >
                                            {message.messageUrl}
                                        </a>
                                    </TableCell>
                                    <TableCell>{formatDate(message.dateFm, false)}</TableCell>
                                    <TableCell>{findMyDhpcAcknowledgedData(message.id)}</TableCell>
                                    <TableCell>
                                        <button
                                            className="btn buttonPrimary small-padding"
                                            onClick={() => onOpenMessage(message)}
                                        >
                                            Ανάγνωση
                                        </button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <PopUp
                openPopUp={openActionsPopUp}
                setOpenPopUp={setOpenActionsPopUp}
                title={"Μήνυμα DHPC"}
                maxWidth="md"
            >
                <DHPCAction dhpc={selected} close={close} />
            </PopUp>
        </div>
    );
}

export default NewDHPCMessages;
