import { Button, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { formatDate } from "../../../../Utilities/Utilities";
import { Alert } from "@material-ui/lab";
import IdikaDHPCService from "../../../../../services/MediPlus/Idika/IdikaDHPCService";
import { DoctorContext } from "../../../Doctor";
import { useContext } from "react";

function DHPCAction({ dhpc, close }) {
    const doctor = useContext(DoctorContext);
    let myDhpc = doctor.dhpc.myMessages.find((element) => element.dhpcMessageId === dhpc.id);
    const acknowledged = myDhpc.acknowledged && myDhpc.acknowledged !== "" ? true : false;
    const [checkboxValue, setCheckboxValue] = useState(acknowledged ? acknowledged : false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const onCheckboxChangeHandler = () => {
        setCheckboxValue((prevState) => !prevState);
        setError(false);
        setSuccess(false);
    };

    const onSubmit = () => {
        if (checkboxValue) {
            let request = {
                dhpcMessageId: dhpc.id.toString(),
                presented: "false",
                acknowledged: "true",
            };

            IdikaDHPCService.updateReadStatus(request)
                .then((response) => {
                    setSuccess(true);
                    setError(false);
                    doctor.setFetchDHPC(true);
                })
                .catch((error) => {
                    setSuccess(false);
                    setError(error.response.data.errors.error);
                });
        } else {
            // checkbox not checked -> so message
            setError(true);
            setSuccess(false);
        }
    };

    return (
        <div className="section" style={{ height: "300px" }}>
            <Grid container spacing={1}>
                {(error || success) && (
                    <Grid container item spacing={2}>
                        <Grid item xs={12}>
                            {error ? (
                                !checkboxValue ? (
                                    <Alert severity="error">
                                        Δεν έχετε επιβεβαιώσει ότι διαβάσατε το μήνυμα DHPC
                                    </Alert>
                                ) : (
                                    <Alert severity="error">{error}</Alert>
                                )
                            ) : null}
                            {success && (
                                <Alert severity="success">Επιτυχής αποδοχή ενημέρωσης</Alert>
                            )}
                        </Grid>
                    </Grid>
                )}

                <Grid container item spacing={2}>
                    <Grid item xs={4}>
                        <p className="infoP alignRight">Σύντομη Περιγραφή</p>
                    </Grid>
                    <Grid item xs={8}>
                        <p>{dhpc.shortTitle}</p>
                    </Grid>
                </Grid>
                <Grid container item spacing={2}>
                    <Grid item xs={4}>
                        <p className="infoP alignRight">Αρχείο Μηνύματος</p>
                    </Grid>
                    <Grid item xs={8}>
                        <a target="_Blank" href={dhpc.messageUrl} rel="noopener noreferrer">
                            {dhpc.messageUrl}
                        </a>
                    </Grid>
                </Grid>
                <Grid container item spacing={2}>
                    <Grid item xs={4}>
                        <p className="infoP alignRight">Ημ/νία Ισχύος</p>
                    </Grid>
                    <Grid item xs={8}>
                        <p>{formatDate(dhpc.dateFm, false)}</p>
                    </Grid>
                </Grid>
                <Grid container item spacing={2}>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={8}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkboxValue}
                                    name="acceptDHPC"
                                    onChange={onCheckboxChangeHandler}
                                    color="primary"
                                    disabled={acknowledged || success}
                                />
                            }
                            label={
                                "Διάβασα την άμεση ενημέρωση ώστε να την λάβω υπόψη μου κατα την συνταγογράφηση του σκευάσματος"
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
            <div className="flexBox">
                {!acknowledged && (
                    <button
                        className="btn buttonPrimary small-padding"
                        onClick={onSubmit}
                        disabled={success}
                    >
                        Αποδοχή
                    </button>
                )}

                <button className="btn buttonPrimary small-padding" onClick={() => close(true)}>
                    {acknowledged ? "Κλείσιμο" : "Ακύρωση"}
                </button>
            </div>
        </div>
    );
}

export default DHPCAction;
