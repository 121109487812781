import axios from "axios";
import { baseUrl, postOptions } from "../config.js";

class MediPlusPatientService {
    constructor(baseUrl) {
        this.basePath = "/patients";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    updatePatient(data) {
        return axios(postOptions(this.baseUrl, "", data));
    }

    updatePatientWithEkaa(data) {
        return axios(postOptions(this.baseUrl, "/update-with-ekaa", data));
    }
}

export default new MediPlusPatientService(baseUrl);
