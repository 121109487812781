const mainColumns = [
    { name: "Σύντομη Περιγραφή" },
    { name: "Αρχείο Μηνύματος" },
    { name: "Ημ/νία Ισχύος" },
    { name: "Αναγνώστηκε" },
    { name: "Ενέργειες" },
];

const formatDate = (dateText, time) => {
    if (dateText === "" || dateText === null) {
        return "";
    }
    let date = new Date(dateText);
    let year = date.getFullYear().toString();
    let month = (parseInt(date.getMonth()) + 1).toString();
    let day = date.getDate().toString();
    let hours = date.getHours().toString();
    let minutes = date.getMinutes().toString();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hours.length < 2) hours = "0" + hours;
    if (minutes.length < 2) minutes = "0" + minutes;
    let finalDate = day + "/" + month + "/" + year;
    if (time) {
        finalDate +=  " " + hours + ":" + minutes;
    }
    return finalDate;
};

export { mainColumns, formatDate };
