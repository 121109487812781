import axios from "axios";
import { baseUrl, getOptions } from "../../config.js";

class IdikaMasterDataService {
    constructor(baseUrl) {
        this.basePath = "/idika/masterdata";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getExaminationGroups() {
        return axios(getOptions(this.baseUrl, "/examination/groups"));
    }

    getExamsNoPaticipationCases() {
        return axios(getOptions(this.baseUrl, "/exams/noparticipationcases"));
    }

    getNoPaticipationCases(params) {
        return axios(getOptions(this.baseUrl, "/noparticipationcases", params));
    }

    getIcpc2s(parameters) {
        return axios(getOptions(this.baseUrl, "/icpc2s", parameters));
    }

    getMedicines(parameters) {
        return axios(getOptions(this.baseUrl, "/medicines", parameters));
    }

    getIcd10s(parameters) {
        return axios(getOptions(this.baseUrl, "/icd10s", parameters));
    }

    getIcd10Code(parameters) {
        return axios(getOptions(this.baseUrl, "/icd10Code", parameters));
    }

    getBiometrics() {
        return axios(getOptions(this.baseUrl, "/biometrics"));
    }

    getPrescriptionTypes() {
        return axios(getOptions(this.baseUrl, "/prescription/types"));
    }

    getPrescriptionRepeats() {
        return axios(getOptions(this.baseUrl, "/prescription/repeats"));
    }

    getPrescriptionIntervals() {
        return axios(getOptions(this.baseUrl, "/prescription/intervals"));
    }

    getDoseUnitNo() {
        return axios(getOptions(this.baseUrl, "/doseunitno"));
    }

    getParticipationPercentages() {
        return axios(getOptions(this.baseUrl, "/participation/percentages"));
    }

    getMedicineFrequencies() {
        return axios(getOptions(this.baseUrl, "/medicine/frequencies"));
    }

    getNoInNCases() {
        return axios(getOptions(this.baseUrl, "/noinncases"));
    }

    getRelationTypes() {
        return axios(getOptions(this.baseUrl, "/relation/types"));
    }

    getHighRiskGroups() {
        return axios(getOptions(this.baseUrl, "/highriskgroups"));
    }

    getOverridePrescription() {
        return axios(getOptions(this.baseUrl, "/override/prescription"));
    }

    getOverrideTypes() {
        return axios(getOptions(this.baseUrl, "/override/types"));
    }

    getGalenicMedicine() {
        return axios(getOptions(this.baseUrl, "/galenic-medicine"));
    }

    getMedicine(barcode) {
        return axios(getOptions(this.baseUrl, `/medicine/${barcode}`));
    }

    getConsumablesCatergories() {
        return axios(getOptions(this.baseUrl, "consumables/categories"));
    }

    getDHPCMessages(parameters) {
        return axios(getOptions(this.baseUrl, "/dhpc/messages", parameters));
    }

    getAllDHPCMessages() {
        return axios(getOptions(this.baseUrl, "/dhpc/messages/all"));
    }

    getAllMedicineInShortage() {
        return axios(getOptions(this.baseUrl, "/medicineshortage"));
    }

    getExaminationsConnectedWithSocialIsurances(parameters) {
        return axios(getOptions(this.baseUrl, "/examinations/socialinsurances", parameters));
    }

    getCountrySocialInsuarance() {
        return axios(getOptions(this.baseUrl, "/country-soc-insurance"));
    }

    getGenders() {
        return axios(getOptions(this.baseUrl, "/genders"));
    }

}

export default new IdikaMasterDataService(baseUrl);
