import React, { useState } from "react";
import IdikaVisitService from "../../../../../../services/MediPlus/Idika/IdikaVisitService";
import Alert from "@material-ui/lab/Alert";
import {
    visitDetailsInitialState,
    initialAddReferralState,
    addPrescriptionInitialState,
} from "../../../../Doctor";

function CancelVisit({
    visitDetails,
    setVisitDetails,
    setCancelPopUp,
    setVisitReasonValue,
    tabItem,
    setTabItem,
}) {
    const [error, setError] = useState(null);
    const [disabledButton, setDisablledButton] = useState(false);

    const onCancel = () => {
        setDisablledButton(true);
        IdikaVisitService.cancelVisit(visitDetails.id)
            .then(({ data }) => {
                const vD = {
                    ...visitDetails,
                    ...visitDetailsInitialState,
                    prescriptionsToBeRegistered: [],
                    referralsToBeRegistered: [],
                };
                setTabItem({
                    ...tabItem,
                    visitDetails: vD,
                    viewPrescription: {},
                    viewReferral: {},
                    addReferral: initialAddReferralState,
                    addPrescription: addPrescriptionInitialState,
                });
                setVisitDetails(vD);
                setVisitReasonValue("");
                setCancelPopUp(false);
            })
            .catch((error) => {
                if (error.response.data.status === 610) {
                    // 'cannot' cancel visit with temp referral
                    setError(error.response);
                } else if (error.response.data.status !== 1050) {
                    //1050 --> visit already cancelled
                    setError(error.response);
                    setDisablledButton(false);
                }
            });
    };

    return (
        <div>
            {error && (
                <div>
                    <Alert severity="error">
                        {error.data.status === 610 ? (
                            <div>
                                <p>
                                    Δεν είναι δυνατή η ακύρωση επίσκεψης που περιλαμβάνει
                                    παραπεμπτικά σε κατάσταση προσωρινής αποθήκευσης.
                                </p>

                                <p>
                                    Για να συνεχίσετε προχωρήστε σε <strong>Καταχώρηση</strong> και
                                    στη συνέχεια <strong>Ακύρωση</strong> των παραπεμπτικών.
                                </p>
                            </div>
                        ) : error.data.status === 1051 ? (
                            "Η επίσκεψη δεν μπορεί να ακυρωθεί διότι υπάρχουν μη ακυρώσιμες συνταγές."
                        ) : error.data.status === 1052 ? (
                            "Η επίσκεψη δεν μπορεί να ακυρωθεί διότι υπάρχουν μη ακυρώσιμα παραπεμπτικά."
                        ) : error.status === 400 ? (
                            error.data.errors.error
                        ) : (
                            "Κάτι πήγε στραβά!"
                        )}
                    </Alert>
                    <br />
                </div>
            )}
            <div>Είστε σίγουρος ότι θέλετε να ακυρώσετε την επίσκεψη;</div>
            <br />
            <div align="center">
                <button
                    className="btn buttonPrimary"
                    onClick={() => onCancel()}
                    disabled={disabledButton}
                >
                    Ναι
                </button>
            </div>
        </div>
    );
}

export default CancelVisit;
