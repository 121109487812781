import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import GeneralData from "./GeneralData/GeneralData.js";
import { mainTabs } from "./config.js";
import Icpc2 from "./Icpc2/Icpc2.js";
import IdikaVisitService from "../../../../../services/MediPlus/Idika/IdikaVisitService.js";
import PopUp from "../../../../Utilities/PopUp.js";
import CloseVisit from "./CloseVisit/CloseVisit.js";
import CancelVisit from "./CancelVisit/CancelVisit.js";
import Alert from "@material-ui/lab/Alert";
import ActiveVisit from "./ActiveVisit/ActiveVisit.js";
import { getCurrentDateGreekFormat } from "../../../../Utilities/Utilities.js";

function reOpenVisitButton(visitDetails) {
    let currentDate = getCurrentDateGreekFormat();
    let date = visitDetails.startDate.split(" ")[0].split("/");
    let day = date[0].toString();
    let month = date[1].toString();
    let year = date[2].toString();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    if (`${day}/${month}/${year}` === currentDate) return true;
    return false;
}

function VisitDetails({
    doctorData,
    patientData,
    visitDetails,
    setVisitDetails,
    tabIndex,
    tabItem,
    setTabItem,
    popPrescriptionOrReferralForRegistration,
}) {
    const [selectedTabVisitDetails, setSelectedTabVisitDetails] = useState(0);
    function handleTabs(e, val) {
        setSelectedTabVisitDetails(val);
    }

    const [visitReasonValue, setVisitReasonValue] = useState("");

    const showContent = () => {
        if (selectedTabVisitDetails === 0)
            return (
                <GeneralData
                    visitDetails={visitDetails}
                    setVisitDetails={setVisitDetails}
                    tabIndex={tabIndex}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                    visitReasonValue={visitReasonValue}
                    setVisitReasonValue={setVisitReasonValue}
                />
            );
        else if (selectedTabVisitDetails === 1) return <Icpc2 />;
        else if (selectedTabVisitDetails === 2)
            return (
                <div className="tabContent">
                    <h1>3</h1>
                </div>
            );
        else return null;
    };
    const [startRegistrationPopUp, setStartRegistrationPopUp] = useState(false);
    const [addVisitError, setAddVisitError] = useState(null);
    const [activeVisitId, setActiveVisitId] = useState(-1);

    const [errorPopUp, setErrorPopUp] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const addVisit = () => {
        if (visitDetails.reason === "") {
            setAddVisitError("Πρέπει να βάλετε Λόγο Επίσκεψης!");
            return;
        }

        setAddVisitError(null);

        let request = {
            reason: visitDetails.reason,
            comment: visitDetails.comment,
            patientDetails: {
                amkaOrEkaa: patientData.amka.toString(),
                insuranceId: JSON.stringify(
                    patientData.activeInsurances.activeInsurance[
                        patientData.selectedActiveInsurance
                    ].socialInsurance.id
                ),
            },
            doctorDetails: {
                id: JSON.stringify(doctorData.id),
                unitId: sessionStorage.getItem("unitId"),
            },
            icpc2s: visitDetails.icpc2s,
        };

        IdikaVisitService.createNewVisit(request)
            .then(({ data }) => {

                let prescriptionsToBeRegistered = tabItem.visitDetails.prescriptionsToBeRegistered;
                let referralsToBeRegistered = tabItem.visitDetails.referralsToBeRegistered;
                let visitDtls = {
                    ...data.visitDetails,
                    prescriptionsToBeRegistered,
                    referralsToBeRegistered,
                };
                setVisitDetails(visitDtls);
                if (prescriptionsToBeRegistered.length > 0 || referralsToBeRegistered.length > 0) {
                    setStartRegistrationPopUp(true);
                }
            })
            .catch((error) => {
                // 425 status: There is already an active visit
                if (error.response.data.status === 425) {
                    let errorData = error.response.data;
                    setActiveVisitId(
                        errorData.errors.error.split("Exists active visit with id:")[1]
                    );
                    setActiveVisitPopUp(true);
                } else if (error.response.data.status === 6023) {
                    setErrorMessage(
                        `Δεν έχετε δικαίωμα συνταγογράφησης σε δικαιούχους της κατηγορίας "${
                            patientData.activeInsurances.activeInsurance[
                                patientData.selectedActiveInsurance
                            ].socialInsurance.shortName
                        }"`
                    );
                    setErrorPopUp(true);
                } else {
                    setErrorMessage("Παρουσιάστηκε κάποιο πρόβλημα.");
                    setErrorPopUp(true);
                    // console.log(error.response);
                }
            });
    };

    const [cancelPopUp, setCancelPopUp] = useState(false);
    const [closePopUp, setClosePopUp] = useState(false);
    const [activeVisitPopUp, setActiveVisitPopUp] = useState(false);

    const [printError, setPrintError] = useState(null);
    const onPrint = () => {
        setPrintError(null);
        IdikaVisitService.printVisit(visitDetails.id)
            .then(({ data }) => {
                let file = new Blob([data], { type: "application/pdf" });
                let fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
            .catch((error) => {
                setPrintError(error);
            });
    };

    const [saveError, setSaveError] = useState(null);
    const [saveSuccessAlert, setSaveSuccessAlert] = useState(null);
    const onSave = () => {
        setSaveSuccessAlert(null);
        let request = {
            reason: visitDetails.reason,
            comment: visitDetails.comment,
            patientDetails: {
                amkaOrEkaa: patientData.amka.toString(),
                insuranceId: JSON.stringify(
                    patientData.activeInsurances.activeInsurance[
                        patientData.selectedActiveInsurance
                    ].socialInsurance.id
                ),
            },
            doctorDetails: {
                id: JSON.stringify(doctorData.id),
                unitId: sessionStorage.getItem("unitId"),
            },
            icpc2s: visitDetails.icpc2s,
        };
        IdikaVisitService.updateVisit(visitDetails.id, request)
            .then(() => {
                setSaveSuccessAlert("Η Αποθήκευση έγινε με Επιτυχία!");
                setTimeout(function () {
                    setSaveSuccessAlert(null);
                }, 2000);
            })
            .catch((error) => {
                // console.log(error.response);
                setSaveError(error);
            });
    };

    const onOpen = () => {
        IdikaVisitService.reopenVisit(visitDetails.id)
            .then(({ data }) => {
                setVisitDetails(data.visitDetails);
            })
            .catch((error) => {
                // 425 status: There is already an active visit
                if (error.response.data.status === 425) {
                    let errorData = error.response.data;
                    setActiveVisitId(
                        errorData.errors.error.split("Exists active visit with id:")[1]
                    );
                    setActiveVisitPopUp(true);
                } else {
                    // console.log(error.response);
                }
            });
    };

    const onPrintAllVisitReferrals = () => {
        IdikaVisitService.printAllVisitReferrals(visitDetails.id)
            .then(({ data }) => {
                let file = new Blob([data], { type: "application/pdf" });
                let fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
            .catch((error) => {
                setPrintError(error);
            });
    };

    const prescribedReferral = () => {
        let found = tabItem.visitDetails.referrals.find(
            (referral) => referral.status === "ΣΥΝΤΑΓΟΓΡΑΦΗΜΕΝΟ"
        );
        if (found === undefined) return false;
        return true;
    };

    return (
        <div className="section">
            <div className="boxTitle">
                <h6>
                    Στοιχεία Επίσκεψης (Κωδικός Επίσκεψης:{" "}
                    {visitDetails.id === -1 ? "Νέα Επίσκεψη" : visitDetails.id})
                </h6>
            </div>
            <div className="boxContent">
                <Tabs
                    value={selectedTabVisitDetails}
                    onChange={handleTabs}
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    className="tabsBttns"
                >
                    {mainTabs.map((tab, index) => {
                        return <Tab key={index} {...tab} />;
                    })}
                </Tabs>
                {addVisitError && selectedTabVisitDetails === 0 && (
                    <Alert severity="error">{addVisitError}</Alert>
                )}
                {printError && selectedTabVisitDetails === 0 && (
                    <Alert severity="error">Κάτι πήγε στραβά με την Εκτύπωση!</Alert>
                )}
                {saveError && selectedTabVisitDetails === 0 && (
                    <Alert severity="error">Η Αποθήκευση δεν πραγματοποιήθηκε!</Alert>
                )}
                {saveSuccessAlert && selectedTabVisitDetails === 0 && (
                    <Alert severity="success">{saveSuccessAlert}</Alert>
                )}
                {showContent()}
                <br />
                <hr />
                <br />
                {visitDetails.id === -1 ? (
                    <button className="btn buttonPrimary" onClick={() => addVisit()}>
                        Καταχώρηση
                    </button>
                ) : visitDetails.status === "Συνταγογραφημένη" ? (
                    <div>
                        <button className="btn buttonPrimary" onClick={() => onSave()}>
                            Αποθήκευση
                        </button>
                        <button className="btn buttonPrimary" onClick={() => setClosePopUp(true)}>
                            Κλείσιμο Επίσκεψης
                        </button>
                        <button className="btn buttonPrimary" onClick={() => setCancelPopUp(true)}>
                            Ακύρωση Επίσκεψης
                        </button>
                        {(tabItem.visitDetails.referralsToBeRegistered.length > 0 ||
                            tabItem.visitDetails.prescriptionsToBeRegistered.length > 0) && (
                            <button
                                className="btn buttonPrimary"
                                onClick={() => popPrescriptionOrReferralForRegistration()}
                            >
                                Καταχώρηση Συνταγών/Παραπεμπτικών
                            </button>
                        )}
                        {prescribedReferral() === true && (
                            <button
                                className="btn buttonPrimary"
                                onClick={() => onPrintAllVisitReferrals()}
                            >
                                Εκτύπωση Παραπεμπτικών
                            </button>
                        )}
                    </div>
                ) : visitDetails.status === "Ολοκληρωμένη" ? (
                    <div>
                        <button className="btn buttonPrimary" onClick={() => setCancelPopUp(true)}>
                            Ακύρωση Επίσκεψης
                        </button>
                        {reOpenVisitButton(visitDetails) && (
                            <button className="btn buttonPrimary" onClick={() => onOpen()}>
                                Άνοιγμα Επίσκεψης
                            </button>
                        )}
                        <button className="btn buttonPrimary" onClick={() => onPrint()}>
                            Εκτύπωση Επίσκεψης
                        </button>
                        {visitDetails.referrals.length > 0 && (
                            <button
                                className="btn buttonPrimary"
                                onClick={() => onPrintAllVisitReferrals()}
                            >
                                Εκτύπωση Παραπεμπτικών
                            </button>
                        )}
                    </div>
                ) : visitDetails.status === "Ακυρωμένη" ? (
                    <div></div>
                ) : null}
            </div>
            <PopUp
                openPopUp={closePopUp}
                setOpenPopUp={setClosePopUp}
                title={"Κλείσιμο Επίσκεψης"}
                maxWidth="lg"
            >
                <CloseVisit
                    visitDetails={visitDetails}
                    setVisitDetails={setVisitDetails}
                    setClosePopUp={setClosePopUp}
                />
            </PopUp>
            <PopUp
                openPopUp={cancelPopUp}
                setOpenPopUp={setCancelPopUp}
                title={"Ακύρωση Επίσκεψης"}
                maxWidth="sm"
            >
                <CancelVisit
                    visitDetails={visitDetails}
                    setVisitDetails={setVisitDetails}
                    setCancelPopUp={setCancelPopUp}
                    setVisitReasonValue={setVisitReasonValue}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                />
            </PopUp>
            <PopUp
                openPopUp={activeVisitPopUp}
                setOpenPopUp={setActiveVisitPopUp}
                title={"Ανοιχτή Επίσκεψη"}
                maxWidth="sm"
            >
                <ActiveVisit
                    activeVisitId={parseInt(activeVisitId)}
                    setActiveVisitPopUp={setActiveVisitPopUp}
                />
            </PopUp>
            <PopUp
                openPopUp={startRegistrationPopUp}
                setOpenPopUp={setStartRegistrationPopUp}
                title={"Καταχώρηση Συνταγών/Παραπεμπτικών"}
                maxWidth="sm"
            >
                <div>
                    Ξεκινάει η διαδικασία Καταχώρησης Συνταγών/Παραπεμπτικών! Επιθυμείτε να
                    συνεχίσετε;
                    <div className="mt-3" align="center">
                        <button
                            className="btn buttonPrimary"
                            onClick={() => {
                                popPrescriptionOrReferralForRegistration();
                                setStartRegistrationPopUp(false);
                            }}
                        >
                            Συνέχεια
                        </button>
                    </div>
                </div>
            </PopUp>

            <PopUp
                openPopUp={errorPopUp}
                setOpenPopUp={setErrorPopUp}
                title={"Προειδοποίηση"}
                maxWidth="sm"
            >
                <p>{errorMessage}</p>
            </PopUp>
        </div>
    );
}

export default VisitDetails;
