import React, { useState, useEffect } from "react";
import { formatDateAndTime, Spinner } from "../../../../../../../Utilities/Utilities";
import Alert from "@material-ui/lab/Alert";
import { mainColumns, medicineColumns, diagnosisColumns, galenicColumns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PopUp from "../../../../../../../Utilities/PopUp";
import Pagination from "@material-ui/lab/Pagination";
import IdikaPrescriptionService from "../../../../../../../../services/MediPlus/Idika/IdikaPrescriptionService";
import { GalenicIngredients } from "../../../../../ViewPrescription/Medicines/Medicines";
// import { formatMaterialUiDate } from "../../../../../../../Utilities/Utilities";

function PrescriptionHistory({ patient, setPrescriptionHistory, tabItem, setTabItem }) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        // api changes and needs from or fromExec
        // max months before up to 6 months
        //if we add barcode:"" it works , safer this way?
        // let fromDate = new Date();
        // fromDate.setMonth(fromDate.getMonth() - 6);
        // fromDate.setDate(fromDate.getDate() + 1);
        let request = {
            page: page - 1,
            size: 5,
            // from: formatMaterialUiDate(fromDate),
            barcode:""
        };
        if (patient.amka.length === 20) request.ekaa = patient.amka;
        else request.amka = patient.amka;
        IdikaPrescriptionService.getPrescriptions(request)
            .then(({ data }) => {
                setData(data);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [patient.amka, page]);

    const [more, setMore] = useState(false);
    const [selectedPrescription, setSelectedPrescription] = useState(null);

    const handleMore = (prescriptionBarcode) => {
        IdikaPrescriptionService.getPrescription(prescriptionBarcode)
            .then(({ data }) => {
                setSelectedPrescription(data);
                setMore(true);
            })
            .catch((error) => {
                setError(error);
            });
    };

    const [selectedPrescriptionBarcode, setSelectedPrescriptionBarcode] = useState(0);
    const [openCancelledPrescriptionRepeat, setOpenCancelledPrescriptionRepeat] = useState(false);
    const [prescriptionRepeat, setPrescriptionRepeat] = useState(null);

    const checkPrescriptionForRepeat = (prescription) => {
        setPrescriptionRepeat(prescription);
        setOpenCancelledPrescriptionRepeat(true);
    };

    const repeatPrescription = (prescription) => {
        setSelectedPrescriptionBarcode(prescription.barcode);
        IdikaPrescriptionService.getPrescription(prescription.barcode)
            .then(({ data }) => {
                data.objectForRepeat.id = "";
                data.objectForRepeat.name = data.id;
                data.objectForRepeat.createdDate = data.issueDate;
                data.objectForRepeat.type = "history";
                data.galenicTherapy = data.galenicTherapy;
                setTabItem({
                    ...tabItem,
                    visitDetails: {
                        ...tabItem.visitDetails,
                        prescriptionsToBeRegistered: [
                            ...tabItem.visitDetails.prescriptionsToBeRegistered,
                            data.objectForRepeat,
                        ],
                    },
                });
                setSelectedPrescriptionBarcode(0);
            })
            .catch((error) => {
                setError(error);
            });
    };

    const removePrescription = (prescription) => {
        let newPrescriptions = tabItem.visitDetails.prescriptionsToBeRegistered.filter(
            (alreadyAddedPrescription) =>
                alreadyAddedPrescription.type === undefined ||
                (alreadyAddedPrescription.type === "history" &&
                    alreadyAddedPrescription.name !== prescription.barcode)
        );
        setTabItem({
            ...tabItem,
            visitDetails: {
                ...tabItem.visitDetails,
                prescriptionsToBeRegistered: newPrescriptions,
            },
        });
    };

    const checkIfThePackageIsAlreadyAdded = (prescription) => {
        let found = tabItem.visitDetails.prescriptionsToBeRegistered.find(
            (alreadyAddedPrescription) =>
                alreadyAddedPrescription.type !== undefined &&
                alreadyAddedPrescription.type === "history" &&
                alreadyAddedPrescription.name === prescription.barcode
        );
        if (found === undefined) return false;
        return true;
    };

    const [openIngredientsPopUp, setOpenIngredientsPopUp] = useState(false);
    const [ingredients, setIngredients] = useState(null);

    const onIngredientsHandle = (medicine) => {
        setIngredients(medicine.request);
        setOpenIngredientsPopUp(true);
    };

    return (
        <div>
            {loading ? (
                <Spinner align="center" animation="spinner-border" />
            ) : error ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : data.count === 0 ? (
                <Alert severity="info">
                    {`Δεν υπάρχουν παλαιότερες Συνταγές για τον ασθενή '${patient.lastName}
            ${patient.firstName}'`}
                </Alert>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {mainColumns.map((column, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <b>{column.name}</b>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.prescriptions.map((prescription, index) => {
                                return (
                                    <TableRow key={prescription.barcode}>
                                        <TableCell>{prescription.barcode}</TableCell>
                                        <TableCell>
                                            {formatDateAndTime(prescription.issueDate)}
                                        </TableCell>
                                        <TableCell>{prescription.status.status}</TableCell>
                                        <TableCell>
                                            {checkIfThePackageIsAlreadyAdded(prescription) ===
                                            false ? (
                                                <button
                                                    className="btn buttonPrimary small-padding"
                                                    onClick={() =>
                                                        prescription.status.status === "ΑΚΥΡΩΜΕΝΗ"
                                                            ? checkPrescriptionForRepeat(
                                                                  prescription
                                                              )
                                                            : repeatPrescription(prescription)
                                                    }
                                                    disabled={
                                                        selectedPrescriptionBarcode ===
                                                        prescription.barcode
                                                    }
                                                >
                                                    {selectedPrescriptionBarcode ===
                                                    prescription.barcode ? (
                                                        <div>
                                                            <i className="fa fa-spinner fa-pulse"></i>
                                                            Άντληση....
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <i className="fa fa-repeat"></i>
                                                            Επανάληψη
                                                        </div>
                                                    )}
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn buttonDanger small-padding"
                                                    onClick={() => removePrescription(prescription)}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                    Αφαίρεση
                                                </button>
                                            )}
                                            <button
                                                className="btn buttonPrimary small-padding"
                                                onClick={() => handleMore(prescription.barcode)}
                                            >
                                                <i className="fa fa-search"></i>
                                                Λεπτομέρειες
                                            </button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <Pagination count={data.totalPages} page={page} onChange={handleChange} />
                </TableContainer>
            )}
            <PopUp
                openPopUp={more}
                setOpenPopUp={setMore}
                showCloseButton={true}
                title={"Συνταγή"}
                maxWidth="xl"
            >
                {selectedPrescription && (
                    <div>
                        <div>
                            <b>Αριθμός Συνταγής</b>: {selectedPrescription.id} <br />
                            <b>Τύπος Συνταγής</b>: {selectedPrescription.type.nameForPrescription}{" "}
                            <br />
                            <b>Επανάλήψεις Συνταγής</b>:{selectedPrescription.repeat.id} <br />
                            <b>Περίοδος Επανάληψης</b>:
                            {selectedPrescription.interval === ""
                                ? "-"
                                : selectedPrescription.interval.intervalDescription}{" "}
                            <br />
                            <b>Ημ/νία Έκδοσης Συνταγής</b>: {selectedPrescription.issueDate} <br />
                            <b>Ημ/νία Πρoθεσμίας Εκτέλεσης</b>: {selectedPrescription.endDate}{" "}
                            <br />
                            <b>Κατάσταση Συνταγής</b>: {selectedPrescription.status} <br />
                        </div>
                        <br />
                        {selectedPrescription.medicines.count > 0 && (
                            <div>
                                <div className="mb-3">
                                    <h6 style={{ marginLeft: "10px", marginBottom: "10px" }}>
                                        Διαγνώσεις Συνταγής
                                    </h6>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {diagnosisColumns.map((column, index) => {
                                                        return (
                                                            <TableCell key={index}>
                                                                <b>{column.name}</b>
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {selectedPrescription.diagnosis.diagnosis.map(
                                                    (innerDiagnosis, index) => {
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell>
                                                                    {innerDiagnosis.code}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {innerDiagnosis.title}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    }
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                <div className="mb-3">
                                    <h6 style={{ marginLeft: "10px", marginBottom: "10px" }}>
                                        Φάρμακα
                                    </h6>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                {!selectedPrescription.galenicTherapy ? (
                                                    <TableRow>
                                                        {medicineColumns.map((column, index) => {
                                                            return (
                                                                <TableCell key={index}>
                                                                    <b>{column.name}</b>
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                ) : (
                                                    <TableRow>
                                                        {galenicColumns.map((column, index) => {
                                                            return (
                                                                <TableCell key={index}>
                                                                    <b>{column.name}</b>
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                )}
                                            </TableHead>
                                            {!selectedPrescription.galenicTherapy ? (
                                                <TableBody>
                                                    {selectedPrescription.medicines.medicines.map(
                                                        (medicine, index) => {
                                                            return (
                                                                <TableRow key={index}>
                                                                    <TableCell>
                                                                        {medicine.request
                                                                            .genericMedicine ===
                                                                            true && (
                                                                            <i className="fa fa-check"></i>
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            medicine.request
                                                                                .treatmentProtocolDescription
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {`${medicine.commercialName} ${medicine.formCode} ${medicine.content}  ${medicine.packageForm}`}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            medicine.request
                                                                                .activeSubstanceName
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            medicine.request
                                                                                .doseQuantity.unit
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            medicine.request
                                                                                .doseQuantity.value
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            medicine.request
                                                                                .effectiveTime.name
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            medicine.request
                                                                                .rateQuantity.value
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {medicine.request.quantity}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {medicine.request.quantity -
                                                                            medicine.request
                                                                                .remainder}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            medicine.request
                                                                                .percentageParticipation
                                                                        }
                                                                        %
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }
                                                    )}
                                                </TableBody>
                                            ) : (
                                                <TableBody>
                                                    {selectedPrescription.medicines.medicines.map(
                                                        (medicine, index) => {
                                                            return (
                                                                <TableRow key={index}>
                                                                    <TableCell>
                                                                        {
                                                                            medicine.request
                                                                                .displayName
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            medicine.request
                                                                                .pharmaceuticalForm
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            medicine.request
                                                                                .galenicMeasurementUnit
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            medicine.request
                                                                                .galenicQuantity
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            medicine.request
                                                                                .doseQuantity.unit
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            medicine.request
                                                                                .doseQuantity.value
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            medicine.request
                                                                                .effectiveTime.name
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            medicine.request
                                                                                .rateQuantity.value
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            medicine.request
                                                                                .percentageParticipation
                                                                        }
                                                                        %
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {medicine.request
                                                                            .insuredParticipation +
                                                                            medicine.request
                                                                                .insuredDifference}
                                                                        €
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            medicine.request
                                                                                .insuredParticipation
                                                                        }
                                                                        €
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            medicine.request
                                                                                .insuredDifference
                                                                        }
                                                                        €
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {medicine.request.comment}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <button
                                                                            className="btn buttonPrimary"
                                                                            onClick={() =>
                                                                                onIngredientsHandle(
                                                                                    medicine
                                                                                )
                                                                            }
                                                                        >
                                                                            Συστατικά
                                                                        </button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }
                                                    )}
                                                </TableBody>
                                            )}
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </PopUp>
            <PopUp
                openPopUp={openIngredientsPopUp}
                setOpenPopUp={setOpenIngredientsPopUp}
                title={"Συστατικά"}
                maxWidth="lg"
                maxHeight="md"
            >
                <GalenicIngredients prescription={selectedPrescription} ingredients={ingredients} />
            </PopUp>
            <PopUp
                openPopUp={openCancelledPrescriptionRepeat}
                setOpenPopUp={setOpenCancelledPrescriptionRepeat}
                showCloseButton={true}
                title={"Προσοχή! Η συνταγή έχει ακυρωθεί,θέλετε να συνεχίσετε;"}
                maxWidth="lg"
            >
                <div>
                    <p>
                        Αν η συνταγη περιέχει φάρμακα που απαιτουν θεραπευτικό πρωτοκολλο, το
                        θεραπευτικό πρωτόκολλο και τα φάρμακα θα πρέπει να προστεθούν χειροκίνητα
                    </p>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <button
                            type="submit"
                            className="btn buttonPrimary"
                            onClick={() => {
                                repeatPrescription(prescriptionRepeat);
                                setOpenCancelledPrescriptionRepeat(false);
                            }}
                        >
                            Συνέχεια
                        </button>

                        <button
                            type="submit"
                            className="btn buttonPrimary"
                            onClick={() => {
                                setOpenCancelledPrescriptionRepeat(false);
                            }}
                        >
                            Ακύρωση
                        </button>
                    </div>
                </div>
            </PopUp>
        </div>
    );
}

export default PrescriptionHistory;
