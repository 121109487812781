import React, { useEffect, useState } from "react";
import { Alert, Pagination } from "@material-ui/lab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IdikaMasterDataService from "../../../../../services/MediPlus/Idika/IdikaMasterDataService";
import { Spinner } from "../../../../Utilities/Utilities";
import { mainColumns, formatDate } from "./config";
import PopUp from "../../../../Utilities/PopUp";
import DHPCAction from "./DHPCAction";
import { DoctorContext } from "../../../Doctor";
import { useContext } from "react";

function DHPCInformation() {
    const [data, setData] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [openActionsPopUp, setOpenActionsPopUp] = useState(false);
    const [selected, setSelected] = useState(null);

    const doctor = useContext(DoctorContext);
    let myDhpc = doctor.dhpc.myMessages;
    const handleChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        let request = {
            page: page - 1,
            size: 10,
        };
        IdikaMasterDataService.getDHPCMessages(request)
            .then(({ data }) => {
                setData(data);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [page]);

    const onOpenMessage = (message) => {
        setSelected(message);
        // setSelectedData(findMyDhpcData(message.id));
        setOpenActionsPopUp(true);
    };

    const findMyDhpcAcknowledgedData = (id) => {
        let message = myDhpc.find((element) => element.dhpcMessageId === id);
        if (message) {
            let date = new Date(message.acknowledged);
            if (isNaN(date.getTime())) {
                return null;
            } else {
                return formatDate(message.acknowledged, true);
            }
        } else {
            return null;
        }
    };

    // const findMyDhpcData = (id) => {
    //     let message = myDhpc.find((element) => element.dhpcMessageId === id);
    //     if (message) {
    //         return message;
    //     } else {
    //         return {};
    //     }
    // };

    const close = () => {
        setOpenActionsPopUp(false);
    };

    return (
        <div>
            {loading ? (
                <Spinner align="center" animation="spinner-border" />
            ) : error ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : data.count === 0 ? (
                <Alert severity="info">{`Δεν υπάρχουν μηνύματα`}</Alert>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {mainColumns.map((column, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <b>{column.name}</b>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.dhpcMessages.map((message, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{message.shortTitle}</TableCell>
                                        <TableCell>
                                            <a
                                                target="_Blank"
                                                href={message.messageUrl}
                                                rel="noopener noreferrer"
                                            >
                                                {message.messageUrl}
                                            </a>
                                        </TableCell>
                                        <TableCell>{formatDate(message.dateFm, false)}</TableCell>
                                        <TableCell>
                                            {findMyDhpcAcknowledgedData(message.id)}
                                        </TableCell>
                                        <TableCell>
                                            <button
                                                className="btn buttonPrimary small-padding"
                                                onClick={() => onOpenMessage(message)}
                                            >
                                                Ανάγνωση
                                            </button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <Pagination count={data.totalPages} page={page} onChange={handleChange} />
                </TableContainer>
            )}
            <PopUp
                openPopUp={openActionsPopUp}
                setOpenPopUp={setOpenActionsPopUp}
                title={"Μήνυμα DHPC"}
                maxWidth="lg"
            >
                <DHPCAction dhpc={selected} close={close} />
            </PopUp>
        </div>
    );
}

export default DHPCInformation;
